import React, { useEffect } from "react";

export default function ClickAwayListenerHoc({
  nodeRef,
  elementToIgnore,
  children,
  hideElement,
  active,
}) {
  const clickAwayHandler = (e) => {
    const target = e.target;
    if (
      (active == undefined || active) &&
      !nodeRef.contains(target) &&
      nodeRef !== e.target &&
      target.getAttribute("name") !== elementToIgnore
    ) {
      hideElement();
    }
  };

  useEffect(() => {
    document.addEventListener("click", clickAwayHandler, true);

    return () => {
      document.removeEventListener("click", clickAwayHandler, true);
    };
  }, [nodeRef, active]);

  return <>{children}</>;
}
