import React, { useEffect, useMemo } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "./store/actions/index";
import "./App.css";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import RenderRouter from "./routes/renderRouter";
import Cookies from "js-cookie";

const App = (props) => {
  const {
    onAuthCheck,
    onConnectWebsocket,
    onFetchCurrentUserRole,
    currentUser,
    token,
  } = props;
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  useEffect(() => {
    const uniqueId = searchParams.get("uniqueKey");
    if (uniqueId) {
      Cookies.set("access_token", uniqueId);
    }
    onAuthCheck();
  }, [onAuthCheck]);

  let userId = currentUser.sso;
  if (process.env.REACT_APP_AUTH_ENABLED !== "true") {
    userId = process.env.REACT_APP_AUTH_OVERRIDE_ID;
    if (process.env.REACT_APP_AUTH_OVERRIDE_TOKEN) {
      Cookies.set("access_token", process.env.REACT_APP_AUTH_OVERRIDE_TOKEN);
    }
  }

  useEffect(() => {
    if (userId) {
      onFetchCurrentUserRole(userId, token);
    }
  }, [onFetchCurrentUserRole, userId, currentUser, token]);

  useEffect(() => {
    onConnectWebsocket();
  }, [onConnectWebsocket]);

  return <RenderRouter />;
};

const mapStateToProps = (state) => {
  let ret = {
    token: state.auth.accessToken,
    currentUserRole: state.user.currentUserRole,
    currentUser: state.auth.currentUser,
  };
  if (process.env.REACT_APP_AUTH_ENABLED === "true") {
    ret = {
      token: state.auth.accessToken,
      currentUser: state.auth.currentUser,
      currentUserRole: state.user.currentUserRole,
    };
  }
  return ret;
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuthCheck: () => dispatch(actions.authCheck()),
    onConnectWebsocket: () => dispatch(actions.sockJsConnect()),
    onFetchCurrentUserRole: (sso, token) =>
      dispatch(actions.fetchCurrentUserRole(sso, token)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
