import axios from "../../axios";
import * as actionTypes from "./actionTypes";
import Cookies from "js-cookie";

export const authSuccess = (data) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    accessToken: data.access_token,
    currentUser: {
      sso: data.sso,
      firstName: data.firstName,
      lastName: data.lastName,
      userEmail: data.userEmail,
    },
  };
};

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

export const authEnd = () => {
  return {
    type: actionTypes.AUTH_END,
  };
};
const overrideAuth = process.env.REACT_APP_AUTH_OVERRIDE_TOKEN;
let refreshTokenInterval;

const setRefreshTokenInterval = (dispatch) => {
  let intervalTime = process.env.REACT_APP_AUTH_INTERVAL
    ? process.env.REACT_APP_AUTH_INTERVAL
    : 5400;
  if (!overrideAuth) {
    refreshTokenInterval = setInterval(() => {
      dispatch(getRefreshToken());
    }, 1000 * intervalTime);
  }
};

export const authCheck = () => {
  return (dispatch) => {
    dispatch(authStart());
    let userInfo = JSON.parse(localStorage.getItem("currentUser"));
    let uniqueKeyId = overrideAuth ? overrideAuth : Cookies.get("access_token");
    axios
      .get(`/auth/token?id=${uniqueKeyId}`, { withCredentials: true })
      .then((response) => {
        const expire_time = new Date();
        expire_time.setSeconds(
          expire_time.getSeconds() + parseInt(response.data.expires_in)
        );
        response.data.expires_on = expire_time.toUTCString();
        if (userInfo) {
          response.data.previous_access_token = userInfo.previous_access_token;
          response.data.previous_refresh_token =
            userInfo.previous_refresh_token;
          response.data.previous_expires_on = userInfo.previous_expires_on;
        } else {
          response.data.previous_access_token = "";
          response.data.previous_refresh_token = "";
          response.data.previous_expires_on = "";
        }
        localStorage.setItem("currentUser", JSON.stringify(response.data));
        dispatch(authSuccess(response.data));
        setRefreshTokenInterval(dispatch);
      })
      .catch((err) => {
        dispatch(authEnd());
        console.log("error ", err);
        if (
          err.response &&
          (err.response.status === 401 || err.response.status === 500)
        ) {
          if (userInfo) {
            dispatch(getRefreshToken());
          } else {
            dispatch(authRemove());
          }
        }
      });
  };
};

export const getRefreshToken = () => {
  return (dispatch) => {
    let uniqueKeyId = Cookies.get("access_token");
    if (uniqueKeyId && uniqueKeyId != "") {
      let userInfo = JSON.parse(localStorage.getItem("currentUser"));
      let previous_access_token = userInfo ? userInfo.access_token : "";
      let previous_refresh_token = userInfo ? userInfo.refresh_token : "";
      let previous_expires_on = userInfo ? userInfo.expires_on : "";
      dispatch(authStart());
      axios
        .get(
          `/auth/refresh-token?refresh-token=${userInfo.refresh_token}&id=${uniqueKeyId}`,
          {
            withCredentials: true,
          }
        )
        .then((response) => {
          console.log("refresh-token response", response);
          const expire_time = new Date();
          expire_time.setSeconds(
            expire_time.getSeconds() + parseInt(response.data.expires_in)
          );
          userInfo.previous_access_token = previous_access_token;
          userInfo.previous_refresh_token = previous_refresh_token;
          userInfo.previous_expires_on = previous_expires_on;
          userInfo.access_token = response.data.access_token;
          userInfo.refresh_token = response.data.refresh_token;
          userInfo.expires_on = expire_time.toUTCString();
          userInfo.expires_in = response.data.expires_in;
          localStorage.setItem("currentUser", JSON.stringify(userInfo));
          dispatch(authSuccess(userInfo));
        })
        .catch((err) => {
          dispatch(authEnd());
          console.log("error ", err);
          dispatch(authRemove());
        });
    } else {
      refreshTokenInterval && clearInterval(refreshTokenInterval);
    }
  };
};

export const authRemove = () => {
  return {
    type: actionTypes.AUTH_REMOVE,
  };
};
