import React from "react";
import { getRouter } from "./utils";
import { routeConfig, loginConfig, noRoleConfig } from "./routeConfig";
import { useUser } from "../hooks/useUser";
import { routes } from "./routes";
import Cookies from "js-cookie";

const RenderRouter = () => {
  const { currentUserRole } = useUser();
  const roleId = currentUserRole.roleId;
  let validRoute = roleId ? getRouter(routeConfig[roleId]) : "";

  let accessToken = Cookies.get("access_token");
  if (!accessToken) {
    validRoute = getRouter(loginConfig, routes.LOGIN);
  }

  if (currentUserRole !== "" && currentUserRole.roleName === "NO ROLE") {
    validRoute = getRouter(noRoleConfig, routes.NO_ROLE);
  }

  return validRoute;
};

export default RenderRouter;
