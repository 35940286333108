import React, { useEffect, useState } from "react";
import "./DropdownSuggestions.css";

const DropdownSuggestions = (props) => {
  const {
    show,
    options,
    onSelect,
    closeOnSelect,
    length = 5,
    marginTop = 5,
    selected,
    onOptionEmpty,
    labelField,
  } = props;

  const [showComp, setShowComp] = useState(true);

  useEffect(() => {
    show != undefined && setShowComp(show);
  }, [show]);

  useEffect(() => {
    if (options && options.length == 0 && onOptionEmpty) {
      onOptionEmpty();
    }
  }, [options]);

  const getLabel = (option) => {
    if (labelField) {
      return option[labelField] ? option[labelField].toUpperCase() : "";
    }
    return option ? option.toUpperCase() : "";
  };

  return (
    <>
      {showComp && options && options.length != 0 && (
        <div className="dropdown-list-container">
          <ul
            className={
              "dropdown-suggestion-list" +
              (options.length > length ? " padding-right" : "")
            }
            style={{
              maxHeight: Math.ceil(27.2 * length) + 4 + "px",
              marginTop: marginTop + "px",
            }}
          >
            {options
              .sort(function (a, b) {
                return getLabel(a)
                  .toLowerCase()
                  .localeCompare(getLabel(b).toLowerCase());
              })
              .map((option, index) => {
                return (
                  <li
                    className={`${
                      selected &&
                      getLabel(option) &&
                      selected.toLowerCase() == getLabel(option).toLowerCase()
                        ? "selected"
                        : ""
                    }`}
                    key={index}
                    onClick={() => {
                      if (closeOnSelect) {
                        setShowComp(false);
                      }
                      onSelect(option);
                    }}
                  >
                    {getLabel(option)}{" "}
                  </li>
                );
              })}
          </ul>
        </div>
      )}
    </>
  );
};

export default DropdownSuggestions;
