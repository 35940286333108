import React from "react";
import Loader_img from "../../../../assets/images/loader.gif";
import "../../../shared/UI/Spinner/Spinner.css";

const Loader = ({ loading = false }) => {
  return loading ? (
    <div className="loader">
      <img width="25" height="25" src={Loader_img} alt="loader" />
    </div>
  ) : null;
};

export default Loader;
