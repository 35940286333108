import React, { useState, useEffect, useRef, Fragment } from "react";
import SearchItem from "./searchitem/Searchitem";
import "./Searchbox.css";
import { connect } from "react-redux";
import Button from "../../components/shared/UI/Button/Button";
import * as actions from "../../store/actions/index";
import displayLogo from "../../assets/icons/LSM_display.svg";
import Select from "../../components/shared/UI/Select/Select";
import { ReactComponent as SearchIcon } from "../../assets/icons/search-icon.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/Close-icon.svg";
import { ReactComponent as RefreshIcon } from "../../assets/icons/refresh-icon.svg";

const Searchbox = (props) => {
  const {
    onSearchCurrentLiveShot,
    token,
    showLoader,
    onShowLoader,
    type,
    newLiveshot,
    blockId,
    controlRoomList,
    currentCanvas,
    facilityList,
    closeErrorBar,
    openErrorBar,
    isError,
    userFacilityId,
    clearSearchTerm,
  } = props;
  const { searchList, currentUserRole } = props;
  const [searchTerm, setSearchTerm] = useState("");
  const [searchLocationTerm, setSearchLocationTerm] = useState("");
  const [searchVenueNameTerm, setSearchVenueNameTerm] = useState("");
  const [disableSearchByName, setDisableSearchByName] = useState(false);
  const [searchType, setSearchType] = useState(0);
  const inputElement = useRef(null);
  const [currentRoom, setCurrentRoom] = useState(null);
  const [facilityId, setFacilityId] = useState(0);
  const [selected, setSelected] = useState(null);
  const [searchHitInterval, setSearchHitInterval] = useState("");
  let [filteredSearchData, setFilteredSearchData] = useState([]);
  useEffect(() => {
    if (controlRoomList && currentCanvas) {
      let room = controlRoomList.find(
        (list) => list.roomId === currentCanvas.roomId
      );
      setCurrentRoom(room);
    }
  }, [controlRoomList, currentCanvas]);
  useEffect(() => {
    if (!newLiveshot) {
      if (inputElement.current) {
        inputElement.current.focus();
        setSearchTerm("");
        setSearchHitInterval("");
        setSearchLocationTerm("");
        setSearchVenueNameTerm("");
        setDisableSearchByName(false);
        setFacilityId(userFacilityId);
        onShowLoader();
        onSearchCurrentLiveShot(token);
      }
    }
  }, [
    newLiveshot,
    onShowLoader,
    onSearchCurrentLiveShot,
    token,
    userFacilityId,
  ]);

  useEffect(() => {
    setSearchType(type);
    let newData = {};
    newData.abstractLiveshotId = "1abc";
    newData.flag = true;
    newData.abstractLiveshotName = "";
    newData.hitInterval = "";
    newData.venueType = "E.G. 30R LOCATION, SKYPE, QUICKLINK...";
    newData.location = "";
    newData.studioName = "";
    newData.facilityId = Number(facilityId);
    let searchListDuplicate = searchList.filter(
      (liveshot) => liveshot.abstractLiveshotId === "1abc"
    );
    if (searchListDuplicate.length !== 0) {
      searchList.forEach((search) => {
        if (search.abstractLiveshotId === "1abc") {
          search.facilityId = Number(facilityId);
        }
      });
    }
    let filteredData = searchList.filter((liveShotType) => {
      if (liveShotType.abstractLiveshotName === null) return null;
    });

    if (facilityId !== 0) {
      filteredData = searchList.filter((liveShotType) => {
        if (liveShotType.abstractLiveshotName === null) return null;
        return liveShotType.facilityId === Number(facilityId);
      });
      let newLiveshot = filteredData.filter((liveshot) => {
        return liveshot.flag === true;
      });
      if (newLiveshot.length > 0) {
        newLiveshot[0].abstractLiveshotName = searchTerm;
        newLiveshot[0].hitInterval = searchHitInterval;
      }
    } else {
      filteredData = searchList;
    }
    sortLiveShotData(filteredData);
    setFilteredSearchData(filteredData);
  }, [type, searchList, facilityId]);

  useEffect(() => {
    let filterSearchData = [];
    let filterByfacilityId = facilityId !== 0;
    let filterBySearchType = false; // searchType !== 0;

    if (searchTerm) {
      filterSearchData = searchList.filter((liveShotType) => {
        return (
          liveShotType.abstractLiveshotName !== null &&
          (!filterByfacilityId ||
            liveShotType.facilityId === Number(facilityId)) &&
          (!filterBySearchType ||
            liveShotType.liveshotTypeId === Number(searchType)) &&
          (liveShotType.abstractLiveshotName
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
            (liveShotType.venue !== null &&
              liveShotType.venue
                .toLowerCase()
                .includes(searchTerm.toLowerCase())))
        );
      });
    } else {
      filterSearchData = searchList.filter((liveShotType) => {
        return liveShotType.abstractLiveshotName !== null;
      });
    }
    sortLiveShotData(filterSearchData);
    setFilteredSearchData(filterSearchData);
  }, [searchTerm, searchLocationTerm, searchVenueNameTerm]);

  useEffect(() => {
    if (clearSearchTerm) setSearchTerm("");
  }, [clearSearchTerm]);

  const sortLiveShotData = (liveShotData) => {
    liveShotData.sort((a, b) =>
      a.abstractLiveshotName > b.abstractLiveshotName
        ? 1
        : b.abstractLiveshotName > a.abstractLiveshotName
        ? -1
        : 0
    );
  };

  const onRefreshSearch = () => {
    setFilteredSearchData([]);
    onShowLoader();
    onSearchCurrentLiveShot(token);
    setSearchTerm("");
    setSearchLocationTerm("");
    setSearchVenueNameTerm("");
    setDisableSearchByName(false);
  };

  const handleOnChange = (chosenValue) => {
    setFacilityId(Number(chosenValue.value));
    setSelected(chosenValue);
  };

  const onUpdateSearch = (e) => {
    setSearchTerm(e.target.value);
    setSearchLocationTerm(e.target.value);
    setSearchVenueNameTerm(e.target.value);
  };

  return (
    <Fragment>
      <div
        className={
          selected && selected.label === "vprod-1"
            ? "Searchbox_container vprod-paddingright"
            : "Searchbox_container"
        }
      >
        <div className="search-header-row">
          <img
            src={displayLogo}
            alt="display logo"
            className="search-display-logo"
          />
          <label className="search-header-title">Search Live Shots</label>
          {showLoader ? <i className="fas fa-spinner fa-spin"></i> : null}
          <CloseIcon onClick={props.modalClosed} className="search-closeicon" />
        </div>

        <div className="search-box-row">
          <div className="search-border-1">
            <span className="search-img-input">
              <SearchIcon className="searchls_icon" />

              <input
                className="search-textbox"
                type="text"
                placeholder="Search"
                value={searchTerm}
                onChange={onUpdateSearch}
                ref={inputElement}
                disabled={disableSearchByName}
              />
            </span>
          </div>

          <Select
            style={{ width: 150 }}
            onChange={handleOnChange}
            placeholder="SELECT"
            value={selected}
            options={facilityList.map((option) => ({
              value: option.facilityId,
              key: option.facilityId,
              label: option.facilityName,
            }))}
          />
          <div className="search-refresh-btn">
            <Button onClick={onRefreshSearch} className="search-refresh-btn">
              <RefreshIcon className="search-refresh-icon" />
              Refresh
            </Button>
          </div>
        </div>
        <div className="searchbox">
          {filteredSearchData.map((searchObj) => (
            <SearchItem
              key={searchObj.abstractLiveshotId}
              searchInfo={searchObj}
              currentUserRole={currentUserRole}
              clicked={props.modalClosed}
              closeErrorBar={closeErrorBar}
              openErrorBar={openErrorBar}
              isError={isError}
              blockId={blockId}
              room={currentRoom}
            />
          ))}
          {searchTerm ? (
            <SearchItem
              key={0}
              searchInfo={[]}
              searchTermValue={searchTerm}
              currentUserRole={currentUserRole}
              clicked={props.modalClosed}
              blockId={blockId}
              room={currentRoom}
            />
          ) : (
            ""
          )}
        </div>
        <input
          className="search-cancel-button"
          type="button"
          value="Cancel"
          onClick={props.modalClosed}
        />
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    searchList: state.searchListInfo.searchList,
    token: state.auth.accessToken,
    showLoader: state.searchListInfo.showLoader,
    newLiveshot: state.liveShotContainer.newLiveshot,
    currentCanvas: state.canvasInfo.currentCanvas,
    controlRoomList: state.canvasInfo.controlRoomList,
    facilityList: state.showInfo.facilityList,
    userFacilityId: state.user.userFacilityId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSearchCurrentLiveShot: (token) => dispatch(actions.searchLiveShot(token)),
    onShowLoader: () => dispatch(actions.showLoader()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Searchbox);
