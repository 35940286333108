import axios from "../../axios";
import * as actionTypes from "./actionTypes";
import * as actions from "../../store/actions/index";
import * as Constants from "../../components/shared/Constants";
import {
  source_destination_page_constants,
  UPLOAD_MESSAGE,
} from "../../constants/PageConstants";

export const toggleLoader = () => {
  return {
    type: actionTypes.TOGGLE_LOADER,
  };
};

export const addRouterSources = (res) => {
  return {
    type: actionTypes.FETCH_ROUTER_SOURCES,
    routerSources: res,
  };
};

export const parseRouterSources = (res) => {
  return {
    type: actionTypes.FORMATTED_ROUTER_SOURCES,
    routerSources: res,
  };
};

export const addRouterDestinations = (res) => {
  return {
    type: actionTypes.FETCH_ROUTER_DESTINATIONS,
    routerDestinations: res,
  };
};

export const addRoomDetails = (res) => {
  return {
    type: actionTypes.FETCH_ROOM_DETAILS,
    roomDetails: res,
  };
};

export const addPinsInfo = (res) => {
  return {
    type: actionTypes.FETCH_PINS_TABLE_INFO,
    pinInfo: res,
  };
};

export const getPinsInfo = (res) => {
  return {
    type: actionTypes.FETCH_PIN_MODAL_INFO,
    pinsInfo: res,
  };
};

export const addUserSso = (res) => {
  return {
    type: actionTypes.FETCH_USER_INFO,
    userSso: res,
  };
};

export const addSignalTypeDetails = (res) => {
  return {
    type: actionTypes.FETCH_SIGNAL_TYPE_DETAILS,
    signalTypeDetails: res,
  };
};

export const savePinData = (studioPinData, successMsg) => {
  return {
    type: actionTypes.STUDIOPINDATASAVE_OBJECT_SUCCESS,
    studioPinData: studioPinData,
    successMsg: successMsg,
  };
};
export const getRouterDestinations = (token) => {
  return (dispatch) => {
    axios
      .get("/liveshot-api/routerSignal/?kind=destinations", {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((response) => {
        dispatch(addRouterDestinations(response.data));
      })
      .catch((err) => {
        console.log("error ", err);
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
      });
  };
};

export const getRouterSources = (token) => {
  return (dispatch) => {
    axios
      .get("/liveshot-api/routerSignal/?kind=sources", {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((response) => {
        dispatch(addRouterSources(response.data));
        dispatch(parseRouterSources(response.data));
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
        console.log("error ", err);
      });
  };
};

export const getSignalTypeDetails = (token) => {
  return (dispatch) => {
    axios
      .get("/liveshot-api/liveshotSignalType/", {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((response) => {
        dispatch(addSignalTypeDetails(response.data));
      })
      .catch((err) => {
        console.log("error ", err);
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
      });
  };
};

export const getRoomDetails = (token) => {
  return (dispatch) => {
    axios
      .get("/liveshot-api/room/?hidden=all", {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((response) => {
        const roomDetails = [];
        const details = response.data;
        for (let key in details) {
          const room = details[key];
          let filteredData = details.filter(
            (data) => data.roomId === room.coreId
          );
          if (filteredData.length === 0) {
            roomDetails.push(room);
          } else {
            room.coreId = filteredData[0].roomName;
            roomDetails.push(room);
          }
        }
        dispatch(addRoomDetails(roomDetails));
      })
      .catch((err) => {
        console.log("error ", err);
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
      });
  };
};

export const getPinTableInfo = (token) => {
  return (dispatch) => {
    axios
      .get("/liveshot-api/studioTruck/venuePins", {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((response) => {
        const tableInfo = [];
        const details = response.data;
        for (let key in details) {
          const room = details[key];
          tableInfo.push(room);
        }
        dispatch(addPinsInfo(tableInfo));
      })
      .catch((err) => {
        console.log("error ", err);
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
      });
  };
};
export const downloadSourceDestinationDetails = (token, activeTab) => {
  return (dispatch) => {
    dispatch(toggleLoader());
    let url = "";
    if (activeTab === source_destination_page_constants.SIGNAL_TYPES_TAB) {
      url = "/liveshot-api/liveshotSignalType/?export=true";
    } else if (
      activeTab === source_destination_page_constants.ROOMS_TAB ||
      activeTab === source_destination_page_constants.SOURCES_TAB ||
      activeTab === source_destination_page_constants.DESTINATIONS_TAB
    ) {
      url = `/ext-api/router/routerSignalDetails/?kind=${activeTab}`;
    } else if (activeTab === source_destination_page_constants.USERS_TAB) {
      url = `/user-api/user/userExport/`;
    }

    axios
      .get(url, {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((res) => {
        dispatch(toggleLoader());
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", activeTab + ".csv");
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch((err) => {
        dispatch(toggleLoader());
        console.log("error ", err);
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
      });
  };
};
export const uploadNewUserInfo = (token, ssoNumber) => {
  const formData = { sso: ssoNumber };
  return (dispatch) => {
    dispatch(toggleLoader());
    axios
      .post(`/user-api/user/`, formData, {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((res) => {
        let response = res;
        dispatch(toggleLoader());
        dispatch(addUserSso(response));
      })
      .catch((err) => {
        dispatch(
          showPopupModal({
            resultData: err.response.data.message,
            statusCode: err.response.status,
          })
        );
        dispatch(toggleLoader());
        console.log("error ", err);
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
      });
  };
};

export const updatePinInfo = (token, pinId, pinData) => {
  return (dispatch) => {
    dispatch(toggleLoader());
    axios
      .patch(`/liveshot-api/pin/${pinId}`, pinData, {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((res) => {
        dispatch(toggleLoader());
        if (res.status === 200) {
          res.data.id = pinId;
          dispatch(
            savePinData({
              studioPinData: res.data,
              successMsg: Constants.STUDIOPINDATA_UPDATED_SUCCESS_MSG,
            })
          );
          dispatch(actions.getPinModalInfo(token));
        }
      })
      .catch((err) => {
        dispatch(toggleLoader());
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
        dispatch(
          savePinData({
            resultData: Constants.STUDIOPINDATA_UPDATE_FAILURE_MSG,
            statusCode: err.status,
          })
        );
      });
  };
};
export const updatePinModalData = (pinData, token) => {
  return (dispatch) => {
    dispatch(toggleLoader());
    axios
      .patch(`/liveshot-api/pin/${pinData.id}`, pinData, {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch(
            savePinData({
              studioPinData: res.data,
              successMsg: Constants.STUDIOPINDATA_SAVE_SUCCESS_MSG,
            })
          );
          dispatch(actions.getPinModalInfo(token));
        }
        dispatch(toggleLoader());
      })
      .catch((err) => {
        dispatch(toggleLoader());
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
        dispatch(
          savePinData({
            resultData: Constants.STUDIOPINDATA_SAVE_FAILURE_MSG,
            statusCode: err.status,
          })
        );
      });
  };
};
export const savePinModalData = (pinData, token) => {
  return (dispatch) => {
    dispatch(toggleLoader());
    axios
      .post("/liveshot-api/pin/", pinData, {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((res) => {
        dispatch(toggleLoader());
        if (res.status === 201) {
          dispatch(
            savePinData({
              studioPinData: res.data,
              successMsg: Constants.STUDIOPINDATA_SAVE_SUCCESS_MSG,
            })
          );
          dispatch(actions.getPinModalInfo(token));
        }
      })
      .catch((err) => {
        dispatch(toggleLoader());
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
        dispatch(
          savePinData({
            resultData: Constants.STUDIOPINDATA_SAVE_FAILURE_MSG,
            statusCode: err.status,
          })
        );
      });
  };
};

export const getPinModalInfo = (token) => {
  return (dispatch) => {
    axios
      .get("/liveshot-api/pin/", {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((response) => {
        const pinInfo = response.data;
        dispatch(getPinsInfo(pinInfo));
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
      });
  };
};

export const deletePinsInfo = (token, pinId) => {
  return (dispatch) => {
    dispatch(toggleLoader());
    axios
      .delete(`/liveshot-api/pin/${pinId}`, {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((res) => {
        dispatch(actions.getPinModalInfo(token));
        dispatch(toggleLoader());
      })
      .catch((err) => {
        dispatch(toggleLoader());
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
      });
  };
};

export const importSourceDestination = (token) => {
  return (dispatch) => {
    dispatch(toggleLoader());
    axios
      .post("/ext-api/router/dropAndImport/", {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((res) => {
        dispatch(
          showPopupModal({
            resultData: Constants.DROP_AND_IMPORT_SUCCESS_MSG,
            statusCode: res.status,
          })
        );
        dispatch(toggleLoader());
      })
      .catch((err) => {
        dispatch(
          showPopupModal({
            resultData: err.response.data.message,
            statusCode: err.response.status,
          })
        );
        dispatch(toggleLoader());
        console.log("error ", err);
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
      });
  };
};

export const downloadPinDetails = (token) => {
  return (dispatch) => {
    dispatch(toggleLoader());
    axios
      .get(`/liveshot-api/studioTruck/downloadVenuePins`, {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((res) => {
        dispatch(toggleLoader());
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "VenuePin" + ".csv");
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch((err) => {
        dispatch(toggleLoader());
        console.log("error ", err);
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
      });
  };
};
export const uploadPins = (token, file) => {
  return (dispatch) => {
    dispatch(toggleLoader());
    let formData = new FormData();
    formData.append("file", file);
    axios
      .post(`/liveshot-api/studioTruck/uploadPins`, formData, {
        withCredentials: true,
        headers: {
          Authorization: token !== "" ? `Bearer ${token}` : "",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        dispatch(
          showPopupModal({
            resultData: Constants.UPLOAD_SOURCE_DESTINATION_SUCCESS_MSG,
            statusCode: res.status,
          })
        );
        dispatch(toggleLoader());
      })
      .catch((err) => {
        dispatch(
          showPopupModal({
            resultData: err.response.data.message,
            statusCode: err.response.status,
          })
        );
        dispatch(toggleLoader());
        console.log("error ", err);
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
      });
  };
};
export const uploadSourceDestinationDetails = (
  token,
  file,
  activeTab,
  userId
) => {
  return (dispatch) => {
    dispatch(toggleLoader());
    let formData = new FormData();
    formData.append("file", file);
    let url = "";
    if (activeTab === source_destination_page_constants.SIGNAL_TYPES_TAB) {
      url = `/liveshot-api/liveshotSignalType/import?updatedBy=${userId}`;
    } else if (activeTab === source_destination_page_constants.ROOMS_TAB) {
      url = `/ext-api/router/roomDetails/?kind=${source_destination_page_constants.ROOMS_TAB}&updatedBy=${userId}`;
    } else if (
      activeTab === source_destination_page_constants.SOURCES_TAB ||
      activeTab === source_destination_page_constants.DESTINATIONS_TAB
    ) {
      url = `/ext-api/router/routerSignalDetails/?kind=${activeTab}&updatedBy=${userId}`;
    } else if (activeTab === source_destination_page_constants.USERS_TAB) {
      url = `/user-api/user/userImport?updatedBy=${userId}`;
    }
    axios
      .post(url, formData, {
        withCredentials: true,
        headers: {
          Authorization: token !== "" ? `Bearer ${token}` : "",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        dispatch(toggleLoader());
        if (activeTab === source_destination_page_constants.SIGNAL_TYPES_TAB) {
          dispatch(getSignalTypeDetails(token));
        } else if (activeTab === source_destination_page_constants.ROOMS_TAB) {
          dispatch(getRoomDetails(token));
        } else if (
          activeTab === source_destination_page_constants.SOURCES_TAB
        ) {
          dispatch(getRouterSources(token));
        } else if (
          activeTab === source_destination_page_constants.DESTINATIONS_TAB
        ) {
          dispatch(getRouterDestinations(token));
        } else if (activeTab === source_destination_page_constants.USERS_TAB) {
          dispatch(actions.fetchUserList(token));
        }
        dispatch(
          showPopupModal({
            resultData: Constants.UPLOAD_SOURCE_DESTINATION_SUCCESS_MSG,
            statusCode: res.status,
            signalMsg: { statusCode: res.status },
          })
        );
      })
      .catch((err) => {
        dispatch(toggleLoader());
        dispatch(
          showPopupModal({
            resultData:
              err &&
              err.response &&
              err.response.data &&
              err.response.data.message,
            statusCode: err && err.response && err.response.status,
          })
        );
        console.log("error ", err);
        if (err && err.response && err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
      });
  };
};

const showPopupModal = (message) => {
  let content = "";
  let isSuccess = [200, 201].includes(message.statusCode);
  let title = isSuccess
    ? UPLOAD_MESSAGE.SUCCESS_TITLE
    : UPLOAD_MESSAGE.FAILED_TITLE;
  if (isSuccess) {
    content = UPLOAD_MESSAGE.SUCCESS;
  } else if (message.statusCode === 202) {
    content = UPLOAD_MESSAGE.INVALID;
  } else if (message.statusCode === 400) {
    content = UPLOAD_MESSAGE.FAILED_400;
  } else if (message.statusCode === 500) {
    content = UPLOAD_MESSAGE.FAILED_500;
  } else if (!message.statusCode) {
    title = UPLOAD_MESSAGE.FAILED_504_TITLE;
    content = UPLOAD_MESSAGE.FAILED_504;
  } else {
    content = UPLOAD_MESSAGE.FAILED_UNKNOWN_CODE + message.statusCode;
  }
  return actions.showCommonPopupModal({
    showModal: true,
    title: title,
    content: content,
    type: isSuccess ? "success" : "error",
  });
};
