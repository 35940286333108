import React, { forwardRef } from "react";

const NumberInput = forwardRef(
  (
    { placeholder = "ENTER", maxLength = 4, name, value, onChange, disabled },
    ref
  ) => {
    return (
      <input
        ref={ref}
        pattern="([0-9][0-9][0-9][0-9])"
        placeholder={placeholder}
        type="text"
        maxLength={maxLength}
        name={name}
        value={value}
        className={`inputFields ${!disabled ? "labelClass" : "disable-input"}`}
        onChange={onChange}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          }
        }}
        disabled={disabled}
      />
    );
  }
);

export default NumberInput;
