import { createNotification, NOTIFICATION_TYPE_INFO } from "react-redux-notify";
import React from "react";
/* Array of Strings */
window.$TIPS_AND_TRICKS = [];

const TIPS_AND_TRICKS = window.$TIPS_AND_TRICKS;

const getDayOfYear = () => {
  var now = new Date();
  var start = new Date(now.getFullYear(), 0, 0);
  var diff =
    now -
    start +
    (start.getTimezoneOffset() - now.getTimezoneOffset()) * 60 * 1000;
  var oneDay = 1000 * 60 * 60 * 24;
  var day = Math.floor(diff / oneDay);
  return day;
};

let todaysTipTrick = "";
if (TIPS_AND_TRICKS !== undefined) {
  let tipsTricksLen = TIPS_AND_TRICKS.length;
  let dayOfYear = getDayOfYear();
  let todaysTipTrickIdx = dayOfYear % tipsTricksLen;

  if (todaysTipTrickIdx >= 0 && todaysTipTrickIdx < tipsTricksLen)
    todaysTipTrick = TIPS_AND_TRICKS[todaysTipTrickIdx];
}

export const flashNotification = {
  message: todaysTipTrick,
  type: NOTIFICATION_TYPE_INFO,
  duration: parseInt(process.env.REACT_APP_FLASH_NOTIF_DUR),
  canDismiss: true,
  icon: <i className="fa fa-info" />,
  customStyles: {
    "notification--info": "custom-notif",
    content: "custom-notif-content",
  },
};

export const showNotification = createNotification;
