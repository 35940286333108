import React, { forwardRef, memo } from "react";
import { AutoComplete } from "antd";
import { CustomInput } from "../Input/CustomInput";
import "./autocomplete-style.css";
import NumberInput from "../Input/NumberInput";

function onlyUnique(value, index, array) {
  return array.indexOf(value) === index && value;
}

const ReactAutoComplete = forwardRef((props, ref) => {
  const filteredOption =
    (props.options && props.options.filter(onlyUnique).sort()) || [];
  return (
    <AutoComplete
      ref={ref}
      {...props}
      virtual={false}
      popupClassName="signal-menu"
      options={filteredOption.map((option) => ({ value: option }))}
    >
      {props.custominput === "number" ? (
        <NumberInput />
      ) : (
        <CustomInput
          className={"textarea" + props.patherr}
          placeholder="ENTER"
        />
      )}
    </AutoComplete>
  );
});

ReactAutoComplete.defaultProps = {
  onChange: () => {},
  value: "",
  options: [],
  key: "antd-select",
  custominput: "textarea",
};

export default memo(ReactAutoComplete);
