import React, { useEffect, useState } from "react";
import { ReactComponent as EditIcon } from "../../../assets/icons/Edit.svg";
import { ReactComponent as CloseIcon } from "../../../assets/icons/Close-icon.svg";
import "./AddPinsModal.css";
import { venue_page_constants } from "../../../constants/PageConstants";
import {
  validateAndUpdatePin,
  validateFormPin,
  transformInput,
} from "../../shared/utility";

export const AddPinsModal = ({
  isModalOpen,
  isPinsAddMode,
  onAddPinModalCloseClick,
  onAddPinModalSubmitClick,
  onUpdatePinModalSubmitClick,
  pinRowStId,
  stLiveshotName,
  stIfb1Pin,
  stIfb2Pin,
  stPlPin,
  stCreatedOn,
  openErrorBar,
  closeErrorBar,
}) => {
  const [liveshotName, setLiveshotName] = useState("");
  const [isAddPinSaveDisabled, setIsAddPinSaveDisabled] = useState(true);
  const [ifb1Pin, setIfb1Pin] = useState("");
  const [ifb2Pin, setIfb2Pin] = useState("");
  const [plPin, setPlPin] = useState("");
  const [pinCreatedOn, setPinCreatedOn] = useState("");

  const handelLiveshotName = (event) => {
    setLiveshotName(event.target.value);
  };
  useEffect(() => {
    setLiveshotName(stLiveshotName);
    setIfb1Pin(stIfb1Pin);
    setIfb2Pin(stIfb2Pin);
    setPlPin(stPlPin);
    setPinCreatedOn(stCreatedOn);
  }, [stLiveshotName, stIfb1Pin, stIfb2Pin, stPlPin, stCreatedOn]);

  //Code to enable or disable the add pin modal save button.
  useEffect(() => {
    let isAddPinSaveDisabledValue = liveshotName && ifb1Pin ? false : true;
    setIsAddPinSaveDisabled(isAddPinSaveDisabledValue);
  }, [liveshotName, ifb1Pin]);
  const handleSubmitAddPinModal = () => {
    let newPinData = {};
    newPinData.liveshotName = transformInput(liveshotName);
    newPinData.studioIfb1pin = ifb1Pin;
    newPinData.studioIfb2pin = ifb2Pin;
    newPinData.studioPlpin = plPin;
    const hasErrorMsg = validateFormPin(newPinData);
    if (hasErrorMsg) {
      openErrorBar(hasErrorMsg, true);
      return false;
    }
    if (isPinsAddMode) {
      onAddPinModalSubmitClick(newPinData);
    } else {
      newPinData.id = pinRowStId;
      newPinData.createdOn = pinCreatedOn;
      onUpdatePinModalSubmitClick(newPinData);
    }
    closeErrorBar();
  };

  return (
    <>
      {isModalOpen && (
        <div className="pop-container-div">
          <div className="pin-modal-position addPinModalcontainer">
            <div className="pin-modal-content bg-col">
              <div className="model-title-icons-row">
                {isPinsAddMode && (
                  <>
                    <i className=" fa fa-plus-circle fa-lg addPinIcon" />
                    <label className="modal-title">Add pin</label>
                  </>
                )}
                {!isPinsAddMode && (
                  <>
                    <EditIcon className="editPinIcon" alt="edit-icon" />
                    <label className="modal-title">Edit pin </label>
                  </>
                )}
                <CloseIcon
                  onClick={onAddPinModalCloseClick}
                  className="closeIcon"
                />
              </div>
              <div className="venue-modal-body pin-modal-body">
                <div className="venue-form-wrapper">
                  <label className="div-label">
                    <span className="mandatory-icon">* </span>
                    Name
                  </label>
                  <input
                    className="venue-input venue-name-input transform-input"
                    placeholder="ENTER NAME"
                    value={liveshotName}
                    onChange={(e) => handelLiveshotName(e)}
                  ></input>
                  <label className="div-label">
                    <span className="mandatory-icon">* </span>
                    IFB 1 PIN
                  </label>
                  <input
                    maxLength={venue_page_constants.MAX_LENGTH}
                    pattern="[0-9]{4}"
                    className="venue-input venue-name-input"
                    placeholder="ENTER IFB 1 PIN"
                    value={ifb1Pin}
                    onChange={(e) => {
                      validateAndUpdatePin(e.target.value, setIfb1Pin);
                    }}
                  ></input>
                  <label className="div-label">IFB 2 PIN</label>
                  <input
                    pattern="[0-9]{4}"
                    maxLength={venue_page_constants.MAX_LENGTH}
                    className="venue-input venue-name-input"
                    placeholder="ENTER IFB 2 PIN"
                    value={ifb2Pin}
                    onChange={(e) => {
                      validateAndUpdatePin(e.target.value, setIfb2Pin);
                    }}
                  ></input>
                  <label className="div-label">PL PIN</label>
                  <input
                    pattern="[0-9]{4}"
                    maxLength={venue_page_constants.MAX_LENGTH}
                    className="venue-input venue-name-input"
                    placeholder="ENTER PL PIN"
                    value={plPin}
                    onChange={(e) => {
                      validateAndUpdatePin(e.target.value, setPlPin);
                    }}
                  ></input>
                </div>
              </div>
              <div className="venue-modal-icons-row">
                <input
                  className="venue-cancel-icon"
                  type="button"
                  value="Cancel"
                  onClick={onAddPinModalCloseClick}
                />
                <input
                  className="venue-save-icon"
                  type="button"
                  value="Save"
                  data-testid="pin-save"
                  onClick={(e) => {
                    e.preventDefault();
                    handleSubmitAddPinModal();
                  }}
                  disabled={isAddPinSaveDisabled}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
