import React from "react";
import { DebounceInput } from "react-debounce-input";
import { CustomInput } from "./CustomInput";

const SignalInput = ({
  debounceTimeout = 1500,
  placeholder = "ENTER",
  name,
  value,
  onChange,
  disabled,
}) => {
  return (
    <DebounceInput
      className={`inputFields ${!disabled ? "labelClass" : "disable-input"}`}
      type="text"
      element={CustomInput}
      value={value}
      onChange={onChange}
      disabled={disabled}
      debounceTimeout={debounceTimeout}
      placeholder={placeholder}
      name={name}
      autoComplete="off"
    />
  );
};

export default SignalInput;
