import * as actionTypes from "../../store/actions/actionTypes";

const initialState = {
  successMsg: {},
  showModal: false,
  lsAddedFromSearch: false,
  newLiveShotId: "",
  LiveShotsInfo: [],
};

const updateLiveShotInfo = (state, action) => {
  return Object.assign({}, state, {
    LiveShotsInfo: action.LiveShotsInfo,
  });
};

const liveshotAddedFromSearchSuccess = (state, action) => {
  return Object.assign({}, state, { lsAddedFromSearch: true });
};

const resetAddedFromSearchSuccess = (state, action) => {
  return Object.assign({}, state, { lsAddedFromSearch: false });
};

const addNewLiveShot = (state, action) => {
  return Object.assign({}, state, {
    newLiveShotId: action.liveShotId,
  });
};

const showErrorModal = (state, action) => {
  return Object.assign({}, state, {
    errorMsg: action.errorMsg,
    showErrorModal: action.showErrorModal,
  });
};

const hideErrorModal = (state, action) => {
  return Object.assign({}, state, {
    showErrorModal: action.showErrorModal,
    errorMsg: action.errorMsg,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.NEW_LIVE_SHOT:
      return addNewLiveShot(state, action);
    case actionTypes.LIVESHOT_ADDED_FROM_SEARCH:
      return liveshotAddedFromSearchSuccess(state, action);
    case actionTypes.RESET_ADDED_FROM_SEARCH:
      return resetAddedFromSearchSuccess(state, action);
    case actionTypes.SHOW_ERROR_MODAL:
      return showErrorModal(state, action);
    case actionTypes.HIDE_ERROR_MODAL:
      return hideErrorModal(state, action);
    case actionTypes.UPDATE_LIVESHOT_INFO:
      return updateLiveShotInfo(state, action);
    default:
      return state;
  }
};

export default reducer;
