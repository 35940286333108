import React from "react";
import "./NotificationBarComponent.css";
import { ReactComponent as WarningIcon } from "../../../../assets/icons/Warning.svg";
import { ReactComponent as UserFilledIcon } from "../../../../assets/icons/UserIconFilled.svg";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/Close-icon-noborder.svg";

const NotificationBarComponent = ({
  classNames,
  closeErrorBar,
  errorMessage,
  isError,
  isFixed = false,
  show,
}) => {
  return show == undefined || show ? (
    <>
      <div
        className={
          (isError ? "blatant-warning" : "blatant-success") +
          (isFixed ? " position-fixed" : "")
        }
      >
        {isError ? (
          <WarningIcon className="warning-icon-blatant" alt="Warning_icon" />
        ) : (
          <UserFilledIcon className="warning-icon-blatant" alt="Warning_icon" />
        )}

        <span className="blatant-warning-text">{errorMessage}</span>
        <CloseIcon
          className={`ErrorCloseIcon ${classNames}`}
          alt="close_icon"
          onClick={closeErrorBar}
        />
      </div>
    </>
  ) : (
    <></>
  );
};

export default NotificationBarComponent;
