import React, { useState, useEffect } from "react";
import "./CommPills.css";
import HangUpMenu from "../../shared/UI/HangUpMenu/HangUpMenu";
import Button from "../../shared/UI/Button/Button";
import * as Constants from "../../shared/Constants";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/index";
import ArrowDownIcon from "../../../assets/icons/Arrow-down.svg";

const CommPills = (props) => {
  const {
    hookValue,
    globalPathName,
    onSetHookStatus,
    token,
    currentUserRole,
    isMangeDisable,
  } = props;
  const [isVisibleDropdown, setIsVisibleDropdown] = useState(false);
  const [isDisable, setIsDisable] = useState(false);

  useEffect(() => {
    if (
      !isMangeDisable &&
      (currentUserRole.roleName === Constants.SUPER_ADMIN ||
        currentUserRole.roleName === Constants.OPERATIONS_ADMIN ||
        currentUserRole.roleName === Constants.VIEW_ONLY_ALT)
    ) {
      setIsDisable(true);
    }
  }, [currentUserRole.roleName, isMangeDisable]);

  const onDisplayDropDown = () => {
    setIsVisibleDropdown(!isVisibleDropdown);
  };

  const onClickHangUp = () => {
    let result = window.confirm("Are you sure you want to hang up the signal?");
    if (result) {
      onSetHookStatus(token, globalPathName);
      setIsVisibleDropdown(false);
      setIsDisable(true);
    }
  };

  let hangUpMenuList = null;
  if (isVisibleDropdown === true) {
    hangUpMenuList = (
      <HangUpMenu
        isVisibleDropdown={isVisibleDropdown}
        onClickHangUp={onClickHangUp}
        setIsVisibleDropdown={setIsVisibleDropdown}
      />
    );
  }

  let disabledTitle = "";
  if (isDisable) {
    disabledTitle = "Signal must be owned by you to be managed";
  }

  return (
    <div className="commPills">
      <Button
        type="button"
        btntype={hookValue ? "pill comms-btn" : "pill comms-btn change"}
        onClick={onDisplayDropDown}
        disabled={isDisable}
        title={disabledTitle}
      >
        {globalPathName}
        <img src={ArrowDownIcon} alt="arrow down icon" />
      </Button>
      {hangUpMenuList}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.accessToken,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetHookStatus: (token, globalPath) =>
      dispatch(actions.setHookStatus(token, globalPath)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CommPills);
