import React, { useState, useEffect } from "react";
import Button from "../../shared/UI/Button/Button";
import "./ShowDropDown.css";
import SearchList from "./SearchList";
import * as Constants from "../../shared/Constants";
import { timeInHours, timeNight } from "../../shared/utility";
import { ReactComponent as DisplayLogo } from "../../../assets/icons/LSM_display.svg";
import Select from "../../../components/shared/UI/Select/Select";
import { ReactComponent as CloseIcon } from "../../../assets/icons/Close-icon.svg";
import { ReactComponent as SearchIcon } from "../../../assets/icons/search-icon.svg";

const ShowDropDown = (props) => {
  const { list, selectedShow, clearSearchTerm } = props;
  let networkList = props.networkList && props.networkList.length > 0;
  let defaultNetwork = networkList
    ? {
        value: props.networkList[0].networkId,
        key: props.networkList[0].networkId,
        label: props.networkList[0].networkName,
      }
    : "";
  const [searchTerm, setSearchTerm] = useState("");
  const [morningTime, setMorningTime] = useState(false);
  const [dayTime, setDayTime] = useState(false);
  const [primeTime, setPrimeTime] = useState(false);
  const [overNightTime, setOverNightTime] = useState(false);
  const [weekEndTime, setWeekEndTime] = useState(false);
  const [searchNetwork, setSearchNetwork] = useState(1);
  const [selectedNetwork, setSelectedNetwork] = useState("");

  useEffect(() => {
    if (clearSearchTerm) setSearchTerm("");
  }, [clearSearchTerm]);

  const onUpdateSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const onNetworkSearch = (chosenValue) => {
    setSearchNetwork(chosenValue);
    setSelectedNetwork(chosenValue);
  };

  const onMorningTimeSearch = () => {
    setMorningTime(!morningTime);
    setDayTime(false);
    setPrimeTime(false);
    setOverNightTime(false);
    setWeekEndTime(false);
  };
  const onDayTimeSearch = () => {
    setDayTime(!dayTime);
    setMorningTime(false);
    setPrimeTime(false);
    setOverNightTime(false);
    setWeekEndTime(false);
  };
  const onPrimeTimeSearch = () => {
    setPrimeTime(!primeTime);
    setDayTime(false);
    setMorningTime(false);
    setOverNightTime(false);
    setWeekEndTime(false);
  };
  const onOverNightTimeSearch = () => {
    setOverNightTime(!overNightTime);
    setPrimeTime(false);
    setDayTime(false);
    setMorningTime(false);
    setWeekEndTime(false);
  };

  const onWeekEndTimeSearch = () => {
    setWeekEndTime(!weekEndTime);
    setPrimeTime(false);
    setDayTime(false);
    setMorningTime(false);
    setOverNightTime(false);
  };

  let filteredData = null;
  if (searchNetwork) {
    filteredData = list.filter((showList) => {
      return (
        showList.networkId === Number(searchNetwork) &&
        showList.showName.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
  }

  if (morningTime) {
    filteredData = list.filter((showList) => {
      return (
        Constants.MORNING_START_TIME <= timeInHours(showList.startTime) &&
        timeInHours(showList.startTime) <= Constants.MORNING_END_TIME &&
        showList.networkId === Number(searchNetwork) &&
        showList.showName.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
  }
  if (dayTime) {
    filteredData = list.filter((showList) => {
      return (
        Constants.DAYTIME_START_TIME < timeInHours(showList.startTime) &&
        timeInHours(showList.startTime) <= Constants.DAYTIME_END_TIME &&
        showList.networkId === Number(searchNetwork) &&
        showList.showName.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
  }
  if (primeTime) {
    filteredData = list.filter((showList) => {
      return (
        Constants.PRIME_START_TIME < timeInHours(showList.startTime) &&
        timeInHours(showList.startTime) <= Constants.PRIME_END_TIME &&
        showList.networkId === Number(searchNetwork) &&
        showList.showName.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
  }
  if (overNightTime) {
    filteredData = list.filter((showList) => {
      return (
        Constants.OVERNIGHT_START_TIME <= timeNight(showList.startTime) &&
        timeNight(showList.startTime) <= Constants.OVERNIGHT_END_TIME &&
        showList.networkId === Number(searchNetwork) &&
        showList.showName.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
  }

  if (weekEndTime) {
    filteredData = list.filter((showList) => {
      return (
        (showList.occursSat === weekEndTime ||
          showList.occursSun === weekEndTime) &&
        showList.networkId === Number(searchNetwork) &&
        showList.showName.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
  }
  return (
    <div className="container">
      <div className="select-ls-header-row">
        <DisplayLogo alt="display logo" className="search-display-logo" />
        <label className="search-header-title">Select Show</label>
        <CloseIcon className="search-closeicon" onClick={props.modalClosed} />
      </div>
      <div className="select-ls-buttons-row">
        <div>
          <Select
            labelInValue={false}
            popupClassName="show-dropdown"
            onChange={onNetworkSearch}
            style={{ width: 200 }}
            value={selectedNetwork ? selectedNetwork : defaultNetwork}
            options={props.networkList.map((option) => ({
              value: option.networkId,
              key: option.networkId,
              label: option.networkName,
            }))}
          />
        </div>
        <Button btntype={"showtime"} onClick={onMorningTimeSearch}>
          MORNING
        </Button>
        <Button btntype={"showtime"} onClick={onDayTimeSearch}>
          DAYTIME
        </Button>
        <Button btntype={"showtime"} onClick={onPrimeTimeSearch}>
          PRIMETIME
        </Button>
        <Button btntype={"showtime"} onClick={onOverNightTimeSearch}>
          OVERNIGHT
        </Button>
        <Button btntype={"showtime"} onClick={onWeekEndTimeSearch}>
          WEEKEND
        </Button>
      </div>
      <div className="search-ls-border">
        <span className="search-ls-img-input">
          <SearchIcon
            className={`search-ls-icon ${searchTerm ? "enable-search" : ""}`}
          />
          <input
            type="text"
            value={searchTerm}
            placeholder="SEARCH"
            onChange={onUpdateSearch}
            className="search-ls-textbox"
          />
        </span>
      </div>
      <div className="showlist">
        <SearchList
          filteredList={filteredData}
          selectedShowID={(id) => selectedShow(id)}
        />
      </div>
    </div>
  );
};

export default ShowDropDown;
