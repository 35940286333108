import React, { Fragment } from "react";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/index";
import "./NotificationModal.css";
import { ReactComponent as WarningIcon } from "../../../assets/icons/warning-red.svg";
import Backdrop from "../../shared/UI/Backdrop/Backdrop";

const NotificationModal = (props) => {
  const {
    type,
    notification,
    onRelease,
    token,
    onTakeAndGrab,
    currentUser,
    searchList,
    normalLiveShots,
  } = props;

  let userFullName = currentUser.firstName + " " + currentUser.lastName;
  let userId = currentUser.sso;
  if (process.env.REACT_APP_AUTH_ENABLED !== "true") {
    userFullName =
      process.env.REACT_APP_AUTH_OVERRIDE_FIRSTNAME +
      " " +
      process.env.REACT_APP_AUTH_OVERRIDE_LASTNAME;
    userId = process.env.REACT_APP_AUTH_OVERRIDE_ID;
  }

  const onReleaseControlTracker = () => {
    const controlTrackerId = notification.data.controlTrackerId;
    if (controlTrackerId) {
      onRelease(controlTrackerId, token, userId);
      props.closeModal();
    }
  };

  const onGrabControlTracker = () => {
    const field = notification.data.liveshotSignal;

    if (field) {
      onTakeAndGrab(token, "grab", userId, userFullName, field);
    }
  };

  let popupMsg = null;
  if (type === "sessionTimeout" || type === "refresh") {
    popupMsg =
      "Session timed out due to inactivity, please click Refresh to continue.";
  } else if (notification) {
    if (notification.type === "LIVESHOT") {
      popupMsg = `${props.updatedBy} updated the hit for "${notification.data.abstractLiveshot.abstractLiveshotName}" on the visible canvas`;
    } else if (notification.type === "ABSTRACTLIVESHOT") {
      popupMsg = `${props.updatedBy} (editing for ${notification.data.inUseRoomCode}) updated liveshot "${notification.data.abstractLiveshotName}"`;
    } else if (
      notification.type === "LIVESHOTSIGNAL" &&
      (notification.data.abstractLiveshotId !== 0 ||
        notification.method === "PATCH")
    ) {
      let liveshots = [];
      let abstractInfo = null;
      let absLsName = null;
      if (notification.data.abstractLiveshotId !== 0) {
        abstractInfo = searchList.find(
          (data) =>
            data.abstractLiveshotId === notification.data.abstractLiveshotId
        );
        if (abstractInfo) absLsName = abstractInfo.abstractLiveshotName;
      } else {
        if (normalLiveShots.length > 0) {
          liveshots = liveshots.concat(normalLiveShots[0].liveshots);
        }
        abstractInfo = liveshots.find(
          (data) => data.liveshotId === notification.data.liveshotId
        );
        if (abstractInfo)
          absLsName = abstractInfo.abstractLiveshot.abstractLiveshotName;
      }
      popupMsg = `${props.updatedBy} (editing for  ${notification.data.inUseRoomCode}) updated a signal for "${absLsName}".`;
    } else if (
      notification.type === "CANVAS" ||
      notification.type === "BLOCK"
    ) {
      popupMsg = `${props.updatedBy} updated the visible canvas.`;
    } else {
      popupMsg = `${props.updatedBy} updated the signal.`;
    }
  }

  let modalHeader = null;
  if (type === "sessionTimeout") {
    modalHeader = (
      <div className="warning-icon-content">
        <WarningIcon className="warning-icon" alt="Warning_icon" />
        <span className="warning-headline">Alert</span>
      </div>
    );
  }
  // TODO - Comment out for now.. Need to check on this condition before removing this
  // else {
  //   modalHeader = (
  //     <div className="modal-header">
  //       <button
  //         type="button"
  //         className="close"
  //         aria-label="Close"
  //         onClick={props.closeModal}
  //       >
  //         &times;
  //       </button>
  //     </div>
  //   );
  // }
  let modalBody = null;
  if (type === "refresh") {
    modalHeader = (
      <div className="warning-icon-content">
        <WarningIcon className="warning-icon" alt="Warning_icon" />
        <span className="warning-headline">Warning</span>
      </div>
    );
    modalBody = (
      <Fragment>
        <div className="warning-content">{popupMsg}</div>
        <div className="warning-buttons">
          <button
            type="button"
            className="delete-submit-btn warning-btn"
            onClick={props.onConfirm}
          >
            REFRESH
          </button>
        </div>
      </Fragment>
    );
  } else if (type === "sessionTimeout") {
    modalBody = (
      <Fragment>
        <div className="warning-content">{popupMsg}</div>
        <div className="warning-buttons">
          <button
            type="button"
            className="delete-submit-btn warning-btn"
            onClick={props.onConfirm}
          >
            REFRESH
          </button>
        </div>
      </Fragment>
    );
  } else if (type === "grab") {
    modalBody = (
      <Fragment>
        <div className="modal-body">
          <p>
            {notification.data.requesterFullName} in Control Room{" "}
            {notification.data.controlRoomCode} is requesting to own "
            {notification.data.liveshotSignal.globalPathName}"
          </p>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-default"
            onClick={onReleaseControlTracker}
          >
            RELEASE
          </button>
          <button
            type="button"
            className="btn btn-default"
            onClick={props.closeModal}
          >
            CLOSE
          </button>
        </div>
      </Fragment>
    );
  } else if (type === "release") {
    modalBody = (
      <Fragment>
        <div className="modal-body">
          <p>
            "{notification.data.liveshotSignal.globalPathName}" was released
            from Control Room "{notification.data.controlRoomCode}"
          </p>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-default"
            onClick={onGrabControlTracker}
          >
            OWN
          </button>
          <button
            type="button"
            className="btn btn-default"
            onClick={props.closeModal}
          >
            CLOSE
          </button>
        </div>
      </Fragment>
    );
  }

  return (
    <>
      {props.show && <Backdrop show={props.show} />}
      {props.show && (
        <div className="popup-modal  trashInfo-container warning-popup">
          {modalHeader}
          {modalBody}
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.accessToken,
    currentUser: state.auth.currentUser,
    searchList: state.searchListInfo.searchList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onTakeAndGrab: (token, control, userId, userFullName, signalInfo) =>
      dispatch(
        actions.takeAndGrab(token, control, userId, userFullName, signalInfo)
      ),
    onRelease: (controlTrackerId, token, userId) =>
      dispatch(actions.releaseControltracker(controlTrackerId, token, userId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationModal);
