import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";
import Select from "../shared/UI/Select/Select";

const ViewRoleAs = (props) => {
  const {
    token,
    onFetchRoleList,
    roleList,
    currentUserRoleId,
    onChangeUserRole,
  } = props;

  const [currentRole, setCurrentRole] = useState(currentUserRoleId);

  useEffect(() => {
    onFetchRoleList(token);
  }, [token, onFetchRoleList]);

  const setUserRole = (value) => {
    if (value !== "") {
      setCurrentRole(value);
      onChangeUserRole(Number(value), token);
    }
  };

  let role_dropdown = roleList.map((role) => ({
    key: role.roleId,
    label: role.roleName,
    value: role.roleId,
  }));

  return process.env.REACT_APP_ENV_NAME === "local" ? (
    <Select
      labelInValue={false}
      style={{ width: 230 }}
      value={currentRole}
      onChange={setUserRole}
      options={role_dropdown}
      placeholder="VIEW ROLE AS"
    />
  ) : null;
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.accessToken,
    roleList: state.user.roleList,
    currentUser: state.auth.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchRoleList: (token) => dispatch(actions.fetchRoleList(token)),
    onChangeUserRole: (roleId, token) =>
      dispatch(actions.changeUserRole(roleId, token)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewRoleAs);
