import React, { useEffect, useRef, useState } from "react";
import ClickAwayListenerHoc from "../shared/UI/ClickAwayListenerHoc";
import DropdownSuggestions from "../dropdownSuggestions/DropdownSuggestions";
import { DebounceInput } from "react-debounce-input";
import { ReactComponent as DropDownArrowIcon } from "../../assets/icons/Arrow-down.svg";

const Autocomplete = (props) => {
  const {
    options,
    onChange,
    value,
    nodeRef,
    placeholder,
    disabled,
    length,
    marginTop,
    name,
    required,
  } = props;

  const compRef = useRef(null);

  const [showOptions, setShowOptions] = useState(false);
  const [focus, setFocus] = useState(false);
  const [filterKey, setFilterKey] = useState("");
  const [filteredOption, setFilterOptions] = useState([]);

  useEffect(() => {
    if (filterKey && filterKey != "") {
      let filteredOption = options.filter((item) =>
        item.toLowerCase().includes(filterKey.trim().toLowerCase())
      );
      setFilterOptions(filteredOption);
      if (filteredOption.length == 0) {
        showOptions && setShowOptions(false);
      } else {
        focus && setShowOptions(true);
      }
    } else {
      setFilterOptions(options);
      if (focus) {
        setShowOptions(options.length != 0);
      }
    }
  }, [options, filterKey]);

  return (
    <>
      <ClickAwayListenerHoc
        nodeRef={compRef.current}
        hideElement={() => {
          setShowOptions(false);
          setFocus(false);
        }}
      >
        <div
          style={{ position: "relative" }}
          className="autocompleteInput-ctnr"
          onFocus={() => {
            setShowOptions(true);
          }}
          ref={compRef}
        >
          <DebounceInput
            className={`autocompleteInput ${required ? "required" : ""} ${
              value ? "value-filled" : ""
            } ${showOptions ? "focused" : ""}`}
            debounceTimeout={500}
            autoComplete="off"
            value={value || ""}
            placeholder={placeholder}
            onChange={(e) => {
              onChange(e.target.value, e);
              setFilterKey(e.target.value);
            }}
            disabled={disabled}
            onFocus={() => {
              setFilterKey("");
              setFocus(true);
            }}
            onBlur={() => {
              setFocus(false);
            }}
            name={name}
          />

          <div
            className={`dropdown-arrow-icon ${
              showOptions || value ? "focused" : ""
            }`}
            onClick={() => {
              if (!showOptions) {
                setFilterKey("");
              }
              setShowOptions(!showOptions);
            }}
          >
            <DropDownArrowIcon
              className={`${showOptions ? " open" : ""}`}
              alt="Plus Logo"
            />
          </div>

          {showOptions && (
            <DropdownSuggestions
              options={filteredOption}
              selected={value}
              onSelect={(option, key) => {
                onChange(option, undefined, true);
                setShowOptions(false);
              }}
              length={length}
              marginTop={marginTop}
            />
          )}
        </div>
      </ClickAwayListenerHoc>
    </>
  );
};

export default Autocomplete;
