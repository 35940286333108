import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/index";
import { format } from "date-fns";

const SearchList = (props) => {
  const {
    token,
    onUpdateCurrentShow,
    selectedShowID,
    onFetchCurrentCanvas,
    filteredList,
    currentUser,
    onSearchCurrentLiveShot,
    facilityList,
  } = props;

  const [filteredShowDetailList, setFilteredShowDetailList] = useState([]);

  let userId = currentUser.sso;
  if (process.env.REACT_APP_AUTH_ENABLED !== "true") {
    userId = process.env.REACT_APP_AUTH_OVERRIDE_ID;
  }

  useEffect(() => {
    let filteredShowList = [];
    filteredList &&
      filteredList.forEach((show) => {
        let facilityName;
        let showFacility = facilityList.find(
          (facility) => facility.facilityId === show.facilityId
        );
        if (showFacility) {
          facilityName = showFacility.facilityName;
          filteredShowList.push({
            ...show,
            facilityName: facilityName,
          });
        } else {
          filteredShowList.push(show);
        }
      });
    let sortedShowList = filteredShowList.sort(function (a, b) {
      let date = format(new Date(), "yyyy/MM/dd ");
      return new Date(date + a.startTime) - new Date(date + b.startTime);
    });
    setFilteredShowDetailList(sortedShowList);
  }, [filteredList, facilityList]);

  const onSelectShow = (show) => {
    onUpdateCurrentShow(token, show);
    onFetchCurrentCanvas(token, show, userId);
    selectedShowID(show);
    onSearchCurrentLiveShot(token);
  };

  const searchData = filteredShowDetailList.map((list) => {
    return (
      <li
        key={list.showId}
        value={list.showId}
        onClick={() => onSelectShow(list.showId)}
      >
        <div className="select-ls-row">
          <div className="select-ls-showname">{list.showName}</div>
          <div className="select-ls-facility">{list.facilityName}</div>
          <div className="select-ls-time">
            {list.startTime}-{list.endTime}
          </div>
        </div>
      </li>
    );
  });

  return (
    <div>
      <ul>{searchData}</ul>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.accessToken,
    currentShow: state.showInfo.currentShow,
    currentUser: state.auth.currentUser,
    facilityList: state.showInfo.facilityList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateCurrentShow: (token, showId) =>
      dispatch(actions.updateCurrentShow(token, showId)),
    onFetchCurrentCanvas: (token, showId, userId) =>
      dispatch(actions.fetchCurrentCanvas(token, showId, userId)),
    onSearchCurrentLiveShot: (token) => dispatch(actions.searchLiveShot(token)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchList);
