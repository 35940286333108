import React, { useEffect, useMemo, useState } from "react";
import "./SignalModal.css";
import { ReactComponent as RouteSuccessInfo } from "../../../assets/icons/routes-success-info.svg";
import { ReactComponent as RouteFailedInfo } from "../../../assets/icons/routes-failed-info.svg";
import { ReactComponent as RoutesDuplicateInfo } from "../../../assets/icons/routes-duplicate-info.svg";
import { ReactComponent as CloseIcon } from "../../../assets/icons/Close-icon.svg";
import { ReactComponent as CommonSuccessInfo } from "../../../assets/icons/common-success-info.svg";
import Backdrop from "../../shared/UI/Backdrop/Backdrop";
import { ReactComponent as WarningIcon } from "../../../assets/icons/Warning.svg";

const SignalModal = (props) => {
  const { signalInfo, closeModal } = props;

  const [title, setTitle] = useState("");
  const [headerIconType, setHeaderIconType] = useState("");
  const [modalStyle, setModalStyle] = useState("");
  const [closeIconStyle, setCloseIconStyle] = useState("");
  const [msgText, setMsgText] = useState("");
  const [msgContent, setMsgContent] = useState("");
  const [footerButtons, setFooterButtons] = useState("");
  const [signalInfoObj, setSignalInfoObj] = useState({});

  useEffect(() => {
    let isDuplicate = signalInfo.isDuplicate;
    let isOk = [200].includes(signalInfo.status);
    let isSuccess = [200, 202].includes(signalInfo.status);
    let isFailed = [500, 409, 404, 400].includes(signalInfo.status);

    setSignalInfoObj(signalInfo);
    setMsgText("");
    setMsgContent("");
    setFooterButtons(getFooterBtns(""));

    setModalStyle(
      isDuplicate || signalInfo.control === "unOwn"
        ? "signalsYellowBorder"
        : isSuccess
        ? "signalsGreenBorder"
        : "signalsRedBorder"
    );
    setCloseIconStyle(
      isFailed
        ? "error-close-icon"
        : isDuplicate || signalInfo.control === "unOwn"
        ? "duplicate-close-icon"
        : ""
    );

    // scenario - use signal message
    if (signalInfo.control === "take") {
      if (isOk) {
        setHeaderIconType("success");
        setTitle("Route Successful");
      } else {
        setHeaderIconType("failed");
        setTitle("Route Failed");
      }
      setMsgText(
        `Local Path: ${signalInfo.crPathName} and Global Path: ${
          signalInfo.globalPathName
        } are ${signalInfo.status === 200 ? "" : "not"} routed.`
      );
    }

    // scenario - own signal success
    if (signalInfo.control === "grab") {
      if (isSuccess) {
        setHeaderIconType("success");
        setTitle("Route Successful");
        setMsgText(
          `Local Path: ${signalInfo.crPathName} ${
            signalInfo.globalPathName
              ? `and Global Path: ${signalInfo.globalPathName} `
              : ""
          }are routed.`
        );
      } else {
        setHeaderIconType("failed");
        if (signalInfo.status === 409) {
          setTitle("Error");

          let pathName =
            signalInfo.signalType === "outbound"
              ? signalInfo.globalPathName
              : signalInfo.crPathName;
          let res = signalInfo.response.data;
          let showName = res.show.showName;
          let startTime = res.liveshot.windowStartTime;
          let endTime = res.liveshot.windowEndTime;
          let dateParts = res.canvas.showDate.split("-");
          let canvasShowDate = `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;

          setMsgText(
            `${pathName} is already owned on another canvas. ${showName} on ${canvasShowDate} is using ${pathName} from ${startTime} to ${endTime}. /n` +
              `If you would like to OWN this signal, please confirm that you would like to un-OWN the signal from ${showName}.`
          );
          setFooterButtons(
            getConfirmFooterBtns(confirmOverideSignal, signalInfo, true)
          );
        } else {
          setTitle("Route Failed");
          setMsgText(
            `Local Path: ${signalInfo.crPathName} ${
              signalInfo.globalPathName
                ? `and Global Path: ${signalInfo.globalPathName} `
                : ""
            }are not routed.`
          );
          setFooterButtons(getFooterBtns("error-close-btn"));
        }
      }
    }

    // scenario for duplicate local path
    if (isDuplicate) {
      setHeaderIconType("duplicate");
      setTitle("Duplicate Route");

      setMsgText(
        `${
          signalInfo.signalType === "outbound" ? "Global Path" : "Local Path"
        }: ${
          signalInfo.signalType === "outbound"
            ? signalInfo.globalPathName
            : signalInfo.crPathName
        } was previously routed. Would you like to overwrite it and continue routing all signals?`
      );
      setFooterButtons(getConfirmFooterBtns(confirmDuplicateRoute, signalInfo));
    }

    //scenario - unOwn signal success
    if (signalInfo.control === "unOwn" && isSuccess) {
      setHeaderIconType("warning");
      setTitle("Warning");
      setFooterButtons(getFooterBtns("duplicate-close-btn"));
      setMsgText(
        `Local Path: ${signalInfo.crPathName} ${
          signalInfo.globalPathName
            ? `and Global Path: ${signalInfo.globalPathName} `
            : ""
        } route has been removed.`
      );
    }

    // scenario for 'own' signal in canvas/block/summary
    if (signalInfo.control === "takeAll" || signalInfo.control === "grabAll") {
      if (isSuccess) {
        setTitle("Routes Info");
        setHeaderIconType("commonSuccess");
        setMsgContent(
          <>
            {signalInfo.signals.map((signal, index) => {
              let success = [200, 202].includes(signal.status);
              return (
                <div className="textMsg" key={"status-ms" + index}>
                  <span
                    className={
                      "statusChip " +
                      (success ? "success-chip" : "failure-chip")
                    }
                  >
                    {success ? "ROUTED" : "FAILED"}
                  </span>
                  <span className="textInfo">{`${
                    (signal.crPathName
                      ? `Local Path: ${signal.crPathName}`
                      : "") +
                    (signal.globalPathName
                      ? ` and Global Path: ${signal.globalPathName}`
                      : "")
                  }`}</span>
                </div>
              );
            })}
          </>
        );
      } else {
        setMsgContent(
          signalInfo.signals.map((field, idx) => {
            let grabbed = field.status === 500 ? "not" : "";
            let text = field.liveshotSignalTypeId !== 1 ? "grabbed" : "taken";
            return (
              <>
                <p
                  className={field.status === 500 ? "text-danger" : "taken"}
                  key={idx}
                >{`CR Path: ${field.crPathName} and Global Path: ${field.globalPathName} have ${grabbed} ${text}`}</p>
                <p className="text-danger">
                  Error status code: {signalInfo.status}
                </p>
                <p className="text-danger">Message: {signalInfo.msg}</p>
              </>
            );
          })
        );
      }
    }
  }, [signalInfo]);

  let signalMsg = null;
  if (props.crMsg) {
    signalMsg = (
      <p className="text-danger">Signal is not available for route</p>
    );
  }

  const getFooterBtns = (footerBtnCls) => {
    return (
      <div className="footer-btn-padding">
        <input
          className={footerBtnCls != "" ? footerBtnCls : "venue-save-icon"}
          type="button"
          value="Close"
          onClick={closeModal}
        />
      </div>
    );
  };

  const getConfirmFooterBtns = (handleConfirm, signalInfo, isError) => {
    return (
      <div className="warning-buttons footer-btn-padding">
        <button
          className={isError ? "error-cancel-btn" : "duplicate-cancel-btn"}
          onClick={() => {
            handleConfirm(signalInfo, true);
          }}
        >
          NO
        </button>
        <button
          className={isError ? "error-close-btn" : "duplicate-close-btn"}
          onClick={() => handleConfirm(signalInfo, false)}
        >
          YES
        </button>
      </div>
    );
  };

  const confirmOverideSignal = (signalInfoObj, isSkip) => {
    props.closeModal();
    if (signalInfoObj.isSkipOverride != undefined || !isSkip) {
      let crPath = signalInfoObj.crPath;
      delete signalInfoObj.crPath;
      signalInfoObj.overrideCanvasSignal(signalInfoObj, crPath, isSkip);
    }
  };

  const confirmDuplicateRoute = (signalInfoObj, isSkip) => {
    props.closeModal();
    if (signalInfoObj.isSkipDuplicate != undefined || !isSkip) {
      delete signalInfoObj.isDuplicate;
      let crPath = signalInfoObj.crPath;
      delete signalInfoObj.crPath;
      let btnType = signalInfoObj.btnType;
      delete signalInfoObj.btnType;
      let isUse = signalInfoObj.isUse;
      delete signalInfoObj.isUse;

      signalInfoObj &&
        signalInfoObj.confirmDuplicateRoute(
          signalInfoObj,
          crPath,
          btnType,
          isSkip,
          isUse
        );
    }
  };

  const ModalIcon = useMemo(() => {
    const iconMap = {
      success: <RouteSuccessInfo className="modalIcon" alt="Success icon" />,
      commonSuccess: (
        <CommonSuccessInfo className="modalIcon" alt="Success icon" />
      ),
      failed: <RouteFailedInfo className="modalIcon" alt="Failed icon" />,
      duplicate: (
        <RoutesDuplicateInfo className="modalIcon" alt="Duplicate icon" />
      ),
      warning: <WarningIcon className="modalIcon" alt="Warning icon" />,
    };

    return iconMap[headerIconType];
  }, [headerIconType]);

  return (
    props.showSignalModal && (
      <>
        <Backdrop
          show={props.showSignalModal}
          clicked={() => props.closeModal()}
        />
        <div className="popup-modal-route">
          <div className={`modal-content signal-msg-content-box ${modalStyle}`}>
            <div className="modal-header headerDiv">
              {ModalIcon}
              <p className="headerText">{title}</p>
              <CloseIcon
                onClick={props.closeModal}
                className={`closeIcon signals-close-icon ${closeIconStyle}`}
              />
            </div>
            <div className="modal-body signal-msg-body">
              {msgText != "" && (
                <div className="msg-content">
                  {msgText.split("/n").map((msgText, index) => {
                    return (
                      <p key={index} className="textInfo">
                        {" "}
                        {msgText}{" "}
                      </p>
                    );
                  })}
                </div>
              )}
              {msgContent}
              {signalMsg}
            </div>
            <div className="modal-footer signalModalFooter">
              {footerButtons}
            </div>
          </div>
        </div>
      </>
    )
  );
};

export default SignalModal;
