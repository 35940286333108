import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";
import { Draggable, Droppable } from "react-beautiful-dnd";
import useMousetrap from "react-hook-mousetrap";
import Button from "../../components/shared/UI/Button/Button";
import { removeDuplicates } from "../../components/shared/utility";
import { ReactComponent as ArrowIcon } from "../../assets/icons/block-header-arrow.svg";
import { ReactComponent as GrabberIcon } from "../../assets/icons/block-header-grabber.svg";
import { ReactComponent as PlusIcon } from "../../assets/icons/block-header-plus.svg";
import { ReactComponent as TrashIcon } from "../../assets/icons/block-header-trash.svg";
import { ReactComponent as AddIcon } from "../../assets/icons/add-icon.svg";
import LiveShotContainer from "../LiveShotContainer/LiveShotContainer";
import Searchbox from "../../utils/searchbox/Searchbox";
import "../../utils/searchbox/searchitem/Searchitem.css";
import Modal from "../../components/shared/UI/Modal/Modal";
import { BLOCK_DELETE_CONFIRMATION_MESSAGE } from "../../constants/PageConstants";
import { useUser } from "../../hooks/useUser";
import { POPMESSAGE } from "../../components/shared/Constants";

const BlockContainer = (props) => {
  const {
    takeAllPerm,
    grabAllPerm,
    deleteBlockPerm,
    updateBlockPerm,
    addBlockPerm,
  } = useUser();
  const {
    block,
    index,
    type,
    blockLiveshotIdList,
    canvasLiveshotList,
    removeBlock,
    onUpdateBlock,
    isLastBlock,
    token,
    currentUser,
    outofOrderLS,
    onResetLSCreation,
    currentUserRole,
    searchList,
    onStartLSCreation,
    addBlock,
    liveShotSignalSelected,
    onTakeAllCanvasBlock,
    onGrabAllCanvasBlock,
    showCommonPopupModal,
    currentCanvas,
    routingCapabilityType,
    controlRoomList,
    isArmRouting,
    inBoundCRPath,
    outBoundCRPath,
    ifbCRPath,
    plCRPath,
    phoCRPath,
    shortCut,
    lsAddedFromSearch,
    newLiveShotId,
    isDisable,
  } = props;

  const [takeGrabBtn, setTakeGrabBtn] = useState(false);
  const [createNewLS, setCreateNewLS] = useState(false);
  const [lsType, setLsType] = useState("");
  const [isError] = useState(false);
  const [showErrorBar, setShowErrorBar] = useState(false);
  const [currentRoom, setCurrentRoom] = useState(null);
  const [isNewLiveShotsAdded, setIsNewLiveShotsAdded] = useState(false);

  let userFullName = currentUser.firstName + " " + currentUser.lastName;
  let userId = currentUser.sso;
  if (process.env.REACT_APP_AUTH_ENABLED !== "true") {
    userFullName =
      process.env.REACT_APP_AUTH_OVERRIDE_FIRSTNAME +
      " " +
      process.env.REACT_APP_AUTH_OVERRIDE_LASTNAME;
    userId = process.env.REACT_APP_AUTH_OVERRIDE_ID;
  }

  const onAddLiveShot = (event) => {
    const target = event.target.getAttribute("type");
    setLsType(target);
    setCreateNewLS(true);
    onResetLSCreation();
  };
  useMousetrap(["l", "L", "t", "T"], (e) => {
    e.preventDefault();
    if ((e.key === "l" || e.key === "L") && shortCut) {
      createLiveShot();
    }
    if ((e.key === "t" || e.key === "T") && shortCut) {
      scrollTop();
    }
  });
  const scrollTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };
  useEffect(() => {
    if (lsAddedFromSearch) {
      setIsNewLiveShotsAdded(true);
    } else {
      setIsNewLiveShotsAdded(false);
    }
  }, [lsAddedFromSearch]);
  useEffect(() => {
    if (routingCapabilityType >= 2) {
      setTakeGrabBtn(true);
    } else {
      setTakeGrabBtn(false);
    }
  }, [routingCapabilityType, takeGrabBtn]);

  useEffect(() => {
    if (currentCanvas && controlRoomList.length > 0) {
      let room = controlRoomList.find(
        (list) => list.roomId === currentCanvas.roomId
      );
      if (room) {
        setCurrentRoom(room);
      }
    }
  }, [controlRoomList, currentCanvas, currentRoom]);

  const [updatedBy] = useState(userId);
  const [blockLetter, setBlockLetter] = useState(
    block.blockName.replace(" BLOCK", "").replace(" Block", "").toUpperCase()
  );

  const openErrorBar = (message) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const closeErrorBar = () => {
    setShowErrorBar(false);
  };

  const deleteBlock = () => {
    removeBlock(block.blockId);
  };
  const onClickRemoveBlock = () => {
    let ownedSignals = liveShotSignalSelected.filter(
      (ls) =>
        blockLiveshotIdList.includes(ls.liveshotId) &&
        (ls.liveshotSignalTypeId === 2 ||
          ls.liveshotSignalTypeId === 5 ||
          ls.liveshotSignalTypeId === 7 ||
          ls.liveshotSignalTypeId === 8 ||
          ls.liveshotSignalTypeId === 9) &&
        ls.routed
    );

    if (ownedSignals && ownedSignals.length > 0) {
      showCommonPopupModal({
        showModal: true,
        title: "Warning",
        content: POPMESSAGE.DELETE_OWNED_BLOCK,
        type: "warning",
      });
    } else {
      showCommonPopupModal({
        showModal: true,
        onModalClose: () => {
          showCommonPopupModal({ showModal: false });
        },
        onConfirm: () => {
          showCommonPopupModal({ showModal: false });
          deleteBlock();
        },
        content: BLOCK_DELETE_CONFIRMATION_MESSAGE,
        title: "Warning",
        type: "confirm",
      });
    }
  };

  const onClickAddBlock = () => {
    addBlock();
  };

  const changeBlockName = (event) => {
    setBlockLetter(event.target.value);
  };

  const onClickSaveBlock = (event) => {
    event.preventDefault();
    if (blockLetter && blockLetter !== "") {
      const blockId = block.blockId;
      const newBlockName = blockLetter;
      const payload = {
        blockName: newBlockName,
        updatedBy: userId,
      };
      onUpdateBlock(updatedBy, blockId, payload, block.canvasId, token);
    }
  };

  const createLiveShot = () => {
    onStartLSCreation(block.blockId);
  };

  const onClickTakeAll = (key) => {
    let showMessage = false;
    if (liveShotSignalSelected.length > 0) {
      if (blockLiveshotIdList && canvasLiveshotList.length > 0) {
        if (canvasLiveshotList.length > 0) {
          let normalLiveshot = canvasLiveshotList.filter(
            (liveShot) =>
              liveShot.softDelete !== true &&
              liveShot.blockId === block.blockId &&
              (liveShot.readyForPcr === true || liveShot.readyForAir === true)
          );
          let abstractArray = [];
          let normalArray = [];
          let signalArr,
            normalSignalInfo,
            outBoundFields,
            outBoundFieldsNormal,
            commsFields,
            commsFieldsNormal;
          for (let index in normalLiveshot) {
            signalArr = removeDuplicates(liveShotSignalSelected).filter(
              (signal) =>
                signal.abstractLiveshotId ===
                  normalLiveshot[index].abstractLiveshot.abstractLiveshotId &&
                signal.liveshotSignalTypeId === 1
            );
            normalSignalInfo = removeDuplicates(liveShotSignalSelected).filter(
              (signal) =>
                signal.liveshotId === normalLiveshot[index].liveshotId &&
                signal.liveshotSignalTypeId === 0
            );
            for (let key in signalArr) {
              const signal = signalArr[key];
              const normalSignal = normalSignalInfo.find(
                (item) =>
                  item.abstractLiveshotSignalId === signal.liveshotSignalId
              );
              normalArray.push(signal);
              normalSignal && abstractArray.push(normalSignal);
            }
          }
          let liveshotWithOutGlobalPath = normalArray.filter(
            (signal) => signal.liveshotId === 0 && signal.globalPathName === ""
          );
          let liveshotNormalSignal = normalArray.filter(
            (signal) => signal.liveshotId === 0 && signal.globalPathName
          );
          let liveshotAbstractUnilateralSignal = normalArray.filter(
            (signal) => signal.liveshotId === 0 && signal.uniLateral === false
          );
          let liveshotSignalNormal = liveshotNormalSignal.concat(
            liveshotAbstractUnilateralSignal
          );
          let liveshotAbstractWithOutGlobalPath = abstractArray.filter(
            (signal) =>
              signal.abstractLiveshotId === 0 && signal.crPathName === ""
          );
          let liveshotAbstractSignal = abstractArray.filter(
            (signal) =>
              signal.abstractLiveshotId === 0 && signal.crPathName !== ""
          );
          let liveshotSignalAbstarct = liveshotAbstractSignal.filter(
            (signal, ind) =>
              ind ===
              liveshotAbstractSignal.findIndex(
                (elem) =>
                  elem.liveshotSignalTypeId === signal.liveshotSignalTypeId &&
                  elem.crPathName === signal.crPathName
              )
          );
          let liveshotSignalAbstarctDuplicate = liveshotAbstractSignal.filter(
            (signal, ind) =>
              ind !==
              liveshotAbstractSignal.findIndex(
                (elem) => elem.crPathName === signal.crPathName
              )
          );
          let liveshotSignalFiltered = liveshotSignalNormal.concat(
            liveshotSignalAbstarct,
            liveshotWithOutGlobalPath,
            liveshotAbstractWithOutGlobalPath
          );
          let liveshotSignalDuplicates = liveshotSignalAbstarctDuplicate;
          if (liveshotSignalDuplicates.length > 0) {
            for (let key in liveshotSignalDuplicates) {
              let duplicateSignal = liveshotSignalDuplicates[key];
              if (duplicateSignal.liveshotId === 0) {
                let signal = liveShotSignalSelected.filter(
                  (signal) =>
                    signal.abstractLiveshotSignalId ===
                    duplicateSignal.liveshotSignalId
                );
                duplicateSignal.crPathName = signal[0].crPathName;
              } else {
                let signal = liveShotSignalSelected.filter(
                  (signal) =>
                    signal.liveshotSignalId ===
                    duplicateSignal.abstractLiveshotSignalId
                );
                duplicateSignal.globalPathName = signal[0].globalPathName;
              }
            }
          }

          for (let index in normalLiveshot) {
            signalArr = removeDuplicates(liveShotSignalSelected).filter(
              (signal) =>
                signal.abstractLiveshotId ===
                  normalLiveshot[index].abstractLiveshot.abstractLiveshotId &&
                signal.liveshotSignalTypeId === 1
            );
            normalSignalInfo = removeDuplicates(liveShotSignalSelected).filter(
              (signal) =>
                signal.liveshotId === normalLiveshot[index].liveshotId &&
                signal.liveshotSignalTypeId === 0
            );
            for (let key in signalArr) {
              const signal = signalArr[key];
              const normalSignal =
                normalSignalInfo.find(
                  (item) =>
                    item.abstractLiveshotSignalId === signal.liveshotSignalId
                ) || {};
              let inBoundCrPathInfo = inBoundCRPath.find(
                (item) => item === normalSignal.crPathName
              );
              if (normalSignal.crPathName === "" || !inBoundCrPathInfo) {
                showMessage = true;
              }
            }
            outBoundFields = removeDuplicates(liveShotSignalSelected).filter(
              (signal) =>
                signal.abstractLiveshotId ===
                  normalLiveshot[index].abstractLiveshot.abstractLiveshotId &&
                signal.liveshotSignalTypeId === 2
            );
            outBoundFieldsNormal = removeDuplicates(
              liveShotSignalSelected
            ).filter(
              (signal) =>
                signal.liveshotId === normalLiveshot[index].liveshotId &&
                signal.liveshotSignalTypeId === 0
            );
            for (let key in outBoundFields) {
              const signal = outBoundFields[key];
              const normalSignal =
                outBoundFieldsNormal.find(
                  (item) =>
                    item.abstractLiveshotSignalId === signal.liveshotSignalId
                ) || {};

              let outBoundCrPathInfo =
                normalSignal &&
                outBoundCRPath.find((item) => item === normalSignal.crPathName);
              if (normalSignal.crPathName === "" || !outBoundCrPathInfo) {
                showMessage = true;
              }
            }
            commsFields = removeDuplicates(liveShotSignalSelected).filter(
              (signal) =>
                signal.abstractLiveshotId ===
                  normalLiveshot[index].abstractLiveshot.abstractLiveshotId &&
                (signal.liveshotSignalTypeId === 5 ||
                  signal.liveshotSignalTypeId === 8 ||
                  signal.liveshotSignalTypeId === 9)
            );
            commsFieldsNormal = removeDuplicates(liveShotSignalSelected).filter(
              (signal) =>
                signal.liveshotId === normalLiveshot[index].liveshotId &&
                signal.liveshotSignalTypeId === 0
            );
            for (let key in commsFields) {
              const signal = commsFields[key];
              const normalSignal =
                commsFieldsNormal.find(
                  (item) =>
                    item.abstractLiveshotSignalId === signal.liveshotSignalId
                ) || {};
              let commCrPathInfo = true;
              const commCRArray = [...ifbCRPath, ...plCRPath, ...phoCRPath];
              commCrPathInfo = commCRArray.find(
                (item) => item === normalSignal.crPathName
              );

              if (normalSignal.crPathName === "" || !commCrPathInfo) {
                showMessage = true;
              }
            }
          }

          if (key === "take") {
            onTakeAllCanvasBlock(
              token,
              userId,
              userFullName,
              normalLiveshot,
              liveShotSignalSelected,
              currentCanvas.roomId,
              currentRoom,
              liveshotSignalDuplicates
            );
          } else {
            onGrabAllCanvasBlock(
              token,
              userId,
              userFullName,
              normalLiveshot,
              liveShotSignalSelected,
              currentCanvas.roomId,
              currentCanvas,
              currentRoom,
              outBoundFieldsNormal,
              commsFieldsNormal
            );
          }
        }
      }
    }
  };

  let notAllowTakeAll = false;
  let notAllowGrabAll = false;
  if (isArmRouting === false || !takeAllPerm) {
    notAllowTakeAll = true;
  }
  if (isArmRouting === false || !grabAllPerm) {
    notAllowGrabAll = true;
  }

  let blockTakeAndGrab = null;
  if (takeGrabBtn) {
    blockTakeAndGrab = (
      <div className="normalblock-Take-Grab">
        <div className="title-block">
          <span>{blockLetter}</span> Block
        </div>
        <div className="take-grab-btns">
          <Button
            type="button"
            btntype="takeall"
            onClick={() => onClickTakeAll("take")}
            disabled={notAllowTakeAll}
          >
            USE
          </Button>
          <Button
            type="button"
            btntype="graball"
            onClick={() => onClickTakeAll("grab")}
            disabled={notAllowGrabAll}
          >
            OWN
          </Button>
        </div>
      </div>
    );
  }

  let blockAddButton = false;
  if (addBlockPerm && isLastBlock === true) {
    blockAddButton = true;
  }
  return (
    <>
      <Modal
        show={createNewLS}
        modalClosed={() => setCreateNewLS(false)}
        className="createliveshotmodal"
        classOuterBox={createNewLS ? "add-ls-outer-box" : ""}
      >
        <Searchbox
          blockId={block.blockId}
          type={lsType}
          closeErrorBar={closeErrorBar}
          openErrorBar={openErrorBar}
          isError={isError}
          modalClosed={() => setCreateNewLS(false)}
          currentUserRole={currentUserRole}
        />
      </Modal>
      <Draggable
        draggableId={block.blockId.toString()}
        index={index}
        isDragDisabled={!isDisable}
      >
        {(provided, snapshot) => (
          <div
            id={block.blockId}
            className="normalblockwrapper"
            {...provided.draggableProps}
            ref={provided.innerRef}
            style={{
              borderColor: snapshot.isDragging ? "#87D1AE" : "#1D1D1D",
              ...provided.draggableProps.style,
            }}
          >
            <div className="block-header-container">
              <div className="normalblockheader">
                <div
                  {...provided.dragHandleProps}
                  className={!isDisable ? "hide-add-icon" : "grabber-btn"}
                >
                  <GrabberIcon title="MOVE BLOCK" />
                </div>
                <div className="scrolltop_btn" onClick={scrollTop}>
                  <ArrowIcon title="TOP OF PAGE" />
                </div>
                <div
                  className={
                    !deleteBlockPerm ? "hide-delete-icon" : "remove-block"
                  }
                  onClick={onClickRemoveBlock}
                >
                  <TrashIcon title="DELETE BLOCK" />
                </div>
                <div
                  className={!blockAddButton ? "hide-add-icon" : "add-block"}
                  onClick={onClickAddBlock}
                >
                  <PlusIcon title="ADD A BLOCK" />
                </div>
              </div>
              <div className="block-label-container">
                <div className="block-section">
                  <span className="blocks">Block Label: </span>
                </div>
                <div id="block-name-div">
                  <input
                    type="text"
                    className="block-name"
                    value={blockLetter}
                    disabled={!isDisable}
                    onChange={changeBlockName}
                    maxLength="4"
                  />
                </div>

                <div className="save-block-name-div" onClick={onClickSaveBlock}>
                  <input
                    className="save-block-name-text"
                    type="button"
                    value="SAVE"
                    disabled={!updateBlockPerm}
                  />
                </div>
                <div className="addliveshotbtnblock">
                  <Button
                    id="createLiveShot"
                    onClick={onAddLiveShot}
                    title="ADD LIVESHOT TO BLOCK"
                    disabled={!isDisable}
                  >
                    <AddIcon alt="Add Liveshot Btn" className="add-plus-icon" />
                  </Button>
                </div>
              </div>
              {blockTakeAndGrab}
            </div>
            <Droppable droppableId={block.blockId.toString()} type="liveshot">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {blockLiveshotIdList &&
                    blockLiveshotIdList.length > 0 &&
                    blockLiveshotIdList.map((liveshotId, index) => {
                      const liveshot = canvasLiveshotList.find(
                        (liveshot) => liveshot.liveshotId === liveshotId
                      );
                      return liveshot ? (
                        <LiveShotContainer
                          isBlockLiveshot={true}
                          key={liveshot.liveshotId}
                          id={liveshot.liveshotId}
                          blockId={block.blockId}
                          type={liveshot.abstractLiveshot.liveshotTypeId}
                          formInfo={liveshot}
                          index={index}
                          newliveshot={false}
                          outofOrderLS={outofOrderLS}
                          currentUserRole={currentUserRole}
                          isNewShotAdded={
                            liveshot.liveshotId === newLiveShotId
                              ? isNewLiveShotsAdded
                              : false
                          }
                          blockPerm={isDisable}
                        />
                      ) : null;
                    })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        )}
      </Draggable>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.liveShotContainer.loading,
    token: state.auth.accessToken,
    currentUser: state.auth.currentUser,
    liveShotSignalSelected: state.signalsInfo.liveShotSignalSelected,
    currentCanvas: state.canvasInfo.currentCanvas,
    routingCapabilityType: state.canvasInfo.routingCapabilityType,
    controlRoomList: state.canvasInfo.controlRoomList,
    isArmRouting: state.canvasInfo.armRouting,
    inBoundCRPath: state.signalsInfo.inBoundCRPath,
    outBoundCRPath: state.signalsInfo.outBoundCRPath,
    ifbCRPath: state.signalsInfo.ifbCRPath,
    plCRPath: state.signalsInfo.plCRPath,
    phoCRPath: state.signalsInfo.phoCRPath,
    shortCut: state.canvasInfo.shortCut,
    lsAddedFromSearch: state.liveShotInfo.lsAddedFromSearch,
    newLiveShotId: state.liveShotInfo.newLiveShotId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateBlock: (updatedBy, block_id, payload, canvasId, token) =>
      dispatch(
        actions.updateBlock(updatedBy, block_id, payload, canvasId, token)
      ),
    onResetLSCreation: () => dispatch(actions.resetLSCreation()),
    onStartLSCreation: (blockId) => dispatch(actions.startLSCreation(blockId)),
    onTakeAllCanvasBlock: (
      token,
      userId,
      userFullName,
      abSignalInfo,
      normalSignalInfo,
      roomId,
      currentRoom
    ) =>
      dispatch(
        actions.takeAllCanvasBlock(
          token,
          userId,
          userFullName,
          abSignalInfo,
          normalSignalInfo,
          roomId,
          currentRoom
        )
      ),
    onGrabAllCanvasBlock: (
      token,
      userId,
      userFullName,
      liveshots,
      liveShotSignalSelected,
      roomId,
      currentCanvas,
      currentRoom,
      outBoundFieldsNormal,
      commsFieldsNormal
    ) =>
      dispatch(
        actions.grabAllCanvasBlock(
          token,
          userId,
          userFullName,
          liveshots,
          liveShotSignalSelected,
          roomId,
          currentCanvas,
          currentRoom,
          outBoundFieldsNormal,
          commsFieldsNormal
        )
      ),
    showCommonPopupModal: (modalDetail) =>
      dispatch(actions.showCommonPopupModal(modalDetail)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BlockContainer);
