import * as actionTypes from "../../store/actions/actionTypes";

const initialState = {
  searchList: [],
  showSearch: false,
  liveshotNamesList: "",
  showLoader: false,
  cityList: [],
  stateList: [],
  countryList: [],
};

const closeSearchModalResult = (state, action) => {
  return Object.assign({}, state, { showSearch: action.showSearch });
};
const openSearchModalResult = (state, action) => {
  return Object.assign({}, state, { showSearch: action.showSearch });
};

const searchLiveshotListSuccess = (state, action) => {
  return Object.assign({}, state, {
    searchList: action.searchList,
  });
};

const locationSuccess = (state, action) => {
  let data = action.locationData;
  return Object.assign({}, state, {
    cityList: data.cityList,
    stateList: data.stateList,
    countryList: data.countryList,
  });
};
const addLocationSuccess = (state, action) => {
  if (action.locationType == "CITY") {
    return Object.assign({}, state, {
      cityList: [...state.cityList, action.location],
    });
  } else if (action.locationType == "STATE") {
    return Object.assign({}, state, {
      stateList: [...state.stateList, action.location],
    });
  }
  return Object.assign({}, state, {
    countryList: [...state.countryList, action.location],
  });
};

const liveshotNamesSuccess = (state, action) => {
  return Object.assign({}, state, {
    liveshotNamesList: action.liveshotNamesList,
  });
};

const showLoader = (state, action) => {
  return Object.assign({}, state, { showLoader: action.showLoader });
};

const closeLoader = (state, action) => {
  return Object.assign({}, state, { showLoader: action.showLoader });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SEARCH_LIVESHOT_SUCCESS:
      return searchLiveshotListSuccess(state, action);
    case actionTypes.CLOSE_SEARCH_MODAL:
      return closeSearchModalResult(state, action);
    case actionTypes.OPEN_SEARCH_MODAL:
      return openSearchModalResult(state, action);
    case actionTypes.LOCATION_SUCCESS:
      return locationSuccess(state, action);
    case actionTypes.ADD_LOCATION_SUCCESS:
      return addLocationSuccess(state, action);
    case actionTypes.LIVE_SHOT_NAMES_SUCCESS:
      return liveshotNamesSuccess(state, action);
    case actionTypes.CLOSE_LOADER:
      return closeLoader(state, action);
    case actionTypes.SHOW_LOADER:
      return showLoader(state, action);
    default:
      return state;
  }
};

export default reducer;
