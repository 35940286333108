import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/index";
import NavigationHeader from "../../../components/navigation/NavigationHeader/NavigationHeader";
import { ReactComponent as ResourceTracker } from "../../../assets/icons/Resource Tracker.svg";
import { AgGridReact } from "ag-grid-react";
import "./ResourceTrackerStatus.css";
import "../../../components/shared/CommonCss/CommonCss.css";
import Loader from "../../../components/shared/UI/Loader/Loader";
import { ReactComponent as InfoIcon } from "../../../assets/icons/info-icon.svg";
import { AUTO_REFRESH_MILLISECOND } from "../../../constants/PageConstants";
import { convertForCurrentTZ } from "../../../utils/date/Date";

const ResourceTrackerStatus = (props) => {
  const {
    currentUserRole,
    currentUser,
    onGetResourceTrackerStatus,
    refreshResourceTrackerInfo,
    resourceTrackerData,
    allSourceDestination,
    onDeleteGrabbedSignal,
    token,
    loading,
  } = props;

  const [localResourceTrackerData, setLocalResourceTrackerData] = useState([]);
  let roleRedirect = null;
  if (currentUserRole && ![1, 4, 5, 6].includes(currentUserRole.roleId)) {
    roleRedirect = <Redirect to="/" />;
  }

  let userId = currentUser.sso;
  if (process.env.REACT_APP_AUTH_ENABLED !== "true") {
    userId = process.env.REACT_APP_AUTH_OVERRIDE_ID;
  }

  useEffect(() => {
    onGetResourceTrackerStatus(token);
  }, [token, onGetResourceTrackerStatus]);

  useEffect(() => {
    setLocalResourceTrackerData(resourceTrackerData);
  }, [resourceTrackerData]);

  useEffect(() => {
    let interval;
    if (Object.keys(allSourceDestination).length > 0) {
      interval = setInterval(
        () => refreshResourceTrackerInfo(token, allSourceDestination),
        AUTO_REFRESH_MILLISECOND
      ); // Refetch resource tracker data for every 30 seconds
    }
    return () => clearInterval(interval);
  }, [allSourceDestination]);

  const [columns] = useState([
    {
      headerName: "START TIME",
      field: "startTime",
      resizable: true,
      sortable: true,
      cellStyle: { border: "none !important" },
      filter: false,
      valueFormatter: (params) => convertForCurrentTZ(params.data.startTime),
    },
    {
      headerName: "END TIME",
      field: "endTime",
      resizable: true,
      sortable: true,
      cellStyle: { border: "none !important" },
      filter: false,
      valueFormatter: (params) => convertForCurrentTZ(params.data.endTime),
    },
    {
      headerName: "HIT TIME",
      field: "hitTime",
      resizable: true,
      sortable: true,
      cellStyle: { border: "none !important" },
      filter: false,
      valueFormatter: (params) => convertForCurrentTZ(params.data.hitTime),
    },
    {
      headerName: "CONTROL TIME",
      field: "controlTime",
      resizable: true,
      sortable: true,
      cellStyle: { border: "none !important" },
      filter: false,
      valueFormatter: (params) => convertForCurrentTZ(params.data.controlTime),
    },
    {
      headerName: "CORE",
      field: "coreName",
      resizable: true,
      sortable: true,
      cellStyle: { border: "none !important", whiteSpace: "pre" },
      suppressHeaderMenuButton: true,
      suppressFloatingFilterButton: true,
    },
    {
      headerName: "SIGNAL NAME",
      field: "namesetName",
      resizable: true,
      sortable: true,
      cellStyle: { border: "none !important", whiteSpace: "pre" },
      suppressHeaderMenuButton: true,
      suppressFloatingFilterButton: true,
    },
    {
      headerName: "USED AS",
      field: "liveshotSignalTypeName",
      resizable: true,
      sortable: true,
      cellStyle: { border: "none !important" },
      suppressHeaderMenuButton: true,
      suppressFloatingFilterButton: true,
    },
    {
      headerName: "ROOM",
      field: "roomCode",
      resizable: true,
      cellStyle: { border: "none !important" },
      sortable: true,
      suppressHeaderMenuButton: true,
      suppressFloatingFilterButton: true,
    },
    {
      headerName: "CONTROL",
      field: "controlTypeName",
      resizable: true,
      sortable: true,
      cellStyle: { border: "none !important" },
      suppressHeaderMenuButton: true,
      suppressFloatingFilterButton: true,
    },
    {
      headerName: "LIVE SHOT NAME",
      field: "liveshotName",
      resizable: true,
      sortable: true,
      cellStyle: { border: "none !important" },
      suppressHeaderMenuButton: true,
      suppressFloatingFilterButton: true,
    },
    {
      headerName: "TM/TPM",
      field: "controlledByFullName",
      resizable: true,
      cellStyle: { border: "none !important" },
      sortable: true,
      suppressHeaderMenuButton: true,
      suppressNavigable: true,
      suppressFloatingFilterButton: true,
    },
    {
      headerName: "PIN",
      field: "pin",
      resizable: true,
      cellStyle: { border: "none !important" },
      sortable: true,
      suppressHeaderMenuButton: true,
      suppressNavigable: true,
      suppressFloatingFilterButton: true,
    },
  ]);

  const DateFormater = (data) => {
    if (typeof data.controlTime === "undefined" || data.controlTime === null)
      return;
    let time = new Date(data.controlTime + "Z");
    return time.toLocaleTimeString([], {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hourCycle: "h23",
    });
  };

  const deleteRowsData = (id) => {
    let result = window.confirm(
      "Are you sure you want to delete this resourceTrackerId ?"
    );
    if (result) {
      onDeleteGrabbedSignal(token, id, userId);
    }
  };

  const [defaultcolDef] = useState({
    flex: 1,
    sortable: true,
    filter: true,
    floatingFilter: true,
  });
  return (
    <div className="rt-container container">
      <Loader loading={loading} />
      {roleRedirect}
      <div className="righticons_wrapper">
        <NavigationHeader
          routerSignalDetailsPerm={true}
          controlTrackerPerm={true}
          venueAddressperm={true}
        />
      </div>
      <div className="resource-mgt-header-inner resource-mgt-header-row">
        <div className="Resource-header-label">
          <ResourceTracker alt="Resource-Tracker" />
          <span>Resource Tracker</span>
          <span className="resource-info-tag">
            <InfoIcon /> This page refreshes in real time.
          </span>
        </div>
      </div>

      <div className="ag-theme-alpine-dark resource_table_container table_container">
        <AgGridReact
          columnDefs={columns}
          suppressColumnVirtualisation={process.env.REACT_TEST_ENV === "true"}
          defaultColDef={defaultcolDef}
          rowData={localResourceTrackerData}
          rowClassRules={{
            "text-gray": function (params) {
              return (
                params.data.controlType === 1 &&
                params.data.liveshotName === "In Maintenance"
              );
            },
            "text-blue": function (params) {
              return (
                (params.data.controlType === 2 &&
                  params.data.liveshotSignal.liveshotSignalTypeId === 1) ||
                params.data.controlTypeName === "Upcoming" ||
                params.data.controlTypeName === "In Use"
              );
            },
            "text-green": function (params) {
              return (
                params.data.controlType === 1 &&
                params.data.liveshotSignal.liveshotSignalTypeId === 2 &&
                params.data.liveshotName !== "In Maintenance"
              );
            },
            "text-orange": function (params) {
              const signalTypes = [3, 4, 5, 6, 7, 8, 9];
              return (
                params.data.controlType === 1 &&
                signalTypes.includes(
                  params.data.liveshotSignal.liveshotSignalTypeId
                ) &&
                params.data.liveshotName !== "In Maintenance"
              );
            },
          }}
        ></AgGridReact>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.accessToken,
    currentUserRole: state.user.currentUserRole,
    currentUser: state.auth.currentUser,
    resourceTrackerData: state.resourceTracker.resourceTrackerInfo,
    allSourceDestination: state.resourceTracker.allSourceDestination,
    loading: state.routerSourceDestination.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetResourceTrackerStatus: (token) =>
      dispatch(actions.getResourceTrackerInfo(token)),
    refreshResourceTrackerInfo: (token, allSourceDestination) =>
      dispatch(actions.refreshResourceTrackerInfo(token, allSourceDestination)),
    onDeleteGrabbedSignal: (token, trackerId, userId) =>
      dispatch(actions.deleteGrabbedSignal(token, trackerId, userId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResourceTrackerStatus);
