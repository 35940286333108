import React, { forwardRef } from "react";
import { Input } from "antd";

const { TextArea } = Input;

export const CustomInput = forwardRef((props, ref) => {
  return (
    <TextArea
      ref={ref}
      autoSize={{ minRows: 1, maxRows: 3 }}
      {...props}
      className={
        props.overridestyle === "true"
          ? props.className
          : "input-field" + " " + props.className
      }
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          e.preventDefault();
        }
      }}
    />
  );
});
