import axios from "../../axios";
import * as actionTypes from "./actionTypes";
import * as actions from "../../store/actions/index";

export const setUserRole = (role) => {
  return {
    type: actionTypes.SET_USER_ROLE,
    userRole: role,
  };
};

export const userRoleList = (roleList) => {
  return {
    type: actionTypes.ROLE_LIST,
    roleList: roleList,
  };
};

export const userList = (userList) => {
  return {
    type: actionTypes.USER_LIST,
    userList: userList,
  };
};

export const setCurrentUserRole = (role) => {
  return {
    type: actionTypes.SET_CURRENT_USER_ROLE,
    currentUserRole: role,
  };
};
export const setCurrentUserFacility = (facilityId) => {
  return {
    type: actionTypes.SET_CURRENT_USER_FACILITY,
    userFacilityId: facilityId,
  };
};

export const setPermission = (role) => {
  return {
    type: actionTypes.SET_USER_PERMISSION,
    permission: role,
  };
};

export const fetchRoleList = (token) => {
  return (dispatch) => {
    axios
      .get("/user-api/role/", {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((response) => {
        dispatch(userRoleList(response.data));
      })
      .catch((err) => {
        console.log("error ", err);
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
      });
  };
};

export const fetchCurrentUserRole = (sso, token) => {
  return (dispatch) => {
    axios
      .get(`/user-api/user/${sso}/`, {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((response) => {
        dispatch(setUserRole(response.data.role));
        dispatch(setCurrentUserRole(response.data.role));
        dispatch(setCurrentUserFacility(response.data.facilityId));
      })
      .catch((err) => {
        console.log("error ", err);
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
      });
  };
};

export const updateUserRole = (token, sso, roleId) => {
  return (dispatch) => {
    axios
      .put(`/user-api/user/${sso}/${roleId}`, "", {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((response) => {
        // dispatch(setCurrentUserRole(response.data.role));
        dispatch(actions.fetchUserList(token));
      })
      .catch((err) => {
        console.log("error ", err);
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
      });
  };
};

export const changeUserRole = (roleId, token) => {
  return (dispatch) => {
    axios
      .get(`/user-api/role/${roleId}`, {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((response) => {
        dispatch(setPermission(response.data.permission));
        dispatch(setCurrentUserRole(response.data));
      })
      .catch((err) => {
        console.log("error ", err);
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
      });
  };
};

export const fetchUserList = (token) => {
  return (dispatch) => {
    axios
      .get("/user-api/user/", {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((response) => {
        dispatch(userList(response.data));
      })
      .catch((err) => {
        console.log("error ", err);
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
      });
  };
};

export const updateUser = (token, userInfo) => {
  return (dispatch) => {
    axios
      .put(`/user-api/user/`, userInfo, {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((response) => {
        dispatch(actions.fetchUserList(token));
      })
      .catch((err) => {
        console.log("error ", err);
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
      });
  };
};

export const deleteUser = (token, sso) => {
  return (dispatch) => {
    axios
      .delete(`/user-api/user/${sso}`, "", {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((response) => {
        dispatch(actions.fetchUserList(token));
      })
      .catch((err) => {
        console.log("error ", err);
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
      });
  };
};

export const fetchHeartBeat = (sso, token, onSessionTimeOut) => {
  return (dispatch) => {
    axios
      .get(`/user-api/user/heartbeat?sso=${sso}`, {
        withCredentials: true,
        headers: {
          Authorization: token !== "" ? `Bearer ${token}` : "",
        },
      })
      .then((response) => {})
      .catch((err) => {
        console.log("error ", err);
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        } else {
          onSessionTimeOut();
        }
      });
  };
};
