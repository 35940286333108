import Home from "../containers/Home/Home";
import { routes } from "./routes";
import SourceDestinationStatus from "../pages/layouts/sourceDestinationStatus/SourceDestinationStatus";
import ResourceTrackerStatus from "../pages/layouts/resourceTrackerStatus/ResourceTrackerStatus";
import VenueAddressStatus from "../pages/layouts/venueAddressStatus/VenueAddressStatus";
import NoRole from "../pages/loginPages/norole/NoRole";
import Login from "../pages/loginPages/login/Login";

export const SOURCE_DESTINATION = {
  path: routes.SOURCE_DESTINATION,
  element: SourceDestinationStatus,
};

export const RESOURCE_TRACKER = {
  path: routes.RESOURCE_TRACKER,
  element: ResourceTrackerStatus,
};

export const VENUE_ADDRESS_BOOK = {
  path: routes.VENUE_ADDRESS_BOOK,
  element: VenueAddressStatus,
};

export const HOME = { path: routes.HOME, element: Home, exact: true };

export const noRoleConfig = [
  {
    path: routes.NO_ROLE,
    element: NoRole,
  },
];

export const loginConfig = [
  {
    path: routes.LOGIN,
    element: Login,
  },
];

export const VALID_ROUTE = [
  SOURCE_DESTINATION,
  RESOURCE_TRACKER,
  VENUE_ADDRESS_BOOK,
  HOME,
];

export const routeConfig = {
  1: VALID_ROUTE,
  2: [HOME],
  3: [HOME],
  4: [RESOURCE_TRACKER, HOME],
  5: [RESOURCE_TRACKER, VENUE_ADDRESS_BOOK, SOURCE_DESTINATION, HOME],
  6: [RESOURCE_TRACKER, HOME],
};
