import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/index";
import { ReactComponent as InBoundPlusIcon } from "../../../assets/icons/inbound-add-outline.svg";
import { ReactComponent as CommsPIcon } from "../../../assets/icons/commsPIcon.svg";
import { ReactComponent as MinusLogo } from "../../../assets/icons/remove-icon-outline.svg";
import { ReactComponent as InfoLiveShotOutlineLogo } from "../../../assets/icons/Info-liveshots-outline.svg";
import DropdownSuggestions from "../../dropdownSuggestions/DropdownSuggestions";
import { DebounceInput } from "react-debounce-input";
import { CustomInput } from "../../shared/UI/Input/CustomInput";
import { getPathClassName, removeDuplicates } from "../../shared/utility";
import { commsTypeArray } from "../../../constants/ColumnHeaderArray";
import { liveshot_canvas_constants } from "../../../constants/PageConstants";
import Tooltip from "../../shared/UI/Tooltip/Tooltip";
import {
  SignalInput,
  NumberInput,
  RadioInput,
  SignalDropdown,
  AutoComplete,
} from "../../shared/UI/index";

const CommsSignals = (props) => {
  const {
    isDisable,
    liveShotId,
    formInfo,
    abstractLiveshotId,
    userId,
    isArmRouting,
    deleteSignal,
    currentRoom,
    token,
    liveshotStatus,
    onAddSignal,
    ifbCRPath,
    plCRPath,
    phoCRPath,
    phoTxCRPath,
    inBoundFields,
    sharedIFBGlobalPath,
    sharedPLGlobalPath,
    sharedPHOGlobalPath,
    sharedPHOTXGlobalPath,
    outBoundFields,
    routerSources,
    onUpdateLiveShotSignals,
    onChangeSignal,
    onUpdateNormalLiveShotSignals,
    showCommonPopupModal,
    onCheckAvailableSignals,
    currentCanvas,
    unOwnLiveshotSignals,
    liveShotSignalSelected,
    onTakeAndGrab,
    userFullName,
    showDuplicateLocalPathModal,
    onFetchPinBySipNumber,
    onSetAccessPin,
    onResetAllPin,
    onChangePinFromSignals,
    onCheckOwnership,
    onRemoveRedBorder,
    setCustomIndexNum,
    stDefaultPinInfo,
    onResetSTDefaultPin,
    onDeleteLiveShotSignal,
    onCreateLiveShotSignalFromStudioTruck,
    resetDefaultCommSignal,
    defaultCommSignals,
    pinObj,
    customIndexNum,
    commsFields,
    setCommsFields,
    confirmDuplicateRoute,
    checkForDuplicateLocalPaths,
    updateInfo,
    hideDropdownOnClickOutside,
    isWindowTimeInvalid,
  } = props;

  const commsTypeMenu = useRef([]);

  const [commsRows, setCommsRows] = useState([]);
  const [commsFieldsNormal, setCommsFieldsNormal] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const [plButton, setPlButton] = useState(true);
  const [type, setStype] = useState(0);
  const [autoAddCommSignal, setAutoAddCommSignal] = useState(true);

  useEffect(() => {
    const commsValues = [...commsFields];
    if (pinObj && pinObj.pinNumber) {
      if (
        (pinObj.pinNumber.accessPin || pinObj.pinNumber.accessPin === "") &&
        customIndexNum !== null
      ) {
        onUpdateLiveShotSignals(
          token,
          commsValues[customIndexNum],
          { pin: pinObj.pinNumber.accessPin },
          currentRoom
        );
      }
    }
  }, [pinObj]);

  useEffect(() => {
    const commsArray = [];
    const comms = removeDuplicates(liveShotSignalSelected).filter(
      (signal) =>
        signal.abstractLiveshotId === abstractLiveshotId &&
        signal.liveshotId === liveShotId &&
        (signal.liveshotSignalTypeId === 5 ||
          signal.liveshotSignalTypeId === 7 ||
          signal.liveshotSignalTypeId === 8 ||
          signal.liveshotSignalTypeId === 9)
    );
    setCommsFields(comms);
    updateCommsRows(comms);
    for (let key in comms) {
      commsArray.push(comms[key].liveshotSignalId);
    }
    setCommsFieldsNormal(
      removeDuplicates(liveShotSignalSelected).filter(
        (signal) =>
          signal.liveshotId === liveShotId &&
          signal.abstractLiveshotId === abstractLiveshotId &&
          commsArray.includes(signal.abstractLiveshotSignalId)
      )
    );
  }, [liveShotSignalSelected, liveShotId, abstractLiveshotId]);

  const updateCommsRows = (comms) => {
    let updatedRows = [...commsRows];
    comms &&
      comms.map((item, index) => {
        updatedRows[index] = {
          ...updatedRows[index],
          isShowCommsTypeMenu: false,
          commsTypePlaceholder: "SELECT",
          pathName: item.pathName,
          commsPin: item.pin || "",
          sip: item.sip || "",
          isOwnSelected: false,
          transmissionPathName: item.transmissionPathName,
          crPathName: item.crPathName || "",
          routed: item.routed,
        };
      });
    setCommsRows(updatedRows);
  };

  useEffect(() => {
    if (
      commsFields.length > 0 &&
      formInfo &&
      formInfo.abstractLiveshot.windowEndTime &&
      !isUpdating
    ) {
      for (let key in commsFields) {
        const signal = commsFields[key];
        if (
          signal.globalPathName &&
          sharedIFBGlobalPath.includes(signal.globalPathName)
        ) {
          onCheckOwnership(token, signal, formInfo, currentCanvas);
        } else {
          onRemoveRedBorder(signal.liveshotSignalId);
        }
      }
    }
  }, [
    token,
    commsFields,
    sharedIFBGlobalPath,
    onCheckOwnership,
    formInfo,
    onRemoveRedBorder,
    currentCanvas,
    isUpdating,
  ]);

  useEffect(() => {
    if (
      commsFields.length > 0 &&
      formInfo &&
      formInfo.abstractLiveshot.windowEndTime &&
      !isUpdating
    ) {
      for (let key in commsFields) {
        const signal = commsFields[key];
        if (
          signal.globalPathName &&
          sharedPLGlobalPath.includes(signal.globalPathName)
        ) {
          onCheckOwnership(token, signal, formInfo, currentCanvas);
        } else {
          onRemoveRedBorder(signal.liveshotSignalId);
        }
      }
    }
  }, [
    token,
    commsFields,
    sharedPLGlobalPath,
    onCheckOwnership,
    formInfo,
    onRemoveRedBorder,
    currentCanvas,
    isUpdating,
  ]);

  useEffect(() => {
    if (
      commsFields.length > 0 &&
      formInfo &&
      formInfo.abstractLiveshot.windowEndTime &&
      !isUpdating
    ) {
      for (let key in commsFields) {
        const signal = commsFields[key];
        if (
          signal.globalPathName &&
          sharedPHOGlobalPath.includes(signal.globalPathName)
        ) {
          onCheckOwnership(token, signal, formInfo, currentCanvas);
        } else {
          onRemoveRedBorder(signal.liveshotSignalId);
        }
      }
    }
  }, [
    token,
    commsFields,
    sharedPHOGlobalPath,
    onCheckOwnership,
    formInfo,
    onRemoveRedBorder,
    currentCanvas,
    isUpdating,
  ]);

  useEffect(() => {
    if (
      commsFields.length > 0 &&
      formInfo &&
      formInfo.abstractLiveshot.windowEndTime &&
      !isUpdating
    ) {
      for (let key in commsFields) {
        const signal = commsFields[key];
        if (
          signal.globalPathName &&
          sharedPHOTXGlobalPath.includes(signal.globalPathName)
        ) {
          onCheckOwnership(token, signal, formInfo, currentCanvas);
        } else {
          onRemoveRedBorder(signal.liveshotSignalId);
        }
      }
    }
  }, [
    token,
    commsFields,
    sharedPHOTXGlobalPath,
    onCheckOwnership,
    formInfo,
    onRemoveRedBorder,
    currentCanvas,
    isUpdating,
  ]);

  useEffect(() => {
    let abSignals = [];
    commsFields.forEach((fields) => {
      let crPathNameNormal = commsFieldsNormal.find(
        (inboundNormal) =>
          inboundNormal.abstractLiveshotSignalId === fields.liveshotSignalId
      );
      if (crPathNameNormal === undefined) {
        abSignals.push(fields);
      }
    });

    if (abSignals.length > 0 && autoAddCommSignal && isDisable) {
      setAutoAddCommSignal(false);
    }
  }, [
    token,
    isDisable,
    commsFieldsNormal,
    commsFields,
    liveShotId,
    userId,
    currentRoom,
  ]);

  useEffect(() => {
    if (commsFieldsNormal) {
      let values = commsFieldsNormal.filter((signal) => {
        return signal.liveshotSignalTypeId === 0 && signal.crPathName;
      });
      if (values.length > 0) {
        setPlButton(false);
      } else {
        setPlButton(true);
      }
    }
  }, [plButton, commsFieldsNormal]);

  useEffect(() => {
    if (stDefaultPinInfo && stDefaultPinInfo.liveShotId === liveShotId) {
      onResetSTDefaultPin();
      if (commsFields.length > 0) {
        for (let key in commsFields) {
          const signal = commsFields[key];
          onDeleteLiveShotSignal(
            token,
            signal.liveshotSignalId,
            null,
            currentRoom
          );
        }
      }
      onCreateLiveShotSignalFromStudioTruck(
        token,
        liveShotId,
        abstractLiveshotId,
        5,
        userId,
        currentRoom,
        stDefaultPinInfo.studioDefaultIfb
          ? stDefaultPinInfo.studioDefaultIfb
          : ""
      );
      onCreateLiveShotSignalFromStudioTruck(
        token,
        liveShotId,
        abstractLiveshotId,
        8,
        userId,
        currentRoom,
        stDefaultPinInfo.studioDefaultPl ? stDefaultPinInfo.studioDefaultPl : ""
      );
      onCreateLiveShotSignalFromStudioTruck(
        token,
        liveShotId,
        abstractLiveshotId,
        9,
        userId,
        currentRoom,
        stDefaultPinInfo.studioDefaultPho
          ? stDefaultPinInfo.studioDefaultPho
          : ""
      );
    }
  }, [
    token,
    userId,
    abstractLiveshotId,
    stDefaultPinInfo,
    liveShotId,
    commsFields,
    onDeleteLiveShotSignal,
    onResetSTDefaultPin,
    onCreateLiveShotSignalFromStudioTruck,
    currentRoom,
  ]);

  const createDefaultCommSignals = useCallback(
    (stDefaultPinInfo) => {
      if (commsFields.length > 0) {
        for (let key in commsFields) {
          const signal = commsFields[key];
          onDeleteLiveShotSignal(
            token,
            signal.liveshotSignalId,
            null,
            currentRoom
          );
        }
      }
      onCreateLiveShotSignalFromStudioTruck(
        token,
        liveShotId,
        abstractLiveshotId,
        5,
        userId,
        currentRoom,
        stDefaultPinInfo.studioDefaultIfb
          ? stDefaultPinInfo.studioDefaultIfb
          : ""
      );
      onCreateLiveShotSignalFromStudioTruck(
        token,
        liveShotId,
        abstractLiveshotId,
        8,
        userId,
        currentRoom,
        stDefaultPinInfo.studioDefaultPl ? stDefaultPinInfo.studioDefaultPl : ""
      );
      onCreateLiveShotSignalFromStudioTruck(
        token,
        liveShotId,
        abstractLiveshotId,
        9,
        userId,
        currentRoom,
        stDefaultPinInfo.studioDefaultPho
          ? stDefaultPinInfo.studioDefaultPho
          : ""
      );
    },
    [
      abstractLiveshotId,
      commsFields,
      currentRoom,
      liveShotId,
      onDeleteLiveShotSignal,
      token,
      userId,
      onCreateLiveShotSignalFromStudioTruck,
    ]
  );

  useEffect(() => {
    if (defaultCommSignals) {
      resetDefaultCommSignal();
      createDefaultCommSignals(defaultCommSignals);
    }
  }, [defaultCommSignals, createDefaultCommSignals, resetDefaultCommSignal]);

  const addCommsRow = (typeValue) => {
    const newRow = {
      commsTypePlaceholder: "SELECT",
      isShowCommsTypeMenu: false,
      pathName: "",
      commsPin: "",
      sip: "",
      localPathName: "",
      isOwnSelected: false,
      routed: false,
      transmissionPathName: typeValue,
    };
    setCommsRows([...commsRows, newRow]);
  };

  const setSignals = (signal, i, signalName) => {
    const commsValues = [...commsFields];

    if (signalName === "sipPathComms") {
      commsValues[i].sipSuggest = "false";
      commsValues[i].sipPathNameSelected = "true";
      commsValues[i].updatedBy = parseInt(userId);
      commsRows[i].sip = signal;
      commsValues[i].sip = signal;
      commsRows[i].routed = false;
      commsValues[i].routed = false;
      setCommsFields(commsValues);
      setCommsFieldsNormal(commsValues);
      onUpdateLiveShotSignals(
        token,
        commsValues[i],
        { sip: commsRows[i].sip, routed: false },
        currentRoom
      );
      setCustomIndexNum(i);
      setCommsRows(commsRows);
      onFetchPinBySipNumber(token, commsRows[i].sip);
    }
    if (signalName === "crPathComms") {
      commsValues[i].crSuggest = "false";
      commsValues[i].crPathNameSelected = "true";
      commsValues[i].updatedBy = parseInt(userId);
      commsRows[i].crPathName = signal;
      commsValues[i].crPathName = signal;
      commsRows[i].routed = false;
      commsValues[i].routed = false;
      commsValues[i].crKey = !!commsValues[i].crKey;
      setCommsFields(commsValues, commsRows[i].crPathName);
      setCommsFieldsNormal(commsValues);
      onUpdateLiveShotSignals(
        token,
        commsValues[i],
        { crPathName: commsRows[i].crPathName, routed: false },
        currentRoom
      );
    }
    if (signalName === "globalPathComms") {
      commsValues[i].updatedBy = parseInt(userId);
      commsValues[i].globalPathName = signal;
      commsValues[i].globalSuggest = "false";
      commsValues[i].crPathNameSelected = "true";
      commsValues[i].roomId = currentRoom.roomId;
      setCommsFields(commsValues);
      onUpdateLiveShotSignals(token, commsValues[i], currentRoom, true);
    }

    onChangeSignal(inBoundFields, outBoundFields, commsFields);
  };

  const changeCommsSignal = (i, event, normalLiveshotSignalId = null) => {
    setIsUpdating(true);
    const normalObj = {};
    const values = [...commsFields];
    const commsValues = [...commsFieldsNormal];
    let updatedRows = [...commsRows];
    if (commsValues.length > 0) {
      commsValues[i][event.target.name] = event.target.value;
    }
    values[i].roomId = currentRoom.roomId;
    values[i].updatedBy = parseInt(userId);

    if (event.target.name === "pin") {
      values[i].pin = event.target.value;
      values[i].pintouched = true;
      if (values[i].globalPathName)
        onSetAccessPin(token, values[i].globalPathName, event.target.value);
    }
    if (event.target.name === "transmissionPathName") {
      onResetAllPin();
      if (event.target.value.includes("IFB")) {
        values[i].liveshotSignalTypeId = 5;
        onChangePinFromSignals("", "IFB");
      }
      if (event.target.value.includes("PL")) {
        setPlButton(true);
        values[i].liveshotSignalTypeId = 8;
        onChangePinFromSignals("", "PL");
      }
      if (event.target.value.includes("PHO")) {
        setPlButton(true);
        values[i].liveshotSignalTypeId = 9;
        onChangePinFromSignals("", "PHO");
      }
      values[i].crPathName = "";
      values[i].globalPathName = "";
      values[i].crPathNameSelected = "false";
      values[i].sip = "";
      values[i].sipPathNameSelected = "false";
    }
    values[i][event.target.name] = event.target.value;
    if (event.target.name === "sip") {
      const validPattern = /^[0-9\b]+$/;
      const validCheckedPattern = /^([0-9\b][0-9\b][0-9\b][0-9\b])+$/;
      if (event.target.value === "" || validPattern.test(event.target.value)) {
        if (event.target.value !== "") {
          values[i].sipSuggest = "true";
          updatedRows[i] = {
            ...updatedRows[i],
            sip: event.target.value,
          };
          if (
            event.target.value.length === 0 ||
            validCheckedPattern.test(event.target.value)
          ) {
            updatedRows[i] = {
              ...updatedRows[i],
              routed: false,
            };
            onUpdateLiveShotSignals(
              token,
              values[i],
              { sip: event.target.value, routed: false },
              currentRoom
            );
            onFetchPinBySipNumber(token, event.target.value);
          }
        } else {
          values[i].sipSuggest = "false";
          updatedRows[i] = {
            ...updatedRows[i],
            isShowVenueMenu: false,
            sip: event.target.value,
            routed: false,
          };
          values[i].updatedBy = parseInt(userId);
          commsRows[i].sip = event.target.value;

          onUpdateLiveShotSignals(
            token,
            values[i],
            { sip: event.target.value.toString(), routed: false },
            currentRoom
          );
        }
        setCommsRows(updatedRows);
        setCustomIndexNum(i);
        setCommsFields(commsValues, commsRows[i].sip);
        values[i].sipPathNameSelected = "false";
      }
    }

    if (event.target.name === "crPathName") {
      if (event.target.value !== "") {
        values[i].crSuggest = "true";
        updatedRows[i] = {
          ...updatedRows[i],
          crPathName: event.target.value,
        };
        values[i].crPathName = event.target.value;
      } else {
        setPlButton(true);
        values[i].crSuggest = "false";
        updatedRows[i] = {
          ...updatedRows[i],
          isShowVenueMenu: false,
          crPathName: event.target.value,
          routed: false,
        };
        values[i].updatedBy = parseInt(userId);
        commsRows[i].crPathName = event.target.value;
        values[i].crPathName = event.target.value;
        onUpdateLiveShotSignals(
          token,
          values[i],
          { crPathName: event.target.value, routed: false },
          currentRoom
        );
      }
      setCommsRows(updatedRows);
      setCommsFields(values);
      values[i].crPathNameSelected = "false";
    }
    if (event.target.name === "sipPathComms") {
      if (event.target.value !== "") {
        values[i].sipSuggest = "true";
      } else {
        values[i].sipSuggest = "false";
        values[i].sipPathNameSelected = "true";
        commsValues[i].updatedBy = parseInt(userId);
        commsRows[i].sip = event.target.value;
        commsValues[i].sip = event.target.value;
        setCommsFields(commsValues, commsRows[i].sip);
        setCommsRows(updatedRows);

        onUpdateLiveShotSignals(
          token,
          commsValues[i],
          { sip: commsRows[i].sip },
          currentRoom
        );
      }
    }

    if (event.target.name === "crPathComms") {
      if (event.target.value !== "") {
        values[i].crSuggest = "true";
        updatedRows[i] = {
          ...updatedRows[i],
          crPathName: event.target.value,
        };
      } else {
        setPlButton(true);
        values[i].crSuggest = "false";
        values[i].crPathNameSelected = "true";
        commsValues[i].updatedBy = parseInt(userId);
        commsRows[i].crPathName = event.target.value;
        commsRows[i].routed = false;
        commsValues[i].crPathName = event.target.value;
        normalObj.liveshotSignalId = normalLiveshotSignalId;
        normalObj.roomId = currentRoom.roomId;
        normalObj.updatedBy = parseInt(userId);
        normalObj.crPathName = "";
        onUpdateLiveShotSignals(
          token,
          commsValues[i],
          { crPathName: commsRows[i].crPathName, routed: false },
          currentRoom
        );
      }
      setCommsFields(commsValues);
      setCommsRows(updatedRows);
    }

    if (event.target.name === "availcheck") {
      if (event.target.value === "false") {
        values[i].availCheck = "true";
        if (values[i].liveshotSignalTypeId === 5) {
          onCheckAvailableSignals(
            token,
            sharedIFBGlobalPath,
            formInfo,
            currentCanvas,
            "IFB"
          );
        }
        if (values[i].liveshotSignalTypeId === 8) {
          onCheckAvailableSignals(
            token,
            sharedPLGlobalPath,
            formInfo,
            currentCanvas,
            "PL"
          );
        }
        if (values[i].liveshotSignalTypeId === 9) {
          onCheckAvailableSignals(
            token,
            sharedPHOGlobalPath,
            formInfo,
            currentCanvas,
            "PHO"
          );
        }
      } else {
        values[i].availCheck = "false";
      }
    }

    setCommsFields(values);
    if (
      event.target.name === "pathName" ||
      event.target.name === "transmissionPathName"
    ) {
      onUpdateLiveShotSignals(token, values[i], currentRoom);
      if (event.target.name === "transmissionPathName") {
        commsValues[i]["crPathName"] = "";
        normalObj.liveshotSignalId = normalLiveshotSignalId;
        normalObj.liveshotSignalTypeId = values[i].liveshotSignalTypeId;
        normalObj.roomId = currentRoom.roomId;
        normalObj.updatedBy = parseInt(userId);
        onUpdateNormalLiveShotSignals(token, normalObj, currentRoom);
      }
    }
    onChangeSignal(inBoundFields, outBoundFields, commsFields);
  };

  const removeCommsRow = (idx) => {
    let updatedRows = [...commsRows];
    let commsFieldsRows = [...commsFields];

    updatedRows.splice(idx, 1);
    commsFieldsRows.splice(idx, 1);

    setCommsFields(commsFieldsRows);
    setCommsRows(updatedRows);
  };

  const hideCommsTypes = (idx) => {
    let updatedRows = [...commsRows];
    let commsTypePlaceholder = "SELECT";

    let isShowCommsTypeMenuValue = updatedRows[idx].isShowCommsTypeMenu;
    updatedRows[idx] = {
      ...updatedRows[idx],
      isShowCommsTypeMenu: !isShowCommsTypeMenuValue,
      commsTypePlaceholder: commsTypePlaceholder,
    };
    setCommsRows(updatedRows);
  };

  const onChangeType = (e) => {
    setStype(e.target.value);
  };

  const onChangeCommsLabel = (e, idx) => {
    let values = [...commsFields];
    values[idx].updatedBy = parseInt(userId);
    values[idx].pathName = e.target.value;
    let updatedRows = [...commsRows];
    updatedRows[idx] = {
      ...updatedRows[idx],
      pathName: e.target.value,
    };
    setCommsRows(updatedRows);
    onUpdateLiveShotSignals(
      token,
      values[idx],
      { pathName: e.target.value },
      currentRoom,
      false,
      true
    );
    setCommsFields(values);
  };

  const onChangeCommsType = (e, idx) => {
    const signalType = {
      PHO: 9,
      PL: 8,
      IFB: 5,
    };
    let commstypeValue = e.target.value;

    let values = [...commsFields];
    values[idx].transmissionPathName = commstypeValue;
    values[idx].liveshotSignalTypeId = signalType[commstypeValue];
    values[idx].updatedBy = parseInt(userId);
    values[idx].crPathName = "";

    let updatedRows = [...commsRows];
    updatedRows[idx] = {
      ...updatedRows[idx],
      isShowCommsTypeMenu: false,
      commsTypePlaceholder: "",
      transmissionPathName: commstypeValue,
    };
    setCommsRows(updatedRows);

    onUpdateLiveShotSignals(
      token,
      values[idx],
      {
        transmissionPathName: commstypeValue,
        liveshotSignalTypeId: signalType[commstypeValue],
        crPathName: "",
      },
      currentRoom
    );
    setCommsFields(values);
  };

  const onChangeCommsPin = (e, idx) => {
    const validPattern = /^[0-9\b]+$/;
    const validCheckedPattern = /^([0-9\b][0-9\b][0-9\b][0-9\b])+$/;

    if (e.target.value === "" || validPattern.test(e.target.value)) {
      let values = [...commsFields];
      values[idx].updatedBy = parseInt(userId);
      let updatedRows = [...commsRows];
      updatedRows[idx] = {
        ...updatedRows[idx],
        commsPin: e.target.value,
      };
      setCommsRows(updatedRows);
      if (
        e.target.value.length === 0 ||
        validCheckedPattern.test(e.target.value)
      ) {
        onUpdateLiveShotSignals(
          token,
          values[idx],
          { pin: e.target.value },
          currentRoom
        );
      }
      setCommsFields(values);
    }
  };

  const getSignalList = (signalIds = [2, 5, 7, 8, 9]) => {
    let signalList = [];
    signalList = removeDuplicates(liveShotSignalSelected).filter((signal) =>
      signalIds.includes(signal.liveshotSignalTypeId)
    );
    return signalList;
  };

  const handleOwnCommsRadioClick = (idx, field, crPath) => {
    let updatedRows = [...commsRows];
    let isRouted = updatedRows[idx].routed;

    if (isRouted) {
      unOwnLiveshotSignals(
        token,
        field.liveshotSignalId,
        field,
        "crPathName",
        currentRoom
      );
    } else {
      if (isWindowTimeInvalid()) {
        return;
      }

      if (updatedRows.length > 0) {
        let isDuplicate = checkForDuplicateLocalPaths(
          field,
          getSignalList([5, 7, 8, 9]),
          "crPathName"
        );
        setCommsRows(updatedRows);
        if (!isDuplicate) {
          field.signalType = "comms";
          onTakeAndGrab(
            token,
            "grab",
            userId,
            userFullName,
            field,
            crPath,
            liveShotId,
            currentCanvas.roomId,
            currentRoom
          );
        } else {
          field.isDuplicate = true;
          field.confirmDuplicateRoute = confirmDuplicateRoute;
          field.crPath = crPath;
          field.btnType = "grabComms";
          showDuplicateLocalPathModal(field);
        }
      }
    }
  };

  const tableHeaders = [
    {
      className: "empty-header",
    },
    { className: "commsTypeHeaderCell", value: "Type" },
    { className: "commslabelHeaderCell", value: "Label" },
    { className: "commsSipHeaderCell", value: "SIP" },
    { className: "commsPinHeaderCell", value: "PIN" },
    { className: "commsLocalpathnameHeaderCell", value: "Local" },
    { className: "commsInfoCol" },
    { className: "commsOwnHeaderCell", value: "Use" },
    { className: "commsUseHeaderCell", value: "Own" },
  ];

  return (
    <div className="mtcdetails">
      <div className="phones">
        <div className="signals-heading">
          {!isDisable && liveShotId !== 0 && (
            <div
              type="button"
              btnType="circle_btn"
              title="Add new Liveshot Signal"
              onClick={() => {
                onAddSignal(
                  token,
                  liveShotId,
                  abstractLiveshotId,
                  5,
                  userId,
                  currentRoom,
                  "addComms"
                );
                addCommsRow("IFB");
              }}
            >
              <InBoundPlusIcon className="inboundPlusIcon" alt="Plus Logo" />
            </div>
          )}
          <div className="pl-button">
            {!isDisable && liveShotId !== 0 && (
              <div
                type="button"
                btnType="circle_btn"
                title="Create PL based on IFB"
                onClick={() => {
                  onAddSignal(
                    token,
                    liveShotId,
                    abstractLiveshotId,
                    8,
                    userId,
                    currentRoom,
                    ""
                  );
                  addCommsRow("PL");
                }}
              >
                <CommsPIcon className="inboundPlusIcon" alt="Phone Logo" />
              </div>
            )}
          </div>
          <div className="pl-button"></div>
          <label>COMMS</label>
        </div>
        <div className="commsTableContainer">
          <table className="table table-borderless commsTable">
            <thead>
              <tr>
                {tableHeaders.map((header) => {
                  return (
                    <th
                      scope="col"
                      key={header.className}
                      className={header.className}
                    >
                      {header.value}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {commsFields.map((field, idx) => {
                let crPathListComms = null;
                let sipPathListComms = null;
                let crPath = commsFieldsNormal.find(
                  (commsNormal) =>
                    commsNormal.abstractLiveshotSignalId ===
                    field.liveshotSignalId
                );

                if (field && field.liveshotSignalTypeId === 5) {
                  if (field.crSuggest === "true") {
                    const filteredCRComms = ifbCRPath.filter((crPath) => {
                      if (field.crPathName !== null) {
                        return crPath
                          .toLowerCase()
                          .includes(field.crPathName.toLowerCase());
                      } else {
                        return null;
                      }
                    });
                    if (filteredCRComms.length > 0) {
                      crPathListComms = filteredCRComms;
                    }
                  }

                  if (field.sipSuggest === "true") {
                    let routerSourcesObj = [];
                    routerSources.forEach((rs) => {
                      if (rs.namesetName.includes("SIP")) {
                        if (
                          !routerSourcesObj.includes(
                            rs.namesetName.replace("SIP ", "")
                          )
                        ) {
                          routerSourcesObj.push(
                            rs.namesetName.replace("SIP ", "")
                          );
                        }
                      }
                    });
                    const filteredSipComms = routerSourcesObj.filter(
                      (sourcesSip) => {
                        return sourcesSip.includes(field.sip);
                      }
                    );
                    if (filteredSipComms.length > 0) {
                      sipPathListComms = filteredSipComms;
                    }
                  }
                }

                if (field && field.liveshotSignalTypeId === 7) {
                  if (field.crSuggest === "true") {
                    const filteredCRComms = phoTxCRPath.filter((crPath) => {
                      return crPath
                        .toLowerCase()
                        .includes(field.crPathName.toLowerCase());
                    });
                    if (filteredCRComms.length > 0) {
                      crPathListComms = (
                        <DropdownSuggestions
                          options={filteredCRComms}
                          onSelect={(option) => {
                            setSignals(option, idx, "crPathComms");
                          }}
                        />
                      );
                    }
                  }

                  if (field.sipSuggest === "true") {
                    let routerSourcesObj = [];
                    routerSources.forEach((rs) => {
                      if (rs.namesetName.includes("SIP")) {
                        if (
                          !routerSourcesObj.includes(
                            rs.namesetName.replace("SIP ", "")
                          )
                        ) {
                          routerSourcesObj.push(
                            rs.namesetName.replace("SIP ", "")
                          );
                        }
                      }
                    });
                    const filteredSipComms = routerSourcesObj.filter(
                      (sourcesSip) => {
                        return sourcesSip.includes(field.sip);
                      }
                    );
                    if (filteredSipComms.length > 0) {
                      sipPathListComms = filteredSipComms;
                    }
                  }
                }

                if (field && field.liveshotSignalTypeId === 8) {
                  if (field.crSuggest === "true") {
                    const filteredCRComms = plCRPath.filter((crPath) => {
                      return crPath
                        .toLowerCase()
                        .includes(field.crPathName.toLowerCase());
                    });

                    if (filteredCRComms.length > 0) {
                      crPathListComms = filteredCRComms;
                    }
                  }

                  if (field.sipSuggest === "true") {
                    let routerSourcesObj = [];
                    routerSources.forEach((rs) => {
                      if (rs.namesetName.includes("SIP")) {
                        if (
                          !routerSourcesObj.includes(
                            rs.namesetName.replace("SIP ", "")
                          )
                        ) {
                          routerSourcesObj.push(
                            rs.namesetName.replace("SIP ", "")
                          );
                        }
                      }
                    });
                    const filteredSipComms = routerSourcesObj.filter(
                      (sourcesSip) => {
                        return sourcesSip.includes(field.sip);
                      }
                    );
                    if (filteredSipComms.length > 0) {
                      sipPathListComms = filteredSipComms;
                    }
                  }
                }
                if (field && field.liveshotSignalTypeId === 9) {
                  if (field.crSuggest === "true") {
                    const filteredCRComms = phoCRPath.filter((crPath) => {
                      return crPath
                        .toLowerCase()
                        .includes(field.crPathName.toLowerCase());
                    });
                    if (filteredCRComms.length > 0) {
                      crPathListComms = filteredCRComms;
                    }
                  }
                  if (field.sipSuggest === "true") {
                    let routerSourcesObj = [];
                    routerSources.forEach((rs) => {
                      if (rs.namesetName.includes("SIP")) {
                        if (
                          !routerSourcesObj.includes(
                            rs.namesetName.replace("SIP ", "")
                          )
                        ) {
                          routerSourcesObj.push(
                            rs.namesetName.replace("SIP ", "")
                          );
                        }
                      }
                    });
                    const filteredSipComms = routerSourcesObj.filter(
                      (sourcesSip) => {
                        return sourcesSip.includes(field.sip);
                      }
                    );
                    if (filteredSipComms.length > 0) {
                      sipPathListComms = filteredSipComms;
                    }
                  }
                }

                let localPath = field.crPathName;
                let localPathNameError = field.crPathNameError;
                let isCommsOwnDisabled = !(
                  isArmRouting &&
                  (liveshotStatus === "READY FOR AIR" ||
                    liveshotStatus === "READY FOR PCR") &&
                  localPath &&
                  localPath !== null &&
                  !localPathNameError
                );
                let isOwnSelected =
                  commsRows && commsRows[idx] && commsRows[idx].routed;

                return (
                  <tr
                    key={`${field}-${idx}`}
                    className={field.liveshotSignalId}
                  >
                    <td>
                      {" "}
                      {!isDisable && (
                        <div
                          type="button"
                          btnType="circle_btn removeCommSignal"
                          title="Remove Liveshot Signal"
                          onClick={() => {
                            if (isOwnSelected) {
                              showCommonPopupModal({
                                showModal: true,
                                title: "Warning",
                                content:
                                  "Please un-own the signal before deleting it.",
                                type: "warning",
                              });
                            } else {
                              deleteSignal(
                                field.liveshotSignalId,
                                crPath ? crPath.liveshotSignalId : null
                              );
                              removeCommsRow(idx);
                            }
                          }}
                        >
                          <MinusLogo alt="Minus Logo" className="minusIcon" />
                        </div>
                      )}
                    </td>
                    <td className="commsTypeCell">
                      <SignalDropdown
                        onChange={(e) => {
                          let event = {
                            target: { name: "venueType", value: e.label },
                          };
                          onChangeCommsType(event, idx);
                          changeCommsSignal(idx, event);
                        }}
                        options={commsTypeArray.map((item) => ({
                          label: item,
                          value: item,
                          key: item,
                        }))}
                        disabled={isDisable}
                        placeholder={
                          commsRows &&
                          commsRows[idx] &&
                          commsRows[idx].commsTypePlaceholder
                        }
                        value={field.transmissionPathName || ""}
                      />
                    </td>
                    <td className="labelCell">
                      <SignalInput
                        name="pathName"
                        value={commsRows[idx] ? commsRows[idx].pathName : ""}
                        onChange={(e) => onChangeCommsLabel(e, idx)}
                        disabled={isDisable}
                      />
                    </td>
                    <td className="labelCell">
                      <AutoComplete
                        placeholder="ENTER"
                        name="sip"
                        custominput="number"
                        key={`sip`}
                        autoComplete="off"
                        className={`inboundOutboundInputFields globalpathField ${
                          !isDisable ? "edit-input" : "disable-input"
                        }`}
                        patherr={getPathClassName(field.crPathNameError)}
                        value={commsRows[idx].sip || ""}
                        onSearch={(val) => {
                          changeCommsSignal(idx, {
                            target: { name: "sip", value: val },
                          });
                        }}
                        onSelect={(e) => setSignals(e, idx, "sipPathComms")}
                        options={sipPathListComms}
                        disabled={isDisable}
                      />
                    </td>
                    <td className="labelCell">
                      <NumberInput
                        maxLength={liveshot_canvas_constants.MAX_LENGTH}
                        name="commsPin"
                        value={commsRows[idx] ? commsRows[idx].commsPin : ""}
                        onChange={(e) => onChangeCommsPin(e, idx)}
                        disabled={isDisable}
                      />
                    </td>
                    <td className="labelCell">
                      <AutoComplete
                        placeholder="ENTER"
                        name="crPathName"
                        key={`crPathName${field.crKey}`}
                        autoComplete="off"
                        className={`inboundOutboundInputFields globalpathField ${
                          !isDisable ? "edit-input" : "disable-input"
                        }`}
                        patherr={getPathClassName(field.crPathNameError)}
                        value={commsRows[idx].crPathName || ""}
                        onSearch={(val) => {
                          changeCommsSignal(
                            idx,
                            {
                              target: { name: "crPathName", value: val },
                            },
                            crPath ? crPath.liveshotSignalId : ""
                          );
                        }}
                        onSelect={(e) => setSignals(e, idx, "crPathComms")}
                        options={crPathListComms}
                        disabled={isDisable}
                      />
                    </td>
                    <td className="inbound-info-cell">
                      <Tooltip
                        message={updateInfo(field)}
                        position={"bottom"}
                        title="Update Info"
                        messageTheme="light-theme"
                        titleTheme="light-theme"
                      >
                        <InfoLiveShotOutlineLogo
                          className="update-info-icon"
                          alt="info"
                        />
                      </Tooltip>
                    </td>
                    <td className="use-radio-input-cell-outbound">
                      <span className="useRadioOutbound">
                        <span className={`useRadio`}>
                          <div
                            className="useOutboundRadioDiv disableField"
                            aria-hidden="true"
                          ></div>
                        </span>
                      </span>
                    </td>
                    <td className="own-radio-input-cell">
                      <RadioInput
                        onClick={() => {
                          handleOwnCommsRadioClick(idx, field, crPath);
                        }}
                        disabled={isCommsOwnDisabled || isDisable}
                        checked={isOwnSelected}
                        isDisabled={isDisable}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.accessToken,
    currentCanvas: state.canvasInfo.currentCanvas,
    commCapabilityType: state.canvasInfo.commCapabilityType,
    liveShotSignalSelected: state.signalsInfo.liveShotSignalSelected,
    ifbCRPath: state.signalsInfo.ifbCRPath,
    ifbGlobalPath: state.signalsInfo.ifbGlobalPath,
    plGlobalPath: state.signalsInfo.plGlobalPath,
    plCRPath: state.signalsInfo.plCRPath,
    phoGlobalPath: state.signalsInfo.phoGlobalPath,
    phoCRPath: state.signalsInfo.phoCRPath,
    phoTxCRPath: state.signalsInfo.phoTxCRPath,
    phoTxGlobalPath: state.signalsInfo.phoTxGlobalPath,
    grabbedSignals: state.signalsInfo.grabbedSignals,
    userList: state.user.userList,
    isArmRouting: state.canvasInfo.armRouting,
    sharedIFBGlobalPath: state.signalsInfo.sharedIFBGlobalPath,
    sharedPLGlobalPath: state.signalsInfo.sharedPLGlobalPath,
    sharedPHOGlobalPath: state.signalsInfo.sharedPHOGlobalPath,
    sharedPHOTXGlobalPath: state.signalsInfo.sharedPHOTXGlobalPath,
    availIFBSharedSignals: state.signalsInfo.availIFBSharedSignals,
    availPLSharedSignals: state.signalsInfo.availPLSharedSignals,
    availPHOSharedSignals: state.signalsInfo.availPHOSharedSignals,
    stDefaultPinInfo: state.studioTruckInfo.stDefaultPinInfo,
    routerSources: state.routerSourceDestination.routerSources,
    pinObj: state.signalsInfo.pinNumber,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetAccessPin: (token, path, pin) =>
      dispatch(actions.setAccessPin(token, path, pin)),
    onCreateLiveShotSignalFromStudioTruck: (
      token,
      lsId,
      ablsId,
      lsSignalTypeId,
      userId,
      room,
      globalPathName
    ) =>
      dispatch(
        actions.createLiveShotSignalFromStudioTruck(
          token,
          lsId,
          ablsId,
          lsSignalTypeId,
          userId,
          room,
          globalPathName
        )
      ),
    onUpdateLiveShotSignals: (
      token,
      signalInfo,
      signalInfoObj,
      room,
      updateCommGlobal = false,
      needUpdate = false
    ) =>
      dispatch(
        actions.updateLiveShotSignals(
          token,
          signalInfo,
          signalInfoObj,
          room,
          updateCommGlobal,
          needUpdate
        )
      ),
    onUpdateNormalLiveShotSignals: (token, signalInfo, room) =>
      dispatch(actions.updateNormalLiveShotSignals(token, signalInfo, room)),
    onDeleteLiveShotSignal: (token, lsSignalId, normalLsSignalId, room) =>
      dispatch(
        actions.deleteLiveShotSignal(token, lsSignalId, normalLsSignalId, room)
      ),
    onTakeAndGrab: (
      token,
      control,
      userId,
      userFullName,
      abstractSignalInfo,
      normalSignalInfo,
      liveshotId,
      roomId,
      currentRoom,
      globalPathRoomId
    ) =>
      dispatch(
        actions.takeAndGrab(
          token,
          control,
          userId,
          userFullName,
          abstractSignalInfo,
          normalSignalInfo,
          liveshotId,
          roomId,
          currentRoom,
          globalPathRoomId
        )
      ),
    onResetSTDefaultPin: () => dispatch(actions.resetSTDefaultPin()),
    onRemoveRedBorder: (signalId) =>
      dispatch(actions.removeRedBorder(signalId)),
    onCheckOwnership: (token, signal, formInfo, currentCanvas) =>
      dispatch(actions.checkOwnership(token, signal, formInfo, currentCanvas)),
    onCheckAvailableSignals: (token, signals, formInfo, currentCanvas, type) =>
      dispatch(
        actions.checkAvailableSignals(
          token,
          signals,
          formInfo,
          currentCanvas,
          type
        )
      ),
    onFetchPinBySipNumber: (token, sip) =>
      dispatch(actions.fetchPinBySipNumber(token, sip)),
    showDuplicateLocalPathModal: (res) =>
      dispatch(actions.showSignalModal(res)),
    unOwnLiveshotSignals: (
      token,
      liveshotSignalId,
      field,
      pathKey,
      currentRoom,
      isDuplicate = false,
      grabParams = {}
    ) =>
      dispatch(
        actions.unOwnRoute(
          token,
          liveshotSignalId,
          field,
          pathKey,
          currentRoom,
          isDuplicate,
          grabParams
        )
      ),
    showCommonPopupModal: (modalDetail) =>
      dispatch(actions.showCommonPopupModal(modalDetail)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CommsSignals);
