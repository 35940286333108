import * as actionTypes from "../../store/actions/actionTypes";

const defaultModalDetail = {
  showModal: false,
  onModalClose: () => {},
  onConfirm: () => {},
  title: "",
  content: "",
  type: "",
};

const initialState = {
  showNotification: false,
  commonPopupModalDetails: defaultModalDetail,
  notificationBarDetails: {
    show: false,
    onClose: () => {},
    content: "",
    isError: false,
  },
};

const closeNotificationModalResult = (state, action) => {
  return Object.assign({}, state, {
    showNotification: action.showNotification,
  });
};
const openNotificationModalResult = (state, action) => {
  return Object.assign({}, state, {
    showNotification: action.showNotification,
  });
};

const showNotificationBar = (state, action) => {
  return Object.assign({}, state, {
    notificationBarDetails: action.notificationBarDetails,
  });
};
const showCommonPopupModal = (state, action) => {
  let modalDetails = action.commonPopupModalDetails.showModal
    ? action.commonPopupModalDetails
    : defaultModalDetail;
  return Object.assign({}, state, {
    commonPopupModalDetails: modalDetails,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CLOSE_NOTIFICATION_MODAL:
      return closeNotificationModalResult(state, action);
    case actionTypes.OPEN_NOTIFICATION_MODAL:
      return openNotificationModalResult(state, action);
    case actionTypes.SHOW_NOTIFICATION_BAR:
      return showNotificationBar(state, action);
    case actionTypes.SHOW_COMMON_POPUP_MODAL:
      return showCommonPopupModal(state, action);
    default:
      return state;
  }
};

export default reducer;
