import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Notify } from "react-redux-notify";
import "react-redux-notify/dist/ReactReduxNotify.css";
import { createNotification } from "react-redux-notify";
import Blank from "../../components/blank/Blank.js";
import BlankShow from "../../components/blank/BlankShow";
import CanvasHeader from "../../components/canvasHeader/CanvasHeader.js";
import CanvasContainer from "../CanvasContainer/CanvasContainer.js";
import Spinner from "../../components/shared/UI/Spinner/Spinner";
import PopupModal from "../../components/shared/UI/common/PopupModal.js";
import SignalModal from "../../components/modals/signalModal/SignalModal";
import * as actions from "../../store/actions/index";
import NotificationModal from "../../components/modals/notifiactionModal/NotificationModal.js";
import NotificationBarComponent from "../../components/shared/UI/NotificationBarComponent/NotificationBarComponent.js";
import { flashNotification } from "../../components/TipsAndTricks.js";

const Home = (props) => {
  const {
    currentCanvasId,
    normalLiveShots,
    blankCanvasShow,
    loading,
    isAuthenticated,
    onFetchCurrentUserRole,
    onFetchAllLiveshotPreference,
    token,
    currentUser,
    currentUserRole,
    notifications,
    showSignalModal,
    onHideSignalModal,
    signalMsg,
    showCrPathMsg,
    nonGrabNotifications,
    currentShow,
    onCheckCanvas,
    showDate,
    userList,
    liveshotsInfo,
    onFetchStudioTruckList,
    onFetchHeartBeat,
    onFetchRoleList,
    onSetActiveLiveshot,
    shortCut,
    streamDeckOn,
    onStoreArrPosition,
    authRemove,
    commonPopupModalDetails,
    notificationBarDetails,
    onFetchStudioTruckTypes,
  } = props;

  const [isAlert, setIsAlert] = useState(true);
  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const [userName, setUserName] = useState();
  const [notificationModalType, setNotificationModalType] = useState("refresh");
  const [notificationMsg, setNotificationMsg] = useState(null);
  const [commonNotificationDetails, setCommonNotificationDetails] = useState(
    {}
  );

  let userFullName = currentUser.firstName + " " + currentUser.lastName;
  let userId = currentUser.sso;
  if (process.env.REACT_APP_AUTH_ENABLED !== "true") {
    userFullName =
      process.env.REACT_APP_AUTH_OVERRIDE_FIRSTNAME +
      " " +
      process.env.REACT_APP_AUTH_OVERRIDE_LASTNAME;
    userId = process.env.REACT_APP_AUTH_OVERRIDE_ID;
  }

  const [popupModalDetails, setPopupModalDetails] = useState({
    showModal: false,
    onModalClose: () => {},
    onConfirm: () => {},
    title: "",
    content: "",
    type: "",
  });

  const onSessionTimeOut = () => {
    authRemove();
    setNotificationModalType("sessionTimeout");
    setPopupModalDetails({ showModal: false });
    onHideSignalModal();
    setShowNotificationModal(true);
    clearInterval(heartBeatInterval);
  };

  const onRefresh = () => {
    setShowNotificationModal(false);
    let showId = currentShow !== "" ? currentShow.showId : null;
    onCheckCanvas(token, showDate, showId);
  };

  const reloadSession = () => {
    setShowNotificationModal(false);
    authRemove();
  };

  const handleWebSocketNotifiation = (notification) => {
    if (notification) {
      let updatedBy =
        notification && notification.data && notification.data.updatedBy;

      if (
        notification.type === "GRABREQUEST" ||
        notification.type === "GRABRELEASE"
      ) {
        if (
          notification.type === "GRABREQUEST" &&
          parseInt(notification.data.controlledBy) === parseInt(userId)
        ) {
          return;
        }

        notification.addedOn = new Date().toISOString();
        setNotificationModalType(
          notification.type === "GRABREQUEST" ? "grab" : "release"
        );
        setNotificationMsg(notification);
        setShowNotificationModal(true);
      } else if (liveshotsInfo && liveshotsInfo !== undefined && updatedBy) {
        if (
          userId &&
          parseInt(userId) !== parseInt(updatedBy) &&
          notification.data.canvasId === currentCanvasId
        ) {
          const userName = userList.find(
            (element) => element.sso === parseInt(updatedBy)
          );
          notification.addedOn = new Date().toISOString();
          setUserName(userName);
          setNotificationModalType("refresh");
          setNotificationMsg(notification);
          setShowNotificationModal(true);
        }
      }
    }
  };

  let alrt = localStorage.getItem("isAlert");
  useEffect(() => {
    setIsAlert(alrt !== null && (alrt === "true" || alrt));
  }, [alrt]);

  useEffect(() => {
    onFetchAllLiveshotPreference(token);
  }, [token, onFetchAllLiveshotPreference]);

  useEffect(() => {
    if (!shortCut && !streamDeckOn) {
      onStoreArrPosition(0);
    }
  }, [shortCut, streamDeckOn, onSetActiveLiveshot, onStoreArrPosition]);

  useEffect(() => {
    if (process.env.REACT_APP_AUTH_ENABLED !== "true") {
      onFetchCurrentUserRole(userId, token);
    }
    if (isAuthenticated) {
      onFetchCurrentUserRole(userId, token);
    }
    const tipsAndTicks = window.$TIPS_AND_TRICKS;
    if (tipsAndTicks !== undefined && tipsAndTicks.length > 0) {
      createNotification(flashNotification);
    }
  }, [
    isAuthenticated,
    props.history,
    onFetchCurrentUserRole,
    currentUser,
    token,
    userId,
    createNotification,
  ]);

  useEffect(() => {
    onFetchStudioTruckList(token);
  }, [onFetchStudioTruckList, token]);

  useEffect(() => {
    if (currentUserRole.roleName === "View_Only_Alt") {
      onFetchRoleList(token);
    }
  }, [onFetchRoleList, token, currentUserRole]);

  useEffect(() => {
    handleWebSocketNotifiation(notifications);
  }, [notifications]);

  useEffect(() => {
    handleWebSocketNotifiation(nonGrabNotifications);
  }, [nonGrabNotifications]);

  useEffect(() => {
    if (!notificationBarDetails.onClose) {
      notificationBarDetails.onClose = () => {
        setCommonNotificationDetails({ show: false });
      };
    }
    setCommonNotificationDetails(notificationBarDetails);
  }, [notificationBarDetails]);

  useEffect(() => {
    if (!commonPopupModalDetails.onModalClose) {
      commonPopupModalDetails.onModalClose = () => {
        setPopupModalDetails({ showModal: false });
      };
    }
    setPopupModalDetails(commonPopupModalDetails);
  }, [commonPopupModalDetails]);

  useEffect(() => {
    setPopupModalDetails({ showModal: false });
  }, []);

  let heartBeatInterval;
  useEffect(() => {
    let seconds = process.env.REACT_APP_HEARTBEAT_PERIOD
      ? process.env.REACT_APP_HEARTBEAT_PERIOD
      : 120000;
    if (
      currentUserRole &&
      currentUserRole.roleId !== 0 &&
      currentUserRole.roleId !== undefined
    ) {
      heartBeatInterval = setInterval(() => {
        onFetchHeartBeat(userId, token, onSessionTimeOut);
      }, seconds);
    }
    setCommonNotificationDetails({ show: false });
    return () => {
      clearInterval(heartBeatInterval);
    };
  }, []);

  useEffect(() => {
    let seconds = process.env.REACT_APP_SESSION_TIMEOUT
      ? process.env.REACT_APP_SESSION_TIMEOUT
      : 28800;
    let lastActive = Date.now();

    const resetTimer = () => {
      lastActive = Date.now();
    };

    window.onload = resetTimer;
    document.onmousemove = resetTimer;
    document.onkeydown = resetTimer;

    let lastInactivetimeout = setInterval(function () {
      if (Date.now() - lastActive > seconds * 1000) {
        console.log(
          "session timed out after " +
            Math.floor((Date.now() - lastActive) / 1000) +
            " seconds"
        );
        onSessionTimeOut();
        clearInterval(lastInactivetimeout);
      }
    }, 1000);

    return () => {
      clearInterval(lastInactivetimeout);
    };
  }, []);

  useEffect(() => {
    onFetchStudioTruckTypes(token);
  }, [onFetchStudioTruckTypes, token]);

  const liveshotIsPresent =
    normalLiveShots.length > 0 &&
    normalLiveShots.filter(
      (normalLiveShot) => normalLiveShot.liveshots.length > 0
    ).length > 0;

  let canvas = <Blank />;
  if (loading) {
    canvas = <Spinner />;
  } else if (!currentShow || currentShow === "") {
    canvas = <BlankShow />;
  } else if (blankCanvasShow !== true || liveshotIsPresent) {
    canvas = <CanvasContainer />;
  }

  return (
    <Fragment>
      <PopupModal
        showModal={popupModalDetails.showModal}
        onModalClose={popupModalDetails.onModalClose}
        onConfirm={popupModalDetails.onConfirm}
        title={popupModalDetails.title}
        content={popupModalDetails.content}
        type={popupModalDetails.type}
      />
      <SignalModal
        showSignalModal={showSignalModal}
        closeModal={onHideSignalModal}
        signalInfo={signalMsg}
        crMsg={showCrPathMsg}
      />
      <NotificationBarComponent
        show={commonNotificationDetails.show}
        errorMessage={commonNotificationDetails.content}
        closeErrorBar={commonNotificationDetails.onClose}
        isError={commonNotificationDetails.isError}
        isFixed={true}
      ></NotificationBarComponent>
      <NotificationModal
        show={
          showNotificationModal &&
          (notificationModalType === "sessionTimeout" || isAlert)
        }
        type={notificationModalType}
        notification={notificationMsg}
        closeModal={() => setShowNotificationModal(false)}
        onConfirm={
          notificationModalType === "sessionTimeout" ? reloadSession : onRefresh
        }
        updatedBy={userName}
        normalLiveShots={normalLiveShots}
      />
      <Notify />
      <CanvasHeader userFullName={userFullName} />
      {canvas}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    currentCanvasId: state.canvasInfo.currentCanvasId,
    successMsg: state.liveShotInfo.successMsg,
    showModal: state.liveShotInfo.showModal,
    normalLiveShots: state.liveShotContainer.normalLiveShots,
    blankCanvasShow: state.liveShotContainer.blankCanvasShow,
    loading: state.liveShotContainer.loading,
    isAuthenticated: state.auth.accessToken !== "",
    token: state.auth.accessToken,
    currentUser: state.auth.currentUser,
    currentUserRole: state.user.currentUserRole,
    signalMsg: state.signalsInfo.signalMsg,
    showSignalModal: state.signalsInfo.showSignalModal,
    showBorderMsg: state.signalsInfo.showBorderMsg,
    showCrPathMsg: state.signalsInfo.showCrPathMsg,
    notifications: state.websocketInfo.socketMsg,
    nonGrabNotifications: state.websocketInfo.nongrabMsg,
    showDate: state.canvasInfo.showDate,
    currentShow: state.showInfo.currentShow,
    liveshotsInfo: state.liveShotContainer.liveshotsInfo,
    userList: state.user.userList,
    liveShotSignalSelected: state.signalsInfo.liveShotSignalSelected,
    showErrorModal: state.signalsInfo.showErrorModal,
    inBoundCRPath: state.signalsInfo.inBoundCRPath,
    outBoundCRPath: state.signalsInfo.outBoundCRPath,
    ifbCRPath: state.signalsInfo.ifbCRPath,
    plCRPath: state.signalsInfo.plCRPath,
    phoCRPath: state.signalsInfo.phoCRPath,
    shortCut: state.canvasInfo.shortCut,
    streamDeckOn: state.canvasInfo.streamDeckOn,
    commonPopupModalDetails: state.notificationInfo.commonPopupModalDetails,
    notificationBarDetails: state.notificationInfo.notificationBarDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchCurrentUserRole: (sso, token) =>
      dispatch(actions.fetchCurrentUserRole(sso, token)),
    onShowNotificationModal: () => dispatch(actions.openNotificationModal()),
    onHideSignalModal: () => dispatch(actions.hideSignalModal()),
    onCheckCanvas: (token, date, showId) =>
      dispatch(actions.canvasCheck(token, date, showId)),
    createNotification: (config) => dispatch(createNotification(config)),
    onFetchStudioTruckList: (token) =>
      dispatch(actions.fetchStudioTruckList(token)),
    onFetchHeartBeat: (sso, token, onSessionTimeOut) =>
      dispatch(actions.fetchHeartBeat(sso, token, onSessionTimeOut)),
    onFetchAllLiveshotPreference: (token) =>
      dispatch(actions.fetchAllLiveshotPreference(token)),
    onFetchRoleList: (token) => dispatch(actions.fetchRoleList(token)),
    onSetActiveLiveshot: (id) => dispatch(actions.setActiveLiveshot(id)),
    onSetPressedKey: (key) => dispatch(actions.setPressedKey(key)),
    onStoreArrPosition: (arrPosition) =>
      dispatch(actions.onStoreArrPosition(arrPosition)),
    authRemove: () => dispatch(actions.authRemove()),
    onFetchStudioTruckTypes: (token) =>
      dispatch(actions.fetchStudioTruckTypes(token)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
