import React, { useState, useEffect, Fragment, useRef } from "react";
import { connect } from "react-redux";
import { Button } from "antd";
import * as actions from "../../store/actions/index";
import Backdrop from "../../components/shared/UI/Backdrop/Backdrop";
import VenueModal from "../modals/venueModal/VenueModal";
import { useHistory } from "react-router-dom";
import "./StudioTruck.css";
import { DebounceInput } from "react-debounce-input";
import { VENUE_TYPES } from "../../constants/PageConstants";
import DropdownSuggestions from "../dropdownSuggestions/DropdownSuggestions";
import axios from "../../axios";
import { CustomInput } from "../shared/UI/Input/CustomInput";
import ClickAwayListenerHoc from "../shared/UI/ClickAwayListenerHoc";
import { ReactComponent as DropDownArrowIcon } from "../../assets/icons/Arrow-down.svg";
import Autocomplete from "../autocomplete/Autocomplete";
import { getContactList } from "../shared/utility";
import * as Constants from "../shared/Constants";

const StudioTruck = (props) => {
  const {
    lsId,
    onPatchStudioTruck,
    token,
    studioTruckList,
    studioTruckTypes,
    onSelectedStudioTruck,
    stIFB,
    stPL,
    stPHO,
    stName,
    stPhone,
    stEmail,
    stContact,
    venueName,
    studioCost,
    stDesc,
    studioDisabled,
    studioId,
    studioLocation,
    venueType,
    type,
    tempVType,
    showVenue,
    venueCity,
    venueRegion,
    venueCountry,
    venueSkype,
    venueQuicklink,
    venueWebOther,
    roomCore,
    studioDefaultTransmissionPath,
    onUpdateLiveShotOnChange,
    stTypeId,
    formInfo,
    read,
    hideVenueTypes,
    onChangeVenueType,
    handleOnClear,
    onChangeType,
    onChangeCity,
    onChangeRegion,
    onChangeCountry,
    onChangeSkype,
    onChangeQuicklink,
    onChangeWebOther,
    onChangeTxpath,
    onChangeLiveshotNotes,
    onChangePoNumber,
    onChangeVenueContactName,
    onChangeVenueContactPhone,
    onChangeVenueContactEmail,
    emailError,
    phoneError,
    onStudioVenueNameChange,
    permission,
    liveshotNotes,
    stateList,
    countryList,
    getRefreshToken,
    onChangeContactDetails,
  } = props;

  const initialValues = {};
  const history = useHistory();

  const [studioName, setStudioName] = useState(null);
  const [studioContact, setStudioContact] = useState(null);
  const [studioPhone, setStudioPhone] = useState(null);
  const [studioEmail, setStudioEmail] = useState(null);
  const [venueStCity, setStVenueCity] = useState(null);
  const [venueStRegion, setStVenueRegion] = useState(null);
  const [venueStCountry, setStVenueCountry] = useState(null);
  const [stSkype, setStSkype] = useState(null);
  const [stQuicklink, setStQuicklink] = useState(null);
  const [stWebOther, setStWebOther] = useState(null);
  const [filteredSuggestions, setFilteredSuggestions] = useState(initialValues);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [showVenueForm, setShowVenueForm] = useState(false);
  const [addButtonDisabled, setAddButtonDisabled] = useState(false);
  const [stTxPath, setStTxPath] = useState(null);
  const [stLiveshotNotes, setStLiveshotNotes] = useState("");
  const [studioNotes, setStudioNotes] = useState(null);
  const [readOnly, setReadOnly] = useState(false);
  const [vtype, setvType] = useState("");
  const [onClear, setOnClear] = useState(false);
  const [over] = useState(false);
  const [cityList, setCityList] = useState([]);
  const contactList = getContactList(studioTruckList, studioName);

  const [studioTruckNameList, setStudioTruckNameList] =
    useState(studioTruckList);

  const [showContactNameOption, setShowContactNameOption] = useState(false);
  const [showVenueTypeOption, setShowTypeOption] = useState(false);

  const studioTruckTypeRef = useRef(null);
  const studioContactRef = useRef(null);

  useEffect(() => {
    setReadOnly(read);
  }, [read]);

  useEffect(() => {
    setStudioName(stName);
    setStudioPhone(stPhone);
    setStudioEmail(stEmail);
    setStudioContact(stContact);
    setStudioNotes(stDesc);
    setStVenueCity(venueCity);
    setStVenueRegion(venueRegion);
    setStVenueCountry(venueCountry);
    setStSkype(venueSkype);
    setStQuicklink(venueQuicklink);
    setStWebOther(venueWebOther);
    setvType(venueType);
    setStTxPath(studioDefaultTransmissionPath);
    setStLiveshotNotes(liveshotNotes);
    if (stName) {
      setAddButtonDisabled(false);
    } else {
      setAddButtonDisabled(true);
    }
  }, [
    stName,
    stPhone,
    stEmail,
    stContact,
    studioCost,
    stDesc,
    studioLocation,
    venueCity,
    venueRegion,
    venueCountry,
    venueType,
    venueSkype,
    venueQuicklink,
    venueWebOther,
    stTypeId,
    studioDefaultTransmissionPath,
    vtype,
  ]);

  const closeModal = () => {
    setShowVenueForm(false);
  };

  const onClickContactList = (selectedContact) => {
    if (selectedContact) {
      const contactDetails = {
        liveshotContactName: selectedContact.studioContact,
        liveshotContactPhone: selectedContact.studioPhone,
        liveshotContactEmail: selectedContact.studioEmail,
      };
      onChangeContactDetails(contactDetails, selectedContact);
      console.log(contactDetails);
      selectedContact && onUpdateLiveShotOnChange(lsId, contactDetails, token);
    }
  };

  const onClickList = (name) => {
    let selectedStudio = studioTruckList.filter(
      (list) =>
        (list.studioName || "").toLowerCase().trim() ===
          name.toLowerCase().trim() && list.softDelete === false
    );
    if (selectedStudio !== null && selectedStudio.length > 0) {
      let Id = selectedStudio[0].stId;
      //  Old logic for fetching COMMS paths when a venue is clicked
      onSelectedStudioTruck(token, Id, lsId, true);
      if (selectedStudio[0].studioTypeName === "VIDEO CONFERENCE") {
        onUpdateLiveShotOnChange(
          lsId,
          {
            stId: Id,
            venue: name,
            venueType: selectedStudio[0].studioTypeName,
            skype: selectedStudio[0].skype,
            quickLink: selectedStudio[0].quickLink,
            webOther: selectedStudio[0].webOther,
            city: null,
            region: null,
            country: null,
            tx: selectedStudio[0].studioTx,
            liveshotContactName: selectedStudio[0].studioContact,
            liveshotContactPhone: selectedStudio[0].studioPhone,
            liveshotContactEmail: selectedStudio[0].studioEmail,
            poNumber: selectedStudio[0].poNumber,
            liveshotNote: selectedStudio[0].liveshotNote,
          },
          token
        );
      } else if (
        selectedStudio[0].studioTypeName === "AD HOC FEED" ||
        selectedStudio[0].studioTypeName === "TRUCK"
      ) {
        onUpdateLiveShotOnChange(
          lsId,
          {
            stId: Id,
            venue: name,
            venueType: selectedStudio[0].studioTypeName,
            city: null,
            region: null,
            country: null,
            skype: null,
            quickLink: null,
            webOther: null,
            tx: selectedStudio[0].studioTx,
            liveshotContactName: selectedStudio[0].studioContact,
            liveshotContactPhone: selectedStudio[0].studioPhone,
            liveshotContactEmail: selectedStudio[0].studioEmail,
            poNumber: selectedStudio[0].poNumber,
            liveshotNote: selectedStudio[0].liveshotNote,
          },
          token
        );
      } else {
        onUpdateLiveShotOnChange(
          lsId,
          {
            stId: Id,
            venue: name,
            venueType: selectedStudio[0].studioTypeName,
            city: selectedStudio[0].city,
            region: selectedStudio[0].region,
            country: selectedStudio[0].country,
            skype: null,
            quickLink: null,
            webOther: null,
            tx: selectedStudio[0].studioTx,
            liveshotContactName: selectedStudio[0].studioContact,
            liveshotContactPhone: selectedStudio[0].studioPhone,
            liveshotContactEmail: selectedStudio[0].studioEmail,
            poNumber: selectedStudio[0].poNumber,
            liveshotNote: selectedStudio[0].liveshotNote,
          },
          token
        );
      }
    }
  };

  const onClose = () => {
    setStudioName(null);
    setStudioPhone(null);
    setStudioEmail(null);
    setStudioContact(null);
    setStTxPath(null);
    setStudioNotes(null);
    setStVenueCity("");
    setStVenueRegion("");
    setStVenueCountry("");
    setStSkype("");
    setStQuicklink("");
    setStWebOther("");
    onSelectedStudioTruck(token, 0, lsId);
    onUpdateLiveShotOnChange(
      lsId,
      {
        venue: "",
        venueType: tempVType,
        stId: 0,
        city: "",
        region: "",
        country: "",
        skype: "",
        quickLink: "",
        webOther: "",
        tx: "",
      },
      token
    );
  };

  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      setShowContactNameOption(false);
    }
  };

  const handleContactNameClick = (e) => {
    setShowContactNameOption(!showContactNameOption);
  };

  const fetchCities = (searchKey) => {
    setCityList([]);
    axios
      .get(`liveshot-api/location/city?searchKey=${searchKey}`, {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((res) => {
        setCityList(res.data.cityList);
      })
      .catch((err) => {
        console.log("error ", err);
        if (err.response.status === 401) {
          getRefreshToken();
        }
      });
  };

  const wrapperRef = useRef(null);
  let venueForm = null;
  if (showVenueForm) {
    venueForm = (
      <Fragment>
        <Backdrop show={showVenueForm} clicked={closeModal} />
        <VenueModal
          closeModal={closeModal}
          stName={stName}
          stContact={stContact}
          stPhone={stPhone}
          stEmail={stEmail}
          studioCost={studioCost}
          stDesc={stDesc}
          studioId={studioId}
          venueStCity={venueStCity}
          venueStRegion={venueStRegion}
          venueStCountry={venueStCountry}
          stSkype={stSkype}
          stQuicklink={stQuicklink}
          stWebOther={stWebOther}
          stTxPath={stTxPath}
          showDeleteButton={addButtonDisabled}
          lsId={lsId}
          onDelete={onClose}
          stTypeId={type}
          stLiveshotNotes={stLiveshotNotes}
        />
      </Fragment>
    );
  }

  return (
    <div className="studioform-detail-container">
      {venueForm}
      <div className="liveshotstudio">
        <div className="venue-name-leftpnl field">
          <div className="input-label"> Venue Name</div>
          <Autocomplete
            value={studioName ? studioName : ""}
            placeholder="ENTER VENUE NAME"
            onChange={(value, e, isSelect) => {
              if (isSelect) {
                onClickList(value);
              } else {
                setStudioName(value);
                e && onStudioVenueNameChange(e);
              }
            }}
            disabled={permission}
            options={studioTruckNameList.map((i) => i.studioName)}
            length={3}
            maxLength={Constants.INPUT_MAX_LENGTH}
            name="leftpanel-venueName"
            required
          />
        </div>
        <div className="venue-type-container">
          <div className="venue-type-leftpnl field">
            <div className="input-label"> Venue Type</div>
            <div className="vtype-container">
              <div
                className={`${
                  !permission && showVenue
                    ? "dropdown-focused-bg v-type"
                    : "dropdown-black-bg v-type"
                } ${!vtype ? "dropdown-req" : "dropdown-filled"} ${
                  permission ? "restricted" : ""
                }`}
                ref={studioTruckTypeRef}
                value={type ? type : ""}
                onChange={onChangeType}
                onClick={() => {
                  !permission && hideVenueTypes();
                }}
                disabled={permission}
              >
                <div
                  style={{ color: showVenue && "#141414" }}
                  className={vtype ? "hidden" : "venue-placeholder wrap-text"}
                >
                  {!tempVType || !vtype
                    ? "e.g. 30R LOCATION, SKYPE, QUICKLINK..."
                    : tempVType}
                </div>
                <div className={vtype ? "info wrap-text" : "hidden"}>
                  {vtype}
                </div>
                <div
                  className={`dropdown-arrow-icon ${vtype ? "focused" : ""}`}
                >
                  <DropDownArrowIcon
                    className={`${showVenue ? " open" : ""}`}
                    alt="Plus Logo"
                  />
                </div>
              </div>
            </div>
            <div className="venue-position">
              {showVenue && !permission && (
                <ClickAwayListenerHoc
                  nodeRef={studioTruckTypeRef.current}
                  elementToIgnore="studioTruckOption"
                  hideElement={hideVenueTypes}
                >
                  <ul className="ul-list-item-container">
                    {studioTruckTypes &&
                      studioTruckTypes.map((item) => (
                        <li
                          name="studioTruckOption"
                          className={
                            "ul-list-item" +
                            (over || vtype == item.studioTypeName
                              ? " list-item-bg"
                              : "")
                          }
                          key={item.studioTypeId}
                          value={item.studioTypeId}
                          onClick={(e) => onChangeVenueType(e)}
                        >
                          {item.studioTypeName}
                        </li>
                      ))}
                  </ul>
                </ClickAwayListenerHoc>
              )}
            </div>
          </div>
          <div
            className="venue-city-container field-r"
            style={VENUE_TYPES.includes(vtype) ? { display: "none" } : {}}
          >
            <div className="venue-city-leftpnl">
              <div className="input-label">City</div>
              <DebounceInput
                className={`venueCityInput required-input ${
                  venueStCity ? "value-filled" : ""
                }`}
                debounceTimeout={500}
                autoComplete="off"
                value={venueStCity ? venueStCity : ""}
                placeholder="ENTER CITY"
                onChange={(e) => {
                  onChangeCity(e.target.value);
                }}
                disabled={permission}
                maxLength={Constants.INPUT_MAX_LENGTH}
              />
            </div>
            <div className="venue-region-leftpnl">
              <div className="input-label">St, Province, or Region</div>
              <Autocomplete
                value={venueStRegion ? venueStRegion : ""}
                placeholder="ENTER ST, PROVINCE, OR REGION"
                onChange={(e) => {
                  onChangeRegion(e);
                  setStVenueRegion(e);
                }}
                disabled={permission}
                options={stateList}
                maxLength={Constants.INPUT_MAX_LENGTH}
                name="leftpanel-venueRegion"
                required
              />
            </div>
          </div>
          <div className={"venue-country-container field-r"}>
            <div
              className={`${
                [
                  "VIDEO CONFERENCE",
                  "TRUCK",
                  "AD HOC FEED",
                  "30R LOCATION",
                ].includes(vtype)
                  ? "venue-clear-button padding-top"
                  : "venue-clear-button"
              }`}
            >
              <button
                className={`venue-type-clear-btn ${
                  permission ? "restricted" : ""
                }`}
                onClick={() => {
                  permission ? undefined : handleOnClear();
                }}
              >
                Clear
              </button>
            </div>
            <div
              className="venue-country-leftpnl"
              style={VENUE_TYPES.includes(vtype) ? { display: "none" } : {}}
            >
              <div className="input-label">Country</div>
              <Autocomplete
                value={venueStCountry ? venueStCountry : ""}
                onChange={(e) => {
                  onChangeCountry(e);
                  setStVenueCountry(e);
                }}
                disabled={permission}
                options={countryList}
                maxLength={Constants.INPUT_MAX_LENGTH}
                name="leftpanel-venueCountry"
              />
            </div>
          </div>
          <div
            className="vc-container"
            style={
              vtype === "VIDEO CONFERENCE"
                ? { display: "flex" }
                : { display: "none" }
            }
          >
            <div className="venue-vc-container">
              <div>
                <div className="input-label">Skype:</div>
                <DebounceInput
                  type="text"
                  debounceTimeout={1500}
                  className={`skypeInput transform-input`}
                  value={stSkype ? stSkype : ""}
                  onKeyPress={(e) => onKeyPress(e)}
                  onChange={(e) => onChangeSkype(e)}
                  autoComplete="off"
                  disabled={permission}
                />
              </div>
              <div>
                <div className="input-label">Quicklink:</div>
                <DebounceInput
                  type="text"
                  debounceTimeout={1500}
                  className={`quicklinkInput transform-input`}
                  onChange={(e) => onChangeQuicklink(e)}
                  autoComplete="off"
                  onKeyPress={(e) => onKeyPress(e)}
                  value={stQuicklink ? stQuicklink : ""}
                  disabled={permission}
                />
              </div>
              <div>
                <div className="input-label">Web/Other:</div>
                <DebounceInput
                  type="text"
                  debounceTimeout={1500}
                  className={`webotherInput transform-input`}
                  autoComplete="off"
                  onChange={(e) => {
                    onChangeWebOther(e);
                  }}
                  onKeyPress={(e) => onKeyPress(e)}
                  value={stWebOther ? stWebOther : ""}
                  disabled={permission}
                />
              </div>
            </div>
          </div>
          <div
            className="vc-container"
            style={
              vtype === "HOME CAM" ||
              vtype === "ZOOM" ||
              vtype === "SKYPE" ||
              vtype === "QLINK" ||
              vtype === "WEB"
                ? { display: "flex" }
                : { display: "none" }
            }
          ></div>
        </div>

        <div className="studio-contact">
          <div className="tx-number-leftpnl field">
            <span className="input-label">Tx</span>
            <DebounceInput
              element={CustomInput}
              overridestyle={"true"}
              debounceTimeout={1500}
              className={`venueTxInput transform-input`}
              name="Tx"
              maxLength={1000}
              value={stTxPath ? stTxPath : ""}
              autoComplete="off"
              onChange={(e) => onChangeTxpath(e)}
              onKeyPress={(e) => onKeyPress(e)}
              disabled={permission}
            />
          </div>
          <div className="contact-name-leftpnl field">
            <div className="input-label"> Venue Contact Name</div>
            <ClickAwayListenerHoc
              active={showContactNameOption}
              nodeRef={studioContactRef.current}
              elementToIgnore="studioContactOption"
              hideElement={() => {
                setShowContactNameOption(false);
              }}
            >
              <div
                className={`contact-container ${
                  showContactNameOption ? "focused" : ""
                }`}
                ref={studioContactRef}
                onClick={() => {
                  !showContactNameOption && handleContactNameClick();
                }}
              >
                <DebounceInput
                  element={CustomInput}
                  overrideStyle={true}
                  maxLength={Constants.INPUT_MAX_LENGTH}
                  autoSize={{ minRows: 1, maxRows: 2 }}
                  className={`venueContactInput contactName`}
                  debounceTimeout={1500}
                  autoComplete="off"
                  value={studioContact ? studioContact : ""}
                  onChange={(e) => {
                    setStudioContact(e.target.value);
                    onChangeVenueContactName(e);
                    setShowContactNameOption(true);
                  }}
                  onKeyPress={(e) => onKeyPress(e)}
                  disabled={permission}
                  id="contactName"
                />
                {contactList && contactList.length != 0 && (
                  <div
                    className={`dropdown-arrow-icon ${
                      showContactNameOption || studioContact ? "focused" : ""
                    }`}
                  >
                    <DropDownArrowIcon
                      className={`${showContactNameOption ? " open" : ""}`}
                      alt="Plus Logo"
                      onClick={handleContactNameClick}
                    />
                  </div>
                )}
                {showContactNameOption && (
                  <DropdownSuggestions
                    options={contactList}
                    labelField="studioContact"
                    onSelect={(option, key) => {
                      onClickContactList(option);
                      setShowContactNameOption(false);
                    }}
                    selected={studioContact ? studioContact : ""}
                    length={3}
                  />
                )}
              </div>
            </ClickAwayListenerHoc>
          </div>

          <div className="contact-phone-leftpnl field">
            <div className="input-label"> Venue Contact Phone</div>
            <DebounceInput
              element={CustomInput}
              overrideStyle={true}
              maxLength={1000}
              autoSize={{ minRows: 1, maxRows: 2 }}
              className={`venueContactInput contactPhone`}
              debounceTimeout={500}
              autoComplete="off"
              value={studioPhone || ""}
              onChange={(e) => onChangeVenueContactPhone(e)}
              onKeyPress={(e) => onKeyPress(e)}
              disabled={permission}
            />
          </div>
          <div className="contact-email-leftpnl field">
            <div className="input-label"> Venue Contact Email</div>
            <DebounceInput
              element={CustomInput}
              overrideStyle={true}
              maxLength={Constants.INPUT_MAX_LENGTH}
              autoSize={{ minRows: 1, maxRows: 2 }}
              className={`venueContactInput contactEmail`}
              debounceTimeout={1500}
              autoComplete="off"
              value={studioEmail || ""}
              onChange={(e) => onChangeVenueContactEmail(e)}
              onKeyPress={(e) => onKeyPress(e)}
              disabled={permission}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.accessToken,
    studioTruckList: state.studioTruckInfo.studioTruckList,
    showModal: state.studioTruckOperations.showModal,
    studioTruckTypes: state.studioTruckInfo.studioTruckTypes,
    stateList: state.searchListInfo.stateList,
    countryList: state.searchListInfo.countryList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onPatchStudioTruck: (studioFormData, token, stId) =>
      dispatch(actions.patchStudioTruck(studioFormData, token, stId)),
    onFetchStudioTruckList: (token) =>
      dispatch(actions.fetchStudioTruckList(token)),
    onSelectedStudioTruck: (token, stId, lsId, setPin = undefined) =>
      dispatch(actions.fetchStudioTruckData(token, stId, lsId, setPin)),
    onCloseCommanModal: (token) =>
      dispatch(actions.closeStudioTruckModal(token)),
    onUpdateLiveShotOnChange: (liveshotId, formData, token) =>
      dispatch(actions.updateLiveShotOnChange(liveshotId, formData, token)),
    getRefreshToken: () => dispatch(actions.getRefreshToken()),
  };
};

export default React.memo(
  connect(mapStateToProps, mapDispatchToProps)(StudioTruck)
);

StudioTruck.defaultProps = {
  onChangeContactDetails: (props) => {},
};
