import * as actionTypes from "./actionTypes";
import axios from "../../axios";
import * as actions from "../../store/actions/index";

export const addNormalLiveShotType = (type) => {
  return {
    type: actionTypes.NORMAL_LIVESHOT_TYPE,
    normalLiveShotTypes: type,
  };
};

export const normalLiveShots = (data) => {
  return {
    type: actionTypes.NORMAL_LIVESHOTS,
    normalLiveShots: data,
  };
};

export const liveShots = (nordata) => {
  return {
    type: actionTypes.LIVESHOTS,
    normalLiveShots: nordata,
  };
};

export const noLiveShots = () => {
  return {
    type: actionTypes.NO_LIVESHOTS,
  };
};

export const loadingStart = () => {
  return {
    type: actionTypes.LOADING_START,
  };
};

export const loadingEnd = () => {
  return {
    type: actionTypes.LOADING_END,
  };
};

export const makeFold = () => {
  return {
    type: actionTypes.MAKE_FOLD,
  };
};

export const startLSCreation = (blockId) => {
  return {
    type: actionTypes.START_LIVESHOT_CREATION,
    blockId: blockId,
  };
};

export const resetLSCreation = () => {
  return {
    type: actionTypes.RESET_LIVESHOT_CREATION,
  };
};

export const setLiveshotsInfo = (lsInfo) => {
  return {
    type: actionTypes.LIVESHOTS_INFO,
    liveshotsInfo: lsInfo,
  };
};

export const getLiveshotInfo = (lsInfo) => {
  return {
    type: actionTypes.LIVESHOT_INFO,
    liveshotsInfo: lsInfo,
  };
};

export const onStoreArrPosition = (arrPosition) => {
  return {
    type: actionTypes.STORE_ARR_POSITION,
    arrPosition: arrPosition,
  };
};

export const getHostStudioLiveShots = (canvasId, token) => {
  return (dispatch) => {
    dispatch(loadingStart());
    axios
      .get(`liveshot-api/liveshot/canvas/${canvasId}`, {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((res) => {
        const fetchedNLiveShots = [];
        if (res.data.length > 0) {
          for (let key in res.data) {
            const normalLiveShot = res.data[key];
            if (
              normalLiveShot.blockName !== undefined &&
              normalLiveShot.blockName !== null
            ) {
              fetchedNLiveShots.push({
                ...normalLiveShot,
                canvasId: normalLiveShot.canvasId,
                blockId: normalLiveShot.blockId,
                sequenceNo: normalLiveShot.sequenceNo,
                liveshots: normalLiveShot.liveshots.filter(
                  (item) => item.softDelete !== true
                ),
              });
            }
          }
        }
        dispatch(actions.resetStudioTruckData());
        dispatch(actions.resetSuccessMsg());
        dispatch(actions.resetSignalsInfo());
        dispatch(actions.setActiveLiveshot(null));
        dispatch(liveShots(fetchedNLiveShots));
      })
      .catch((err) => {
        dispatch(loadingEnd());
        dispatch(noLiveShots());
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
      });
  };
};

export const getLiveShotData = (lsId, token) => {
  return (dispatch) => {
    dispatch(loadingStart());
    axios
      .get(`liveshot-api/liveshot/${lsId}`, {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((res) => {
        console.log("live shot info");
        console.log(res);
      })
      .catch((err) => {
        dispatch(loadingEnd());
        dispatch(noLiveShots());
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
        console.log("error ", err);
      });
  };
};
export const getLiveShotsWithOutLoading = (canvasId, token) => {
  return (dispatch) => {
    axios
      .get(`liveshot-api/liveshot/canvas/${canvasId}`, {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((res) => {
        const fetchedNLiveShots = [];
        if (res.data.length > 0) {
          for (let key in res.data) {
            const normalLiveShot = res.data[key];
            if (
              normalLiveShot.blockName !== undefined &&
              normalLiveShot.blockName !== null
            ) {
              fetchedNLiveShots.push({
                ...normalLiveShot,
                canvasId: normalLiveShot.canvasId,
                blockId: normalLiveShot.blockId,
                sequenceNo: normalLiveShot.sequenceNo,
                liveshots: normalLiveShot.liveshots.filter(
                  (item) => item.softDelete !== true
                ),
              });
            }
          }
        }
        dispatch(actions.resetStudioTruckData());
        dispatch(actions.resetSuccessMsg());
        dispatch(actions.setActiveLiveshot(null));
        dispatch(liveShots(fetchedNLiveShots));
      })
      .catch((err) => {
        dispatch(noLiveShots());
        console.log("error ", err);
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
        dispatch(actions.toggleLoader());
      });
  };
};

export const updateLiveshotStatus = (res) => {
  return {
    type: actionTypes.UPDATE_NORMAL_LIVESHOTS,
    normalLiveShots: res,
  };
};

export const updateBlock = (
  updatedBy,
  block_id,
  block,
  currentCanvasId,
  token
) => {
  block.updatedBy = updatedBy;
  return (dispatch) => {
    axios
      .patch(`liveshot-api/block/${block_id}`, block, {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((res) => {
        console.log("block updated: ", block_id);
      })
      .catch((err) => {
        console.log("error ", err);
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
      });
  };
};

export const updateLiveshot = (
  updatedBy,
  currentCanvasId,
  liveshot_id,
  liveshot,
  token,
  refetchCanvas = true
) => {
  liveshot.updatedBy = updatedBy;
  return (dispatch) => {
    dispatch(actions.toggleLoader());
    axios
      .patch(`liveshot-api/liveshot/${liveshot_id}`, liveshot, {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((res) => {
        res.data.updatedBy = updatedBy;
        console.log("liveshot updated:: ", liveshot_id);
        if (refetchCanvas) {
          dispatch(actions.getLiveShotsWithOutLoading(currentCanvasId, token));
        }
        dispatch(actions.toggleLoader());
      })
      .catch((err) => {
        console.log("error ", err);
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
        dispatch(actions.toggleLoader());
      });
  };
};

export const createBlock = (
  createdBy,
  canvas_id,
  block_name,
  sequence_no,
  liveshot_id,
  token
) => {
  return (dispatch) => {
    const block = {
      canvasId: canvas_id,
      blockName: block_name,
      hitTime: null,
      sequenceNo: sequence_no,
      createdBy: createdBy,
      updatedBy: createdBy,
      softDelete: false,
    };
    axios
      .post("liveshot-api/block/", block, {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((response) => {
        if (liveshot_id != null) {
          const blockData = {
            blockId: response.data.blockId,
            sequenceNo: 1,
            updatedBy: createdBy,
          };
          axios
            .patch(`liveshot-api/liveshot/${liveshot_id}`, blockData, {
              withCredentials: true,
              headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
            })
            .then((res) => {
              dispatch(getHostStudioLiveShots(canvas_id, token));
            })
            .catch((err) => {
              console.log("error ", err);
            });
        } else {
          dispatch(getLiveShotsWithOutLoading(canvas_id, token));
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
      });
  };
};

export const deleteBlock = (block_id, canvas_id, userId, token) => {
  return (dispatch) => {
    dispatch(actions.toggleLoader());
    axios
      .delete(`liveshot-api/block/${block_id}?deletedBySSO=${userId}`, {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((res) => {
        dispatch(getLiveShotsWithOutLoading(canvas_id, token));
        dispatch(actions.toggleLoader());
      })
      .catch((err) => {
        console.log("error ", err);
        dispatch(actions.toggleLoader());
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
      });
  };
};

export const deleteLiveShot = (liveshot_id, canvas_id, userId, token) => {
  return (dispatch) => {
    dispatch(actions.toggleLoader());
    axios
      .delete(`liveshot-api/liveshot/${liveshot_id}?deletedBySSO=${userId}`, {
        withCredentials: true,
        headers: { Authorization: token !== "" ? `Bearer ${token}` : "" },
      })
      .then((res) => {
        dispatch(getLiveShotsWithOutLoading(canvas_id, token));
        dispatch(actions.toggleLoader());
      })
      .catch((err) => {
        console.log("error ", err);
        dispatch(actions.toggleLoader());
        if (err.response.status === 401) {
          dispatch(actions.getRefreshToken());
        }
      });
  };
};

export const saveLiveshotsInfo = (lsInfo) => {
  return (dispatch) => {
    dispatch(setLiveshotsInfo(lsInfo));
  };
};
