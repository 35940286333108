import React, { Fragment, useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import Loader from "../../shared/UI/Loader/Loader.js";
import * as actions from "../../../store/actions/index";
import "./LiveShotSignals.css";
import InboundSignals from "./InboundSignals.js";
import OutboundSignals from "./OutboundSignals.js";
import CommsSignals from "./CommsSignals.js";
import { removeDuplicates } from "../../shared/utility.js";
import { POPMESSAGE } from "../../shared/Constants.js";

const LiveShotSignals = (props) => {
  const {
    isDisable,
    liveShotId,
    formInfo,
    abstractLiveshotId,
    onFetchSelectedSignal,
    onCreateLiveShotSignal,
    onDeleteLiveShotSignal,
    plCRPath,
    triggerAvailVcInitState,
    outBoundGlobalPath,
    token,
    currentUser,
    currentCanvasId,
    currentCanvas,
    controlRoomList,
    onChangeSignal,
    liveshotStatus,
    onCreateLiveShotSignalWithValue,
    loading,
    fetchRouterDestinations,
    ifbPin,
    plPin,
    phoPin,
    onChangePinFromSignals,
    onResetAllPin,
    defaultCommSignals,
    resetDefaultCommSignal,
    handlePinCommsSync,
    liveShotVenueType,
    windowEndTime,
    liveShotSignalSelected,
    onUpdateLiveShotSignals,
    unOwnLiveshotSignals,
    onTakeAndGrab,
    userList,
    windowStartTime,
    showCommonPopupModal,
  } = props;

  let userFullName = currentUser.firstName + " " + currentUser.lastName;
  let userId = currentUser.sso;
  if (process.env.REACT_APP_AUTH_ENABLED !== "true") {
    userFullName =
      process.env.REACT_APP_AUTH_OVERRIDE_FIRSTNAME +
      " " +
      process.env.REACT_APP_AUTH_OVERRIDE_LASTNAME;
    userId = process.env.REACT_APP_AUTH_OVERRIDE_ID;
  }

  const [inBoundFields, setInBoundFields] = useState([]);
  const [outBoundFields, setOutBoundFields] = useState([]);
  const [commsFields, setCommsFields] = useState([]);
  const [currentRoom, setCurrentRoom] = useState(null);
  const [outBoundForInBound, setOutBoundForInBound] = useState("");
  const [customIndexNum, setCustomIndexNum] = useState(null);

  useEffect(() => {
    fetchRouterDestinations(token);
  }, [token, fetchRouterDestinations]);

  useEffect(() => {
    onFetchSelectedSignal(token, liveShotId, currentCanvasId);
  }, [onFetchSelectedSignal, token, liveShotId]);

  useEffect(() => {
    if (currentCanvas && controlRoomList.length > 0) {
      let room = controlRoomList.find(
        (list) => list.roomId === currentCanvas.roomId
      );
      if (room) {
        setCurrentRoom(room);
      }
    }
  }, [controlRoomList, currentCanvas, currentRoom]);

  const splitOutBoundSignalValue = useCallback((pathName) => {
    var typicalPatt = /\d\d\d\d?/i;
    var elements = [];
    if (pathName !== null) {
      if (pathName.match(typicalPatt)) {
        elements.push(pathName.match(typicalPatt)[0].trim());
        elements.push(pathName.replace(typicalPatt, ""));
      }
    }
    return elements;
  }, []);

  const onAddSignal = (
    token,
    liveShotId,
    abstractLiveshotId,
    signalTypeID,
    userId,
    room,
    key
  ) => {
    if (key === "addInbound") {
      onCreateLiveShotSignal(
        token,
        liveShotId,
        abstractLiveshotId,
        signalTypeID,
        userId,
        room,
        liveShotVenueType
      );
    } else if (key === "addOutbound" || key === "addOutbutton") {
      let globalPathName = outBoundGlobalPath.filter((outBoundSignal) => {
        return (
          splitOutBoundSignalValue(outBoundSignal)[0] === outBoundForInBound &&
          key === "addOutbutton"
        );
      });
      onCreateLiveShotSignalWithValue(
        token,
        liveShotId,
        abstractLiveshotId,
        signalTypeID,
        userId,
        room,
        "",
        globalPathName[0]
      );
    } else if (key === "addComms") {
      onCreateLiveShotSignal(
        token,
        liveShotId,
        abstractLiveshotId,
        signalTypeID,
        userId,
        room,
        ""
      );
    } else {
      let ifbList = [];
      let existingPlList = [];

      let crPathName =
        ifbList.length > 0
          ? plCRPath.filter((plSignal) => {
              return plSignal.slice(4, 7) == ifbList[0];
            })
          : [];
      onCreateLiveShotSignalWithValue(
        token,
        liveShotId,
        abstractLiveshotId,
        signalTypeID,
        userId,
        room,
        crPathName[0]
      );
    }
  };

  const deleteSignal = (liveShotSignalId, normalLiveshotSignalId = null) => {
    onDeleteLiveShotSignal(
      token,
      liveShotSignalId,
      normalLiveshotSignalId,
      currentRoom
    );
  };

  const checkForDuplicateLocalPaths = (field, signalList, key) => {
    let isDuplicate = null;
    isDuplicate = signalList.find(
      (obj) =>
        obj[key] === field[key] &&
        obj.liveshotSignalId !== field.liveshotSignalId &&
        obj.routed
    );
    return isDuplicate;
  };

  const getSignalList = (signalIds = [2, 5, 7, 8, 9]) => {
    let signalList = [];
    signalList = removeDuplicates(liveShotSignalSelected).filter((signal) =>
      signalIds.includes(signal.liveshotSignalTypeId)
    );
    return signalList;
  };

  const confirmDuplicateRoute = (field, crPath, btnType) => {
    let duplicateField = null;
    let signalType = "";
    if (btnType === "take") {
      duplicateField = checkForDuplicateLocalPaths(
        field,
        getSignalList([1]),
        "crPathName"
      );
    } else if (btnType === "grabOutbound") {
      duplicateField = checkForDuplicateLocalPaths(
        field,
        getSignalList([2]),
        "globalPathName"
      );
      signalType = "outbound";
    } else if (btnType === "grabComms") {
      duplicateField = checkForDuplicateLocalPaths(
        field,
        getSignalList([5, 7, 8, 9]),
        "localPathName"
      );
      signalType = "comms";
    }
    if (duplicateField) {
      if (btnType === "take") {
        onUpdateLiveShotSignals(
          token,
          duplicateField,
          { routed: false },
          currentRoom,
          false,
          true
        );
        onTakeAndGrab(
          token,
          btnType,
          userId,
          userFullName,
          field,
          crPath,
          liveShotId,
          currentCanvas.roomId,
          currentRoom
        );
      } else {
        let grabParams = {
          btnType: btnType,
          userId: userId,
          userFullName: userFullName,
          field: field,
          crPath: crPath,
          liveShotId: liveShotId,
          roomId: currentCanvas.roomId,
          currentRoom: currentRoom,
        };
        unOwnLiveshotSignals(
          token,
          duplicateField.liveshotSignalId,
          duplicateField,
          signalType,
          currentRoom,
          true,
          grabParams
        );
      }
    }
  };

  const updateInfo = (field = {}) => {
    let abstractLiveshot = formInfo.abstractLiveshot;
    let user,
      abstractUserName = null;

    if (userList.length > 0 && field !== "" && abstractLiveshot) {
      user = userList.find((usr) => usr.sso === field.updatedBy);
      abstractUserName = userList.find(
        (usr) => usr.sso === abstractLiveshot.updatedBy
      );
    }

    return (
      <div className="update-info-content">
        <div className="update-info-abstract-liveshotSignalId">
          <span>AbstractLiveshotSignal Id: </span>
          {abstractLiveshot.abstractLiveshotId
            ? abstractLiveshot.abstractLiveshotId
            : ""}
        </div>
        <div className="update-info-abstract-updated-by">
          <span>Updated By: </span>
          {abstractUserName ? abstractUserName.userName : "Unknown"}
        </div>
        <div className="update-info-abstract-updated-on">
          <span>Updated On: </span>
          {abstractLiveshot
            ? new Date(abstractLiveshot.updatedOn).toLocaleString([], {
                year: "numeric",
                month: "numeric",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                second: "numeric",
                hour12: true,
              })
            : ""}
        </div>
        <div className="update-info-liveshotSignalId">
          <span>LiveshotSignal Id: </span>
          {field.liveshotSignalId ? field.liveshotSignalId : ""}
        </div>
        <div className="update-info-updated-by">
          <span>Updated By: </span> {user ? user.userName : "Unknown"}
        </div>
        <div className="update-info-updated-on">
          <span>Updated On: </span>
          {field.updatedOn
            ? new Date(field.updatedOn).toLocaleString([], {
                year: "numeric",
                month: "numeric",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                second: "numeric",
                hour12: true,
              })
            : ""}
        </div>
      </div>
    );
  };

  const hideDropdownOnClickOutside = (data, setData, idx, updatedField) => {
    if (data.length > idx) {
      const tempData = [...data];
      tempData[idx] = {
        ...tempData[idx],
        ...updatedField,
      };
      setData(tempData);
    }
  };

  const isWindowTimeInvalid = () => {
    let windowStart = new Date(currentCanvas.showDate + " " + windowStartTime);
    let windowEnd = new Date(currentCanvas.showDate + " " + windowEndTime);

    if (!windowStartTime || windowStartTime == "" || !windowStart.getTime()) {
      showCommonPopupModal({
        showModal: true,
        title: "Warning",
        content: POPMESSAGE.OWN_WITH_NO_STARTTIME,
        type: "warning",
      });
      return true;
    }

    if (!windowEndTime || windowEndTime == "" || !windowEnd.getTime()) {
      showCommonPopupModal({
        showModal: true,
        title: "Warning",
        content: POPMESSAGE.OWN_WITH_NO_ENDTIME,
        type: "warning",
      });
      return true;
    }

    if (windowEnd.getTime() < Date.now()) {
      showCommonPopupModal({
        showModal: true,
        title: "Warning",
        content: POPMESSAGE.OWN_WHEN_ENDTIME_PAST,
        type: "warning",
      });
      return true;
    }
    return false;
  };

  return (
    <Fragment>
      <Loader loading={loading} />
      <div className="mtcdetails">
        <InboundSignals
          isDisable={isDisable}
          liveShotId={liveShotId}
          formInfo={formInfo}
          abstractLiveshotId={abstractLiveshotId}
          userId={userId}
          userFullName={userFullName}
          deleteSignal={deleteSignal}
          currentRoom={currentRoom}
          outBoundFields={outBoundFields}
          commsFields={commsFields}
          triggerAvailVcInitState={triggerAvailVcInitState}
          onChangeSignal={onChangeSignal}
          liveshotStatus={liveshotStatus}
          handlePinCommsSync={handlePinCommsSync}
          liveShotVenueType={liveShotVenueType}
          setOutBoundForInBound={setOutBoundForInBound}
          onAddSignal={onAddSignal}
          customIndexNum={customIndexNum}
          setCustomIndexNum={setCustomIndexNum}
          inBoundFields={inBoundFields}
          setInBoundFields={setInBoundFields}
          splitOutBoundSignalValue={splitOutBoundSignalValue}
          confirmDuplicateRoute={confirmDuplicateRoute}
          checkForDuplicateLocalPaths={checkForDuplicateLocalPaths}
          updateInfo={updateInfo}
          hideDropdownOnClickOutside={hideDropdownOnClickOutside}
        />
        <OutboundSignals
          isDisable={isDisable}
          liveShotId={liveShotId}
          formInfo={formInfo}
          abstractLiveshotId={abstractLiveshotId}
          userId={userId}
          userFullName={userFullName}
          deleteSignal={deleteSignal}
          currentRoom={currentRoom}
          inBoundFields={inBoundFields}
          commsFields={commsFields}
          onChangeSignal={onChangeSignal}
          liveshotStatus={liveshotStatus}
          onAddSignal={onAddSignal}
          outBoundFields={outBoundFields}
          setOutBoundFields={setOutBoundFields}
          confirmDuplicateRoute={confirmDuplicateRoute}
          checkForDuplicateLocalPaths={checkForDuplicateLocalPaths}
          updateInfo={updateInfo}
          hideDropdownOnClickOutside={hideDropdownOnClickOutside}
          isWindowTimeInvalid={isWindowTimeInvalid}
        />
      </div>
      <CommsSignals
        isDisable={isDisable}
        liveShotId={liveShotId}
        formInfo={formInfo}
        abstractLiveshotId={abstractLiveshotId}
        userId={userId}
        userFullName={userFullName}
        deleteSignal={deleteSignal}
        currentRoom={currentRoom}
        onAddSignal={onAddSignal}
        inBoundFields={inBoundFields}
        outBoundFields={outBoundFields}
        onResetAllPin={onResetAllPin}
        onChangeSignal={onChangeSignal}
        liveshotStatus={liveshotStatus}
        ifbPin={ifbPin}
        plPin={plPin}
        phoPin={phoPin}
        onChangePinFromSignals={onChangePinFromSignals}
        defaultCommSignals={defaultCommSignals}
        resetDefaultCommSignal={resetDefaultCommSignal}
        windowEndTime={windowEndTime}
        customIndexNum={customIndexNum}
        setCustomIndexNum={setCustomIndexNum}
        commsFields={commsFields}
        setCommsFields={setCommsFields}
        confirmDuplicateRoute={confirmDuplicateRoute}
        checkForDuplicateLocalPaths={checkForDuplicateLocalPaths}
        updateInfo={updateInfo}
        hideDropdownOnClickOutside={hideDropdownOnClickOutside}
        isWindowTimeInvalid={isWindowTimeInvalid}
      />
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.accessToken,
    currentUser: state.auth.currentUser,
    currentCanvasId: state.canvasInfo.currentCanvasId,
    currentCanvas: state.canvasInfo.currentCanvas,
    controlRoomList: state.canvasInfo.controlRoomList,
    outBoundGlobalPath: state.signalsInfo.outBoundGlobalPath,
    plCRPath: state.signalsInfo.plCRPath,
    loading: state.routerSourceDestination.loading,
    routerSources: state.routerSourceDestination.routerSources,
    liveShotSignalSelected: state.signalsInfo.liveShotSignalSelected,
    userList: state.user.userList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchSelectedSignal: (token, liveshotId, canvasId) =>
      dispatch(actions.fetchSelectedSignal(token, liveshotId, canvasId)),
    onCreateLiveShotSignal: (
      token,
      lsId,
      ablsId,
      lsSignalTypeId,
      userId,
      room,
      venueType
    ) =>
      dispatch(
        actions.createLiveShotSignal(
          token,
          lsId,
          ablsId,
          lsSignalTypeId,
          userId,
          room,
          venueType
        )
      ),
    onDeleteLiveShotSignal: (token, lsSignalId, normalLsSignalId, room) =>
      dispatch(
        actions.deleteLiveShotSignal(token, lsSignalId, normalLsSignalId, room)
      ),
    onCreateLiveShotSignalWithValue: (
      token,
      lsId,
      ablsId,
      lsSignalTypeId,
      userId,
      room,
      crPathValue,
      globalPathName
    ) =>
      dispatch(
        actions.createLiveShotSignalWIthValue(
          token,
          lsId,
          ablsId,
          lsSignalTypeId,
          userId,
          room,
          crPathValue,
          globalPathName
        )
      ),
    fetchRouterDestinations: (token) =>
      dispatch(actions.getRouterDestinations(token)),
    onUpdateLiveShotSignals: (
      token,
      signalInfo,
      signalInfoObj,
      room,
      updateCommGlobal = false,
      needUpdate = false
    ) =>
      dispatch(
        actions.updateLiveShotSignals(
          token,
          signalInfo,
          signalInfoObj,
          room,
          updateCommGlobal,
          needUpdate
        )
      ),
    unOwnLiveshotSignals: (
      token,
      liveshotSignalId,
      field,
      pathKey,
      currentRoom,
      isDuplicate = false,
      grabParams = {}
    ) =>
      dispatch(
        actions.unOwnRoute(
          token,
          liveshotSignalId,
          field,
          pathKey,
          currentRoom,
          isDuplicate,
          grabParams
        )
      ),
    onTakeAndGrab: (
      token,
      control,
      userId,
      userFullName,
      abstractSignalInfo,
      normalSignalInfo,
      liveshotId,
      roomId,
      currentRoom,
      globalPathRoomId
    ) =>
      dispatch(
        actions.takeAndGrab(
          token,
          control,
          userId,
          userFullName,
          abstractSignalInfo,
          normalSignalInfo,
          liveshotId,
          roomId,
          currentRoom,
          globalPathRoomId
        )
      ),
    showCommonPopupModal: (modalDetail) =>
      dispatch(actions.showCommonPopupModal(modalDetail)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LiveShotSignals);
