import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { DebounceInput } from "react-debounce-input";
import * as actions from "../../../store/actions/index";
import Tooltip from "../../shared/UI/Tooltip/Tooltip";
import { removeDuplicates } from "../../shared/utility";
import DropdownSuggestions from "../../dropdownSuggestions/DropdownSuggestions.js";
import "./LiveShotSignals.css";
import { ReactComponent as InBoundPlusIcon } from "../../../assets/icons/inbound-add-outline.svg";
import { ReactComponent as ExtendPlusIcon } from "../../../assets/icons/extend-plus-icon.svg";
import { ReactComponent as MinusLogo } from "../../../assets/icons/remove-icon-outline.svg";
import { ReactComponent as InfoLiveShotOutlineLogo } from "../../../assets/icons/Info-liveshots-outline.svg";
import { getPathClassName } from "../../shared/utility";
import { CustomInput } from "../../shared/UI/Input/CustomInput.js";
import {
  SignalInput,
  RadioInput,
  SignalDropdown,
  AutoComplete,
} from "../../shared/UI/index.js";

const InboundSignals = (props) => {
  const {
    isDisable,
    liveShotId,
    formInfo,
    abstractLiveshotId,
    isArmRouting,
    onUpdateLiveShotSignals,
    onUpdateNormalLiveShotSignals,
    userId,
    userFullName,
    onCreateExtendLiveShotSignal,
    inBoundCRPath,
    inBoundGlobalPath,
    deleteSignal,
    inBoundExtendGlobalPath,
    currentRoom,
    outBoundFields,
    commsFields,
    liveShotSignalSelected,
    triggerAvailVcInitState,
    routerSources,
    userList,
    token,
    currentCanvas,
    onChangeSignal,
    onTakeAndGrab,
    liveshotStatus,
    onRemoveRedBorder,
    onCheckAvailableSignals,
    onCheckOwnership,
    redBorder,
    sharedInBoundGlobalPath,
    availInBoundSharedSignals,
    studioTruckTypes,
    showDuplicateLocalPathModal,
    setOutBoundForInBound,
    onAddSignal,
    setCustomIndexNum,
    availSignal,
    customIndexNum,
    inBoundFields,
    setInBoundFields,
    splitOutBoundSignalValue,
    confirmDuplicateRoute,
    checkForDuplicateLocalPaths,
    updateInfo,
  } = props;

  let inbound_classes = [];
  let inbound_glpath_classes = [];

  const [isUpdating, setIsUpdating] = useState(false);
  const [inBoundFieldsNormal, setInBoundFieldsNormal] = useState([]);
  const [autoAddInBoundSignal, setAutoAddInBoundSignal] = useState(true);
  const [type, setStype] = useState(0);
  const [rows, setRows] = useState([]);

  const venueTypeMenu = useRef([]);
  const availVCTypeMenu = useRef([]);

  let availVcOptions = [
    { id: 1, name: "SKYPE", globalPath: "SKYPE" },
    { id: 2, name: "QUICKLINK", globalPath: "QLINK" },
    { id: 3, name: "WEB / OTHER", globalPath: "WEB" },
  ];

  useEffect(() => {
    const inboundArray = [];
    const inbound = removeDuplicates(liveShotSignalSelected).filter(
      (signal) =>
        signal.abstractLiveshotId === abstractLiveshotId &&
        signal.liveshotId === liveShotId &&
        signal.liveshotSignalTypeId === 1
    );
    for (let key in inbound) {
      inboundArray.push(inbound[key].liveshotSignalId);
    }

    setInBoundFields(inbound);
    updateRows(inbound);
    setInBoundFieldsNormal(
      removeDuplicates(liveShotSignalSelected).filter(
        (signal) =>
          signal.liveshotId === liveShotId &&
          signal.abstractLiveshotId === 0 &&
          inboundArray.includes(signal.abstractLiveshotSignalId)
      )
    );
  }, [liveShotSignalSelected, liveShotId, abstractLiveshotId]);

  useEffect(() => {
    if (
      inBoundFields.length > 0 &&
      formInfo &&
      formInfo.abstractLiveshot.windowEndTime &&
      !isUpdating
    ) {
      for (let key in inBoundFields) {
        const signal = inBoundFields[key];
        if (
          signal.globalPathName &&
          sharedInBoundGlobalPath.includes(signal.globalPathName)
        ) {
          onCheckOwnership(token, signal, formInfo, currentCanvas);
        } else {
          onRemoveRedBorder(signal.liveshotSignalId);
        }
      }
    }
  }, [
    token,
    inBoundFields,
    sharedInBoundGlobalPath,
    onCheckOwnership,
    formInfo,
    onRemoveRedBorder,
    currentCanvas,
    isUpdating,
  ]);

  useEffect(() => {
    let abSignals = [];
    inBoundFields.forEach((fields) => {
      let crPathNameNormal = inBoundFieldsNormal.find(
        (inboundNormal) =>
          inboundNormal.abstractLiveshotSignalId === fields.liveshotSignalId
      );
      if (crPathNameNormal === undefined) {
        abSignals.push(fields);
      }
    });
    if (abSignals.length > 0 && autoAddInBoundSignal && isDisable) {
      setAutoAddInBoundSignal(false);
    }
  }, [
    token,
    isDisable,
    inBoundFieldsNormal,
    inBoundFields,
    liveShotId,
    userId,
    autoAddInBoundSignal,
    currentRoom,
  ]);

  useEffect(() => {
    if (inBoundFields) {
      let values = inBoundFields.filter((signal) => {
        return (
          signal.liveshotSignalTypeId === 1 &&
          inBoundGlobalPath.includes(signal.globalPathName)
        );
      });
      if (values.length > 0) {
        let pathName = values[0].globalPathName;
        let outBoundValue = splitOutBoundSignalValue(pathName);
        setOutBoundForInBound(outBoundValue[0]);
      } else {
        setOutBoundForInBound("");
      }
    }
  }, [inBoundFields]);

  useEffect(() => {
    let updatedRows = [...rows];
    if (customIndexNum !== null && availSignal) {
      triggerAvailVcInitState();
      updatedRows[customIndexNum].globalPathName = availSignal.signal;
      updatedRows[customIndexNum] = {
        ...updatedRows[customIndexNum],
        isShowVenueMenu: false,
        globalPathName: availSignal.signal,
        showAvailVcMenu: false,
      };
      setRows(updatedRows);
    }
  }, [availSignal]);

  const getSignalList = (signalIds = [2, 5, 7, 8, 9]) => {
    let signalList = [];
    signalList = removeDuplicates(liveShotSignalSelected).filter((signal) =>
      signalIds.includes(signal.liveshotSignalTypeId)
    );
    return signalList;
  };

  const handleUseRadioClick = (field, crPath) => {
    let updatedRows = [...rows];
    let inboundSignalList = getSignalList([1]);
    let isDuplicate = checkForDuplicateLocalPaths(
      field,
      inboundSignalList,
      "crPathName"
    );
    if (updatedRows.length > 0) {
      if (!isDuplicate) {
        onTakeAndGrab(
          token,
          "take",
          userId,
          userFullName,
          field,
          crPath,
          liveShotId,
          currentCanvas.roomId,
          currentRoom
        );
      } else {
        field.isDuplicate = true;
        field.confirmDuplicateRoute = confirmDuplicateRoute;
        field.crPath = crPath;
        field.btnType = "take";
        showDuplicateLocalPathModal(field);
      }
    }
  };

  const changeInBoundSignal = (i, event, normalLiveshotSignalId = null) => {
    setIsUpdating(true);
    const values = [...inBoundFields];
    values[i].updatedBy = parseInt(userId);
    values[i].roomId = currentRoom.roomId;
    values[i][event.target.name] = event.target.value;
    let updatedRows = [...rows];

    // Local path onchange event Inbound
    if (event.target.name === "crPathName") {
      if (event.target.value !== "") {
        values[i].crSuggest = "true";
        updatedRows[i] = {
          ...updatedRows[i],
          crPathName: event.target.value,
        };
      } else {
        values[i].crSuggest = "false";
        updatedRows[i] = {
          ...updatedRows[i],
          isShowVenueMenu: false,
          crPathName: event.target.value,
          routed: false,
        };
        onUpdateLiveShotSignals(
          token,
          values[i],
          { crPathName: event.target.value, routed: false },
          currentRoom
        );
      }
      setRows(updatedRows);
      setInBoundFields(values);
      values[i].crPathNameSelected = "false";
    }

    // Global path onchange event Inbound
    if (event.target.name === "globalPathName") {
      if (event.target.value !== "") {
        values[i].globalSuggest = "true";
        updatedRows[i] = {
          ...updatedRows[i],
          globalPathName: event.target.value,
        };
      } else {
        values[i].globalSuggest = "false";
        updatedRows[i] = {
          ...updatedRows[i],
          isShowVenueMenu: false,
          globalPathName: event.target.value,
          routed: false,
        };
        onUpdateLiveShotSignals(
          token,
          values[i],
          { globalPathName: event.target.value, routed: false },
          currentRoom
        );
      }
      setRows(updatedRows);
      setInBoundFields(values);
      values[i].globalPathNameSelected = "false";
    }

    if (event.target.name === "availcheck") {
      if (event.target.value === "false") {
        values[i].availCheck = "true";
        onCheckAvailableSignals(
          token,
          sharedInBoundGlobalPath,
          formInfo,
          currentCanvas,
          "InBound"
        );
      } else {
        values[i].availCheck = "false";
      }
    }

    if (
      event.target.name === "pathName" ||
      event.target.name === "transmissionPathName"
    ) {
      if (event.target.value !== "") {
        values[i].pathNameSelected = "true";
      }
      if (
        !values[i].uniLateral &&
        event.target.name === "transmissionPathName"
      ) {
        const normalObj = {};
        normalObj.liveshotSignalId = normalLiveshotSignalId;
        normalObj.transmissionPathName = event.target.value;
        normalObj.roomId = currentRoom.roomId;
        normalObj.updatedBy = parseInt(userId);
        onUpdateNormalLiveShotSignals(token, normalObj, currentRoom);
      } else {
        onUpdateLiveShotSignals(token, values[i], currentRoom);
      }
    }
    setInBoundFields(values);
    onChangeSignal(inBoundFields, outBoundFields, commsFields);
  };

  const setSignals = (signal, i, signalName) => {
    const inBoundValues = [...inBoundFields];

    if (signalName === "crPathInBound") {
      inBoundValues[i].crPathName = signal;
      inBoundValues[i].updatedBy = parseInt(userId);
      inBoundValues[i].crSuggest = "false";
      inBoundValues[i].crPathNameSelected = "true";
      inBoundValues[i].crKey = !!inBoundValues[i].crKey;
      //signal local
      rows[i].crPathName = signal;
      inBoundValues[i].crPathName = signal;
      rows[i].routed = false;
      inBoundValues[i].routed = false;
      setInBoundFieldsNormal(inBoundValues);
      const normalObj = {};
      normalObj.liveshotSignalId = inBoundValues[i].liveshotSignalId;
      normalObj.roomId = currentRoom.roomId;
      normalObj.updatedBy = parseInt(userId);
      normalObj.crPathName = signal;
      setInBoundFields(inBoundValues, rows[i].crPathName);
      onUpdateLiveShotSignals(
        token,
        inBoundValues[i],
        { crPathName: rows[i].crPathName, routed: false },
        currentRoom
      );
    }
    if (signalName === "globalPathInBound") {
      inBoundValues[i].updatedBy = parseInt(userId);
      inBoundValues[i].globalPathName = signal;
      inBoundValues[i].globalSuggest = "false";
      inBoundValues[i].globalPathNameSelected = "true";
      inBoundValues[i].globalKey = !!inBoundValues[i].globalKey;
      inBoundValues[i].roomId = currentRoom.roomId;
      rows[i].globalPathName = signal;
      rows[i].routed = false;
      inBoundValues[i].routed = false;
      setInBoundFields(inBoundValues, rows[i].globalPathName);
      onUpdateLiveShotSignals(
        token,
        inBoundValues[i],
        { globalPathName: rows[i].globalPathName, routed: false },
        currentRoom
      );
    }

    onChangeSignal(inBoundFields, outBoundFields, commsFields);
  };

  const onChangeType = (e) => {
    setStype(e.target.value);
  };

  const onChangeAvailVc = (e, idx) => {
    // var a = document.querySelectorAll("li.ul-list-item");
    // for (var i = 0; i < a.length; i++) {
    //   a[i].classList.remove("list-item-bg");
    // }
    // e.target.classList.add("list-item-bg");
    let availVcValue = e.target.value;

    let values = [...inBoundFields];
    values[idx].type = availVcValue;

    let updatedRows = [...rows];
    updatedRows[idx] = {
      ...updatedRows[idx],
      showAvailVcMenu: false,
    };
    setRows(updatedRows);
    onUpdateLiveShotSignals(
      token,
      values[idx],
      { type: availVcValue },
      currentRoom
    );
    setInBoundFields(values);
    triggerAvailVcInitState();
    setCustomIndexNum(idx);
  };

  const onChangeVenueType = (e, idx) => {
    // var a = document.querySelectorAll("li.ul-list-item");
    // for (var i = 0; i < a.length; i++) {
    //   a[i].classList.remove("list-item-bg");
    // }
    // e.target.classList.add("list-item-bg");
    let venuetypeValue = e.target.value;

    let values = [...inBoundFields];
    values[idx].venueType = venuetypeValue;
    values[idx].updatedBy = parseInt(userId);

    let payloadObj = { venueType: venuetypeValue };

    if (venuetypeValue !== "VIDEO CONFERENCE") {
      let availVc = "";
      payloadObj.type = availVc;
      values[idx].type = availVc;
    }

    let updatedRows = [...rows];
    updatedRows[idx] = {
      ...updatedRows[idx],
      isShowVenueMenu: false,
      venueTypePlaceholder: "",
      venueType: venuetypeValue,
    };
    setRows(updatedRows);

    onUpdateLiveShotSignals(token, values[idx], payloadObj, currentRoom);
    setInBoundFields(values);
    onChangeSignal(values, outBoundFields, commsFields);
  };

  const onChangeInboundLabel = (e, idx) => {
    let values = [...inBoundFields];
    let targetValue = e.target.value;
    values[idx].updatedBy = parseInt(userId);
    values[idx].label = targetValue;

    let updatedRows = [...rows];
    updatedRows[idx] = {
      ...updatedRows[idx],
      isShowVenueMenu: false,
      label: targetValue,
    };
    setRows(updatedRows);
    onUpdateLiveShotSignals(
      token,
      values[idx],
      { label: targetValue },
      currentRoom
    );
    setInBoundFields(values);
  };

  const onChangeInboundTx = (e, idx) => {
    let values = [...inBoundFields];
    values[idx].tx = e.target.value;
    values[idx].updatedBy = parseInt(userId);
    let updatedRows = [...rows];
    updatedRows[idx] = {
      ...updatedRows[idx],
      isShowVenueMenu: false,
      tx: e.target.value,
    };
    setRows(updatedRows);
    onUpdateLiveShotSignals(
      token,
      values[idx],
      { tx: e.target.value },
      currentRoom
    );
    setInBoundFields(values);
    onChangeSignal(values, outBoundFields, commsFields);
  };

  const hideAvailVcMenu = (idx) => {
    let updatedRows = [...rows];
    let availPlaceholder = "SELECT";

    let showAvailVcMenuValue = updatedRows[idx].showAvailVcMenu;

    updatedRows[idx] = {
      ...updatedRows[idx],
      showAvailVcMenu: !showAvailVcMenuValue,
      availPlaceholder: availPlaceholder,
    };

    setRows(updatedRows);
  };

  const hideVenueTypes = (idx) => {
    let updatedRows = [...rows];
    let venueTypePlaceholder = "SELECT";

    let isShowVenueMenuValue = updatedRows[idx].isShowVenueMenu;
    updatedRows[idx] = {
      ...updatedRows[idx],
      isShowVenueMenu: !isShowVenueMenuValue,
      venueTypePlaceholder: venueTypePlaceholder,
    };

    setRows(updatedRows);
  };

  const addRow = () => {
    const newRow = {
      isShowVenueMenu: false,
      showAvailVcMenu: false,
      isUseSelected: false,
      isUseHovered: false,
      routed: false,
      availPlaceholder: "SELECT",
      venueTypePlaceholder: "SELECT",
    };
    setRows([...rows, newRow]);
  };

  const removeInBoundRow = (idx) => {
    let updatedRows = [...rows];
    let inBoundFieldsRows = [...inBoundFields];

    updatedRows.splice(idx, 1);
    inBoundFieldsRows.splice(idx, 1);

    setInBoundFields(inBoundFieldsRows);
    setRows(updatedRows);
  };

  const updateRows = (inbound) => {
    let updatedRows = [...rows];
    inbound &&
      inbound.map((item, index) => {
        if (item.venueType) {
          updatedRows[index] = {
            ...updatedRows[index],
            isShowVenueMenu: false,
            availPlaceholder:
              rows[index] && rows[index].availVc
                ? rows[index].availVc
                : "SELECT",
            venueTypePlaceholder: "SELECT",
            label: item.label,
            tx: item.tx,
            globalPathName: item.globalPathName,
            crPathName: item.crPathName,
            transmissionPathName: item.transmissionPathName,
            routed: item.routed,
          };
        } else {
          updatedRows[index] = {
            ...updatedRows[index],
            isShowVenueMenu: false,
            venueTypePlaceholder: "SELECT",
            label: item.label,
            tx: item.tx,
            globalPathName: item.globalPathName,
            crPathName: item.crPathName,
            transmissionPathName: item.transmissionPathName,
            routed: item.routed,
          };
        }
      });

    setRows(updatedRows);
  };

  let routerSourcesObj = [];
  routerSources.forEach((rs) => {
    if (rs.namesetName.includes("SIP")) {
      if (!routerSourcesObj.includes(rs.namesetName)) {
        routerSourcesObj.push(rs.namesetName.replace("SIP ", ""));
      }
    }
  });

  const tableHeaders = [
    {
      className: "empty-header",
    },
    { className: "venueTypeHeaderCell", value: "Venue / VC Type" },
    { className: "availVcHeaderCell", value: "Avail VC" },
    { className: "labelHeaderCell", value: "Label" },
    { className: "txpath", value: "Tx" },
    { className: "globalpath", value: "Global" },
    { className: "localHeaderCell", value: "Local" },
    { className: "extendHeaderCell", value: "Extend" },
    { className: "info-col" },
    { className: "useHeaderCell", value: "Use" },
    { className: "ownHeaderCell", value: "Own" },
  ];

  return (
    <div className="receives">
      <div className="signals-heading">
        {!isDisable && liveShotId !== 0 && (
          <div
            type="button"
            btnType="inbound-create-btn"
            data-testid="inbound-create-btn"
            title="Add new Liveshot Signal"
            onClick={() => {
              setAutoAddInBoundSignal(false);
              onAddSignal(
                token,
                liveShotId,
                abstractLiveshotId,
                1,
                userId,
                currentRoom,
                "addInbound"
              );
              addRow();
            }}
          >
            <InBoundPlusIcon className="inboundPlusIcon" alt="Plus Logo" />
          </div>
        )}
        <div className="align-label" />
        <label>INBOUND PATHS</label>
      </div>
      <div className="inboundTableContainer">
        <table className="table table-borderless inboundTable">
          <thead>
            <tr className="inboundTableHeaderRow">
              {tableHeaders.map((header) => {
                return (
                  <th
                    scope="col"
                    key={header.className}
                    className={header.className}
                  >
                    {header.value}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {inBoundFields.map((field, idx) => {
              let crPathListInBound = null;
              let globalPathListInBound = null;
              let filteredCRInBound = null;
              let crPath = inBoundFieldsNormal.find(
                (inboundNormal) =>
                  inboundNormal.abstractLiveshotSignalId ===
                  field.liveshotSignalId
              );
              let localPath = rows[idx].crPathName;
              let globalPath = rows[idx].globalPathName;
              let localPathNameError = getPathClassName(field.crPathNameError);
              let globalPathNameError = getPathClassName(
                field.globalPathNameError
              );
              let isInboundUseDisabled = !(
                isArmRouting &&
                ["READY FOR AIR", "READY FOR PCR"].includes(liveshotStatus) &&
                localPath &&
                globalPath &&
                localPathNameError.trim() !== "path-error" &&
                globalPathNameError.trim() !== "path-error"
              );

              if (field.crSuggest === "true") {
                filteredCRInBound = inBoundCRPath.filter((crPath) => {
                  return crPath
                    .toLowerCase()
                    .includes(field.crPathName.toLowerCase());
                });
                if (filteredCRInBound.length > 0) {
                  crPathListInBound = filteredCRInBound;
                }
              }

              if (field.globalSuggest === "true") {
                let globalPathArray = inBoundExtendGlobalPath;
                if (field.uniLateral) {
                  globalPathArray = inBoundGlobalPath;
                }
                let filteredGlobalInBound = globalPathArray.filter(
                  (globalPath) => {
                    return globalPath
                      .toLowerCase()
                      .includes(field.globalPathName.toLowerCase());
                  }
                );
                if (field.availCheck === "true") {
                  filteredGlobalInBound = availInBoundSharedSignals.filter(
                    (globalPath) => {
                      return globalPath
                        .toLowerCase()
                        .includes(field.globalPathName.toLowerCase());
                    }
                  );
                }
                if (filteredGlobalInBound.length > 0) {
                  globalPathListInBound = filteredGlobalInBound;
                }
              }
              if (!isDisable) {
                inbound_classes = ["globalpathField", "disable-input"];
              }

              let inBoundCrPathInfo = null;
              let crTitle = null;
              if (crPath) {
                inBoundCrPathInfo = inBoundCRPath.find(
                  (item) => item === crPath.crPathName
                );
              }

              let isRedBorder = null;
              if (redBorder.length > 0) {
                isRedBorder = redBorder.find(
                  (item) => item.liveshotSignalId === field.liveshotSignalId
                );
              }

              let inBoundGlobalPathInfo = null;
              let globalTitle = null;
              if (field) {
                if (field.uniLateral) {
                  inBoundGlobalPathInfo = inBoundGlobalPath.find(
                    (item) => item === field.globalPathName
                  );
                } else {
                  if (crPath) {
                    inBoundGlobalPathInfo = inBoundExtendGlobalPath.find(
                      (item) => item === crPath.globalPathName
                    );
                  }
                }
              }

              if (!inBoundCrPathInfo && crPath) {
                crTitle = "Field is empty";
                if (crPath.crPathName !== "") {
                  crTitle = "Name not found in signal list";
                }
              } else if (!isDisable) {
                inbound_classes = ["globalpathField"];
              } else {
                inbound_classes = ["disable-input"];
              }
              if (!inBoundGlobalPathInfo) {
                globalTitle = "Field is empty";
                if (field.globalPathName) {
                  globalTitle = "Name not found in signal list";
                }
              } else if (
                formInfo &&
                !formInfo.abstractLiveshot.windowEndTime &&
                sharedInBoundGlobalPath.includes(inBoundGlobalPathInfo)
              ) {
                inbound_glpath_classes = ["red-border"];
                globalTitle = "Window-end must be set for shared resources";
              } else if (isRedBorder) {
                inbound_glpath_classes = ["red-border"];
                let date = new Date(isRedBorder.controlTime);
                let hours = "" + date.getHours();
                if (hours.length < 2) hours = "0" + hours;
                let minutes = "" + date.getMinutes();
                if (minutes.length < 2) minutes = "0" + minutes;
                let endDate = new Date(isRedBorder.windowEndTime);
                let endHours = "" + endDate.getHours();
                if (endHours.length < 2) endHours = "0" + endHours;
                let endMinutes = "" + endDate.getMinutes();
                if (endMinutes.length < 2) endMinutes = "0" + endMinutes;
                let userName;
                const userNameList = userList.find(
                  (element) => element.sso === parseInt(isRedBorder.updatedBy)
                );
                if (userNameList !== undefined) {
                  userName = userNameList.userName;
                } else {
                  userName = parseInt(isRedBorder.updatedBy);
                }
                globalTitle =
                  "This signal is reserved by " +
                  userName +
                  " for " +
                  isRedBorder.liveshotName +
                  " between " +
                  hours +
                  ":" +
                  minutes +
                  "-" +
                  endHours +
                  ":" +
                  endMinutes;
              } else if (!isDisable) {
                inbound_glpath_classes = ["globalpathField"];
              } else {
                inbound_glpath_classes = ["disable-input"];
              }

              return (
                <tr
                  key={`${field.liveshotSignalId}-${idx}`}
                  className={field.liveshotSignalId}
                >
                  <td>
                    {!isDisable && (
                      <div
                        type="button"
                        btnType="circle_btn"
                        title="Remove Liveshot Signal"
                        onClick={() => {
                          deleteSignal(
                            field.liveshotSignalId,
                            crPath ? crPath.liveshotSignalId : null
                          );
                          removeInBoundRow(idx);
                        }}
                      >
                        <MinusLogo alt="Minus Logo" className="minusIcon" />
                      </div>
                    )}
                  </td>
                  <td
                    className={`venueTypeCell ${isDisable ? "restricted" : ""}`}
                  >
                    <SignalDropdown
                      onChange={(e) => {
                        let event = {
                          target: { name: "venueType", value: e.label },
                        };
                        onChangeVenueType(event, idx);
                        changeInBoundSignal(idx, event);
                      }}
                      options={studioTruckTypes.map((item) => ({
                        label: item.studioTypeName,
                        value: item.studioTypeId,
                        key: item.studioTypeId,
                      }))}
                      disabled={isDisable}
                      placeholder={
                        rows && rows[idx] && rows[idx].venueTypePlaceholder
                      }
                      value={field.venueType || ""}
                    />
                  </td>
                  <td
                    className={`availVcCell ${isDisable ? "restricted" : ""}`}
                  >
                    <SignalDropdown
                      onChange={(e) => {
                        let event = {
                          target: { name: "availvc", value: e.label },
                        };
                        onChangeAvailVc(event, idx);
                      }}
                      options={availVcOptions.map((item) => ({
                        label: item.name,
                        value: item.name,
                        key: item.name,
                      }))}
                      disabled={
                        isDisable || field.venueType !== "VIDEO CONFERENCE"
                      }
                      placeholder={
                        field.venueType === "VIDEO CONFERENCE"
                          ? "SELECT"
                          : "N/A"
                      }
                      value={field.type || ""}
                    />
                  </td>
                  <td className="labelCell">
                    <SignalInput
                      name="pathName"
                      value={rows[idx] ? rows[idx].label : ""}
                      disabled={isDisable}
                      onChange={(e) => onChangeInboundLabel(e, idx)}
                    />
                  </td>
                  <td className="labelCell">
                    <SignalInput
                      name="transmissionPathName"
                      value={rows[idx].tx}
                      onChange={(e) => onChangeInboundTx(e, idx)}
                      disabled={isDisable}
                    />
                  </td>

                  <td className="globalpathCell labelCell">
                    {field.uniLateral && (
                      <AutoComplete
                        placeholder="ENTER"
                        name="globalPathName"
                        key={`globalPathName${field.globalKey}`}
                        className={`inboundOutboundInputFields globalpathField`}
                        patherr={getPathClassName(field.globalPathNameError)}
                        value={rows[idx].globalPathName || ""}
                        onSearch={(val) => {
                          changeInBoundSignal(
                            idx,
                            { target: { name: "globalPathName", value: val } },
                            crPath ? crPath.liveshotSignalId : ""
                          );
                        }}
                        onSelect={(e) =>
                          setSignals(e, idx, "globalPathInBound")
                        }
                        options={globalPathListInBound}
                        disabled={isDisable}
                      />
                    )}

                    {!field.uniLateral && (
                      <>
                        <AutoComplete
                          placeholder="ENTER"
                          name="globalPathName"
                          key={`globalPathName${field.globalKey}`}
                          autoComplete="off"
                          className={`inboundOutboundInputFields globalpathField ${inbound_glpath_classes.join(
                            ""
                          )}`}
                          patherr={getPathClassName(field.globalPathNameError)}
                          value={rows[idx].globalPathName || ""}
                          onSearch={(val) => {
                            changeInBoundSignal(
                              idx,
                              {
                                target: { name: "globalPathName", value: val },
                              },
                              crPath ? crPath.liveshotSignalId : ""
                            );
                          }}
                          onSelect={(e) =>
                            setSignals(e, idx, "globalPathInBound")
                          }
                          options={globalPathListInBound}
                          disabled={isDisable}
                        />
                      </>
                    )}
                  </td>
                  <td className="localCell labelCell">
                    <AutoComplete
                      placeholder="ENTER"
                      name="crPathName"
                      key={`crPathName${field.crKey}`}
                      autoComplete="off"
                      className={`inboundOutboundInputFields globalpathField ${inbound_glpath_classes.join(
                        ""
                      )}`}
                      patherr={getPathClassName(field.crPathNameError)}
                      value={rows[idx].crPathName || ""}
                      onSearch={(val) => {
                        changeInBoundSignal(
                          idx,
                          {
                            target: { name: "crPathName", value: val },
                          },
                          crPath ? crPath.liveshotSignalId : ""
                        );
                      }}
                      onSelect={(e) => setSignals(e, idx, "crPathInBound")}
                      options={crPathListInBound}
                      disabled={isDisable}
                    />
                  </td>
                  <td
                    className={
                      !field.uniLateral ? "extendCellLocal" : "extendCell"
                    }
                  >
                    {field.uniLateral && (
                      <div className="extendiv">
                        {!isDisable && liveShotId !== 0 && (
                          <div
                            type="button"
                            btnType="circle_btn"
                            title="Extend signal-path to include local CR resource"
                            onClick={() =>
                              onCreateExtendLiveShotSignal(
                                token,
                                liveShotId,
                                abstractLiveshotId,
                                1,
                                userId,
                                currentRoom
                              )
                            }
                          >
                            <ExtendPlusIcon
                              className="extend"
                              alt="Plus Logo"
                            />
                          </div>
                        )}
                      </div>
                    )}
                    {!field.uniLateral && (
                      <span className="textlocal">local</span>
                    )}
                  </td>

                  <td className="inbound-info-cell">
                    <Tooltip
                      message={updateInfo(field)}
                      position={"bottom"}
                      title="Update Info"
                      messageTheme="light-theme"
                      titleTheme="light-theme"
                    >
                      <InfoLiveShotOutlineLogo
                        className="update-info-icon"
                        alt="info"
                      />
                    </Tooltip>
                  </td>
                  <td className="use-radio-input-cell">
                    <RadioInput
                      onClick={() => {
                        handleUseRadioClick(field, crPath);
                      }}
                      disabled={isDisable || isInboundUseDisabled}
                      checked={rows[idx] && rows[idx].routed}
                      isDisabled={isInboundUseDisabled}
                    />
                  </td>
                  <td className="own-radio-input-cell">
                    <span className="ownRadio">
                      <div className="ownRadioDiv" aria-hidden="true"></div>
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.accessToken,
    currentCanvas: state.canvasInfo.currentCanvas,
    inBoundCRPath: state.signalsInfo.inBoundCRPath,
    inBoundGlobalPath: state.signalsInfo.inBoundGlobalPath,
    inBoundExtendGlobalPath: state.signalsInfo.inBoundExtendGlobalPath,
    liveShotSignalSelected: state.signalsInfo.liveShotSignalSelected,
    availSignal: state.signalsInfo.signal,
    userList: state.user.userList,
    isArmRouting: state.canvasInfo.armRouting,
    redBorder: state.signalsInfo.redBorder,
    sharedInBoundGlobalPath: state.signalsInfo.sharedInBoundGlobalPath,
    availInBoundSharedSignals: state.signalsInfo.availInBoundSharedSignals,
    routerSources: state.routerSourceDestination.routerSources,
    studioTruckTypes: state.studioTruckInfo.studioTruckTypes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCreateExtendLiveShotSignal: (
      token,
      lsId,
      ablsId,
      lsSignalTypeId,
      userId,
      room
    ) =>
      dispatch(
        actions.createExtendLiveShotSignal(
          token,
          lsId,
          ablsId,
          lsSignalTypeId,
          userId,
          room
        )
      ),
    onUpdateLiveShotSignals: (
      token,
      signalInfo,
      signalInfoObj,
      room,
      updateCommGlobal = false,
      needUpdate = false
    ) =>
      dispatch(
        actions.updateLiveShotSignals(
          token,
          signalInfo,
          signalInfoObj,
          room,
          updateCommGlobal,
          needUpdate
        )
      ),
    onUpdateNormalLiveShotSignals: (token, signalInfo, room) =>
      dispatch(actions.updateNormalLiveShotSignals(token, signalInfo, room)),
    onTakeAndGrab: (
      token,
      control,
      userId,
      userFullName,
      abstractSignalInfo,
      normalSignalInfo,
      liveshotId,
      roomId,
      currentRoom,
      globalPathRoomId
    ) =>
      dispatch(
        actions.takeAndGrab(
          token,
          control,
          userId,
          userFullName,
          abstractSignalInfo,
          normalSignalInfo,
          liveshotId,
          roomId,
          currentRoom,
          globalPathRoomId
        )
      ),
    onRemoveRedBorder: (signalId) =>
      dispatch(actions.removeRedBorder(signalId)),
    onCheckOwnership: (token, signal, formInfo, currentCanvas) =>
      dispatch(actions.checkOwnership(token, signal, formInfo, currentCanvas)),
    onCheckAvailableSignals: (token, signals, formInfo, currentCanvas, type) =>
      dispatch(
        actions.checkAvailableSignals(
          token,
          signals,
          formInfo,
          currentCanvas,
          type
        )
      ),
    showDuplicateLocalPathModal: (res) =>
      dispatch(actions.showSignalModal(res)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InboundSignals);
