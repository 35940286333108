import React from "react";
import Button from "../../../shared/UI/Button/Button";
import { connect } from "react-redux";
import * as actions from "../../../../store/actions/index";
import moment from "moment";
import { removeDuplicates } from "../../../shared/utility";

const ReleaseNotificationBox = (props) => {
  const {
    releaseNotification,
    removeNotification,
    token,
    onGrabFromNotification,
    currentUser,
    index,
    title,
    currentCanvas,
    liveShotSignalSelected,
  } = props;

  let userFullName = currentUser.firstName + " " + currentUser.lastName;
  let userId = currentUser.sso;
  if (process.env.REACT_APP_AUTH_ENABLED !== "true") {
    userFullName =
      process.env.REACT_APP_AUTH_OVERRIDE_FIRSTNAME +
      " " +
      process.env.REACT_APP_AUTH_OVERRIDE_LASTNAME;
    userId = process.env.REACT_APP_AUTH_OVERRIDE_ID;
  }

  const onGrabControlTracker = () => {
    const field = releaseNotification.data.liveshotSignal;
    let normalSignalInfo = removeDuplicates(liveShotSignalSelected).find(
      (signal) => signal.abstractLiveshotSignalId === field.liveshotSignalId
    );
    if (field && normalSignalInfo) {
      onGrabFromNotification(
        token,
        "grab",
        userId,
        userFullName,
        field,
        normalSignalInfo,
        normalSignalInfo.liveshotId,
        currentCanvas
      );
    }
  };

  let dateObj = releaseNotification.addedOn;
  let msgTime = moment(dateObj).fromNow();

  return (
    <div className="row grab-container">
      <div className="grab-detail" title={title}>
        <div className="grab-text">
          {msgTime} : "{releaseNotification.data.liveshotSignal.globalPathName}"
          was released from Control Room "
          {releaseNotification.data.controlRoomCode}"
        </div>
        <div className="remove-btn">
          <button
            onClick={() => removeNotification(index)}
            type="button"
            className="close"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
      <div className="button-section">
        <div>
          <Button
            onClick={onGrabControlTracker}
            type="button"
            btntype="button btns release"
          >
            OWN
          </Button>
        </div>
        <div>
          <Button
            type="button"
            btntype="button btns ignore"
            onClick={() => removeNotification(index)}
          >
            CLOSE
          </Button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.accessToken,
    currentUser: state.auth.currentUser,
    currentCanvas: state.canvasInfo.currentCanvas,
    liveShotSignalSelected: state.signalsInfo.liveShotSignalSelected,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGrabFromNotification: (
      token,
      control,
      userId,
      userFullName,
      abstractSignalInfo,
      normalSignalInfo,
      liveshotId,
      currentCanvas
    ) =>
      dispatch(
        actions.grabFromNotification(
          token,
          control,
          userId,
          userFullName,
          abstractSignalInfo,
          normalSignalInfo,
          liveshotId,
          currentCanvas
        )
      ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReleaseNotificationBox);
