import React, { useState, useEffect, useRef, forwardRef } from "react";
import { connect } from "react-redux";
import Datepicker from "react-datepicker";
import NavigationHeader from "../navigation/NavigationHeader/NavigationHeader";
import "react-datepicker/dist/react-datepicker.css";
import { ReactComponent as WarningIcon } from "../../assets/icons/Warning.svg";
import Toggle from "react-toggle";
import useMousetrap from "react-hook-mousetrap";
import "./CanvasHeader.css";
import Button from "../shared/UI/Button/Button";
import Modal from "../shared/UI/Modal/Modal";
import * as actions from "../../store/actions/index";
import Select from "../../components/shared/UI/Select/Select";
import ShowDropDown from "../modals/showdropdown/ShowDropDown";
import { dateConversion, currentDate } from "../shared/utility";
import { ReactComponent as PlusIcon } from "../../assets/icons/add-icon.svg";
import { ReactComponent as PlusIconDisabled } from "../../assets/icons/add-icon-disabled.svg";
import { ReactComponent as PrintIcon } from "../../assets/icons/print-icon.svg";
import { ReactComponent as DownloadIcon } from "../../assets/icons/download-icon.svg";

import { ReactComponent as SignatureIcon } from "../../assets/icons/signature-icon.svg";
import { ReactComponent as CopyIcon } from "../../assets/icons/copy-icon.svg";
import { ReactComponent as RefreshIcon } from "../../assets/icons/refresh-icon.svg";
import { ReactComponent as InvoiceIcon } from "../../assets/icons/invoice-icon.svg";
import { ReactComponent as KeyboardIcon } from "../../assets/icons/keyboard.svg";
import { ReactComponent as CalendarIcon } from "../../assets/icons/calendar-icon-select.svg";
import { ReactComponent as FoldIcon } from "../../assets/icons/fold-icon.svg";
import { ReactComponent as ScrollIcon } from "../../assets/icons/header-scroll.svg";
import DownloadMenu from "../shared/UI/DownloadMenu/DownloadMenu";
import Searchbox from "../../utils/searchbox/Searchbox";
import Tooltip from "../shared/UI/Tooltip/Tooltip";
import Input from "antd/es/input/Input";

import { useUser } from "../../hooks/useUser";
import * as Constants from "../shared/Constants";
import { DebounceInput } from "react-debounce-input";
import ViewRoleAs from "../rolechange/ViewRoleAs";
import { MESSAGE_TEXT } from "../../constants/PageConstants";
import { AntdTooltip } from "../../components/shared/UI/Tooltip/AntdTooltip";

import Signature_icon from "../../assets/icons/signature-icon.svg";
import Print_icon from "../../assets/icons/print-icon.svg";
import Download_icon from "../../assets/icons/download-icon.svg";
import Copy_icon from "../../assets/icons/copy-icon.svg";
import Invoice_icon from "../../assets/icons/invoice-icon.svg";

const CanvasHeader = (props) => {
  const {
    createLiveshotPerm,
    updateCanvasPerm,
    readRouterSignalDetailsPermission,
    readControlTrackerPermission,
    takeAllPerm,
    grabAllPerm,
  } = useUser();
  const {
    currentCanvas,
    currentCanvasId,
    onSelectHostStudioLiveShots,
    showList,
    onFetchShowList,
    token,
    networkList,
    currentShow,
    onCheckCanvas,
    fetchCanvasTemplate,
    onCheckCapabilityType,
    onResetAddedFromSearch,
    onResetAddedFromTemplateOrDate,
    showCreateCanvas,
    onCloseCreateCanvas,
    onUpdateCanvas,
    controlRoomList,
    onFetchControllRoomList,
    currentUser,
    currentUserRole,
    onFetchUserList,
    onSaveSelectedShowDate,
    onDownloadToExcel,
    onResetStudioTruckData,
    onResetSuccessMessage,
    newLiveshot,
    onStartLSCreation,
    onResetLSCreation,
    selectedBlockId,
    onFetchLocationSuggestion,
    onLiveShotNameSuggestions,
    onFetchCRPathForInBoundSignal,
    onFetchCRPathForOutBoundSignal,
    onFetchIFBCRPathSignal,
    onFetchPLCRPathSignal,
    onFetchPHOCRPathSignal,
    onFetchGlobalPathForInBoundSignal,
    onFetchGlobalPathExtendInBoundSignal,
    onFetchGlobalPathForOutBoundSignal,
    onFetchIFBGlobalPathSignal,
    onFetchPLGlobalPathSignal,
    onFetchPHOGlobalPathSignal,
    onFetchSharedGlobalInBound,
    onFetchSharedGlobalOutBound,
    onFetchSharedGlobalIFB,
    onFetchSharedGlobalPHO,
    onFetchSharedGlobalPHOTX,
    onFetchSharedGlobalPL,
    onFetchPHOTxCRPath,
    onFetchPHOTxGlobalPath,
    routingCapabilityType,
    onSettingArmRouting,
    onFetchFacilityList,
    onFetchNetworkList,
    shortCut,
    onToggleKeyboard,
    onRefetchSip,
    formattedRouterSource,
    sipState,
    liveShotSignalSelected,
    onUpdateLiveShotSignals,
    showCommonPopupModal,
    showNotificationBar,
    onCopyCanvas,
    canvasBlocks,
    inBoundCRPath,
    outBoundCRPath,
    ifbCRPath,
    plCRPath,
    phoCRPath,
    onTakeAllCanvasBlock,
    onGrabAllCanvasBlock,
    userFullName,
    userRole,
  } = props;

  const canvasNotesLength = Constants.CANVAS_NOTES_LENGTH;

  const [canvasTemplateSeleted, setCanvasTemplateSeleted] = useState(false);
  const [normalCanvasShowDate, setNormalCanvasShowDate] = useState("");
  const [createNewLS, setCreateNewLS] = useState(false);
  const [lsType, setLsType] = useState("");
  const [createShow, setCreateShow] = useState(false);
  const [createDropDown, setCreateDropdown] = useState(false);

  const [startDate, setStartDate] = useState(currentDate());
  const [selectedDate, setSelectedDate] = useState(currentDate());
  const [techMgr, setTechMgr] = useState({});

  const [controlRoomId, setcontrolRoomId] = useState("");
  const [controlRoomName, setControlRoomName] = useState("");
  const [canvasNotes, setcanvasNotes] = useState({});
  const [copyDate, setCopyDate] = useState("");
  const [isFold, setIsFold] = useState(false);
  const [disableDate, setDisableDate] = useState(false);
  const [disableButtons, setDisableButtons] = useState(true);
  const [notesCount, setNotesCount] = useState();
  const [isDisableNotes, setIsDisableNotes] = useState(true);
  const [showDownload, setShowDownload] = useState(false);
  const [isArmRouting, setIsArmRouting] = useState(false);
  const [showDropdownIsOpen, setShowOpenDropdown] = useState(false);
  const [isDateFieldClicked, setIsDateFieldClicked] = useState(false);
  const [controlRoomOptions, setControlRoomOptions] = useState([]);
  const [signalLoadedControlRoomId, setSignalLoadedControlRoomId] = useState(0);
  const [canvasNoteError, setCanvasNoteError] = useState(false);
  const [buttonSelected, setButtonSelected] = useState(false);
  const inputEl = useRef(null);
  const [isDisable, setIsDisable] = useState(true);
  let userId = currentUser.sso;
  if (process.env.REACT_APP_AUTH_ENABLED !== "true") {
    userId = process.env.REACT_APP_AUTH_OVERRIDE_ID;
  }
  const [currentRoom, setCurrentRoom] = useState({});

  useMousetrap(
    [
      createDropDown || newLiveshot || createShow || showCreateCanvas
        ? "esc"
        : "r",
      "r",
      "R",
      "n",
      "N",
      "b",
      "B",
      "d",
      "D",
      "a",
      "A",
      "c",
      "C",
    ],
    (e) => {
      e.preventDefault();
      if (e.key === "Escape") {
        onCloseCanvas();
        onCancelNotification();
        onCancelCreateLiveShot();
        onCancelManageShows();
        setTimeout(() => {
          inputEl.current.focus();
        }, 100);
      }
      if ((e.key === "r" || e.key === "R") && shortCut) {
        onRefresh();
      }
      if ((e.key === "n" || e.key === "N") && shortCut) {
        props.notificationShow();
      }
      if ((e.key === "b" || e.key === "B") && shortCut) {
        scrollDown();
      }
      if ((e.key === "d" || e.key === "D") && shortCut && currentCanvas) {
        onDownloadToExcel(
          token,
          currentCanvas.showId,
          currentCanvas.showDate,
          "full"
        );
      }
      if ((e.key === "a" || e.key === "A") && shortCut) {
        onCreateLiveShot();
      }
      if ((e.key === "c" || e.key === "C") && shortCut) {
        if (isFold) {
          const obj = document.getElementsByClassName("folding");
          obj[0].click();
        } else {
          onUnFoldLiveShot();
        }
      }
    }
  );

  const onChangeDate = (date) => {
    console.log(date);
    setCopyDate(null);
    const canvasdate = dateConversion(date);
    let showId = currentShow !== "" ? currentShow.showId : null;
    if (showId !== null) {
      if (canvasdate !== "1970-01-01") {
        onCheckCanvas(token, canvasdate, showId);
        onSaveSelectedShowDate(canvasdate);
      }
    }
  };

  const onClickCopyCanvasFromDate = (date) => {
    const sourceDate = dateConversion(date);
    let showId = currentShow !== "" ? currentShow.showId : null;
    let destinationDate = selectedDate.replaceAll("/", "-");
    showCommonPopupModal({
      showModal: true,
      onModalClose: () => {
        showCommonPopupModal({ showModal: false });
      },
      onConfirm: () => {
        showCommonPopupModal({ showModal: false });
        onCopyCanvas(token, showId, sourceDate, destinationDate, userId);
      },
      content: `Copy from ${sourceDate} to ${destinationDate}. Any existing data from ${destinationDate} will be lost.\n\nAre you sure you want to continue this action?`,
      title: "Warning",
      type: "confirm",
    });
  };

  const onClickCanvasFromTemplate = () => {
    if (currentShow !== "" && currentShow.showId && selectedDate) {
      let destinationDate = selectedDate.replaceAll("/", "-");
      showCommonPopupModal({
        showModal: true,
        onModalClose: () => {
          showCommonPopupModal({ showModal: false });
        },
        onConfirm: () => {
          showCommonPopupModal({ showModal: false });
          onCopyCanvas(
            token,
            currentShow.showId,
            undefined,
            destinationDate,
            userId
          );
        },
        content: `Copy from Show Template to ${destinationDate}. Any existing data from ${destinationDate} will be lost.\n\nAre you sure you want to continue this action?`,
        title: "Warning",
        type: "confirm",
      });
    }
  };

  const onPrintCanvas = () => {
    setTimeout(() => {
      window.print();
    }, 200);
  };

  const onCreateLiveShot = () => {
    if (currentCanvas) {
      onStartLSCreation(0);
      onResetStudioTruckData();
      onResetSuccessMessage();
    } else {
      alert("Please create a Canvas first and then create a LiveShot");
      return false;
    }
  };

  const onClickNotification = () => {
    setCreateDropdown(true);
  };
  const onCancelNotification = () => {
    setCreateDropdown(false);
  };

  const onCancelManageShows = () => {
    setCreateShow(false);
  };

  const onCancelCreateLiveShot = () => {
    onResetLSCreation();
  };

  const onFoldLiveShot = () => {
    setIsFold(!isFold);
    const obj = document.getElementsByClassName("fold_live_shots");
    if (obj) {
      for (let i = 0; obj.length > i; i++) {
        obj[i].click();
      }
    }
  };

  const onUnFoldLiveShot = () => {
    setIsFold(!isFold);
    const obj = document.getElementsByClassName("unfold_live_shots");
    if (obj) {
      for (let i = 0; obj.length > i; i++) {
        obj[i].click();
      }
    }
  };

  const onAddLiveShot = (event) => {
    const target = event.target.getAttribute("type");
    setLsType(target);
    setCreateNewLS(true);
    onResetLSCreation();
  };

  const onRefresh = () => {
    setIsFold(false);
    onSelectHostStudioLiveShots(currentCanvasId, token);
    onResetAddedFromSearch();
    onResetAddedFromTemplateOrDate();
    onRefetchSip(token, Object.keys(sipState), formattedRouterSource);
  };

  const scrollDown = () => {
    window.scrollTo(0, document.body.scrollHeight);
  };

  const onSelectedShow = (showId) => {
    setCreateDropdown(false);
  };

  const checkSignalOwned = () => {
    let ownedSignals = liveShotSignalSelected.filter(
      (signal) =>
        signal.routed &&
        (signal.liveshotSignalTypeId === 2 ||
          signal.liveshotSignalTypeId === 5 ||
          signal.liveshotSignalTypeId === 7 ||
          signal.liveshotSignalTypeId === 8 ||
          signal.liveshotSignalTypeId === 9)
    );
    return ownedSignals.length > 0;
  };

  const onChangeCanvasNotes = (event) => {
    const payload = {
      ...currentCanvas,
      updatedBy: parseInt(userId),
    };
    const { value: canvasNotes } = event.target;

    const isLengthInvalid = canvasNotes.length > canvasNotesLength;
    if (isLengthInvalid) {
      showCommonPopupModal({
        showModal: true,
        onModalClose: () => {
          showCommonPopupModal({ showModal: false });
        },
        title: "Info Message",
        content:
          "Canvas Notes character limit exceeded. Please reduce the amount of text and try again.",
        type: "info",
      });
      return false;
    }

    Object.assign(payload, { notes: canvasNotes });
    Object.assign(currentCanvas, { notes: canvasNotes });

    if (currentCanvas) {
      onUpdateCanvas(payload, token);
    }
  };

  const onKeyUpCanvasNotes = (event) => {
    const { name, value: canvasNotes } = event.target;
    setNotesCount({
      [name]: canvasNotes.length,
    });
    setcanvasNotes({
      ...canvasNotes,
      [name]: canvasNotes,
    });

    setCanvasNoteError(canvasNotes.length > canvasNotesLength);
  };

  const onChangeTechMgr = (event) => {
    setTechMgr({
      ...techMgr,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  };

  const formatPhonePattern = (phoneNumberString) => {
    let cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    let match = cleaned.match(/^(44|1)?(\d{3})(\d{3})(\d{4})$/);
    if (match && cleaned.length === 10) {
      let intlCode = match[1] ? `+${match[1]} ` : "";
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return phoneNumberString;
  };

  const saveTechManagerDetails = (params) => {
    const updatedTechMgr = params ? params : techMgr;
    const payload = {
      ...currentCanvas,
      ...updatedTechMgr,
      updatedBy: parseInt(userId),
    };

    if (
      updatedTechMgr.techMgrPhone !== null &&
      updatedTechMgr.techMgrPhone !== "" &&
      !isPhoneNumberPatternValid(updatedTechMgr.techMgrPhone)
    ) {
      showCommonPopupModal({
        showModal: true,
        onModalClose: () => {
          showCommonPopupModal({ showModal: false });
        },
        type: "error",
        preventClose: true,
        title: "Error",
        content: Constants.CONTACT_ERR_MESSAGE,
      });
      return false;
    }

    updatedTechMgr.techMgrPhone = formatPhonePattern(
      updatedTechMgr.techMgrPhone
    );

    Object.assign(currentCanvas, updatedTechMgr);
    if (currentCanvas) {
      onUpdateCanvas(payload, token);
    }

    onEditTechMgr();
  };

  const isPhoneNumberPatternValid = (techMgrPhone) => {
    const patternMap = {
      usPhoneNo: /^([0-9]{3})[- ]([0-9]{3})[- ]([0-9]{5})$/, // E.g: 822-591-7704, 727 428 26791
      ukPhoneNo: /^\+44 ([0-9]{4})[ ]([0-9]{6})$/, // E.g: +44 6998 288814
      tenDigitNo: /^[0-9]{10}$/, // E.g: 7939838377
      elevenDigitNo: /^[0-9]{11}$/, // E.g: 79398383777
      usPhoneNoWithAreaCodeWithoutSpace: /^((\(\d{3}\)))(\d){3}(\d){4}$/, // E.g: (800)3439718
      usPhoneNoWithAreaCodeWithoutHyphen:
        /^((\(\d{3}\)))[\s](\d){3}[-](\d){4}$/, // E.g: (800) 343-9718
    };
    let patterns = Object.values(patternMap);

    for (let pattern of patterns) {
      if (techMgrPhone.match(pattern)) return true;
    }

    return false;
  };

  const onEditTechMgr = () => {
    setIsDisableNotes(!isDisableNotes);
  };

  const getControlRoomDetails = (roomId) => {
    return controlRoomList.find((room) => room.roomId === Number(roomId));
  };

  useEffect(() => {
    setControlRoomOptions(
      controlRoomList
        .filter(
          (cr) =>
            cr.softDelete !== true &&
            cr.isCore === false &&
            cr.roomName !== "" &&
            cr.roomName !== "Default Room" &&
            cr.roomName !== null &&
            cr.roomId !== 0 &&
            cr.roomName !== null &&
            cr.isCore === false
        )
        .sort((a, b) => (a.roomName > b.roomName ? 1 : -1))
        .map((controlroom) => ({
          value: controlroom.roomId,
          key: controlroom.roomId,
          label: controlroom.roomName,
        }))
    );
  }, [controlRoomList]);

  useEffect(() => {
    setTechMgr({
      techMgrName: currentCanvas ? currentCanvas.techMgrName : "",
      techMgrPhone: currentCanvas ? currentCanvas.techMgrPhone : "",
    });
    setcanvasNotes({
      notes: currentCanvas ? currentCanvas.notes : "",
    });
    setNotesCount({
      notes:
        currentCanvas && currentCanvas.notes && currentCanvas.notes.length > 0
          ? currentCanvas.notes.length
          : 0,
    });
  }, [currentCanvas]);

  useEffect(() => {
    let showDate = canvasTemplateSeleted ? null : currentDate();
    if (currentCanvas !== null && controlRoomList.length > 0) {
      let room = getControlRoomDetails(currentCanvas.roomId);
      setCurrentRoom(room);
      setControlRoomName(room.roomName);
      setcontrolRoomId(room.roomId);
      onCheckCapabilityType(token, room);
      setDisableButtons(false);

      if (currentCanvas.showDate !== null) {
        showDate = currentCanvas.showDate.replace(/-/, "/").replace(/-/, "/");
      }
      if (showDate !== null) {
        onSaveSelectedShowDate(showDate.replaceAll("/", "-"));
      }
      if (
        room &&
        (signalLoadedControlRoomId == 0 ||
          signalLoadedControlRoomId != room.roomId)
      ) {
        setSignalLoadedControlRoomId(room.roomId);
        onFetchCRPathForInBoundSignal(token, room);
        onFetchCRPathForOutBoundSignal(token, room);
        onFetchIFBCRPathSignal(token, room);
        onFetchPLCRPathSignal(token, room);
        onFetchPHOCRPathSignal(token, room);
        onFetchGlobalPathForInBoundSignal(token, room);
        onFetchGlobalPathExtendInBoundSignal(token, room);
        onFetchGlobalPathForOutBoundSignal(token, room);
        onFetchIFBGlobalPathSignal(token, room);
        onFetchPLGlobalPathSignal(token, room);
        onFetchPHOGlobalPathSignal(token, room);
        onFetchPHOTxCRPath(token, room);
        onFetchPHOTxGlobalPath(token, room);
        onFetchSharedGlobalInBound(token, room);
        onFetchSharedGlobalOutBound(token, room);
        onFetchSharedGlobalIFB(token, room);
        onFetchSharedGlobalPHO(token, room);
        onFetchSharedGlobalPHOTX(token, room);
        onFetchSharedGlobalPL(token, room);
      }
    }
    setStartDate(showDate);
    setSelectedDate(showDate);
  }, [controlRoomList, currentCanvas]);

  useEffect(() => {
    onSettingArmRouting(isArmRouting);
  }, [isArmRouting, onSettingArmRouting]);

  const handleArmRoutingChange = (e) => {
    setIsArmRouting(e.target.checked);
    onSettingArmRouting(e.target.checked);
  };

  useEffect(() => {
    onFetchControllRoomList(token);
    onFetchFacilityList(token);
    onFetchNetworkList(token);
    onFetchLocationSuggestion(token);
    onLiveShotNameSuggestions(token);
    onFetchShowList(token);
    onFetchUserList(token);
  }, [
    onFetchControllRoomList,
    onFetchFacilityList,
    onFetchNetworkList,
    onFetchLocationSuggestion,
    onLiveShotNameSuggestions,
    onFetchShowList,
    onFetchUserList,
    token,
  ]);

  const openErrorBar = (message) => {
    showNotificationBar({ show: true, content: message, isError: true });
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const onCloseCanvas = () => {
    onCloseCreateCanvas();
  };

  const doChangeControlRoom = ({ roomId, roomCode }) => {
    setcontrolRoomId(roomId);

    const payload = {
      ...currentCanvas,
      roomId: Number(roomId),
      isRoomSelected: true,
      updatedBy: +userId,
      ...techMgr,
      notes: canvasNotes.notes,
    };
    onUpdateCanvas(payload, token, true);
    [...liveShotSignalSelected].forEach((signal, index) => {
      let crPath = signal.crPathName;
      let signalControlRoom = getControlRoomDetails(signal.roomId);
      let payload = {
        roomId: Number(roomId),
      };
      if (crPath && signalControlRoom && signalControlRoom.roomCode) {
        if (crPath.substring(0, 2) === "CR") {
          signal.crPathName = crPath.replace(
            signalControlRoom.roomCode,
            roomCode
          );
          payload.crPathName = signal.crPathName;
          payload.routed = false;
        }

        signal.updatedBy = userId;
        signal.roomId = Number(roomId);

        onUpdateLiveShotSignals(token, signal, payload, roomId, false, true);
      }
    });
  };

  const onChangeControlRoom = (e) => {
    if (!currentCanvas) return;

    if (checkSignalOwned()) {
      showCommonPopupModal({
        showModal: true,
        onModalClose: () => {
          showCommonPopupModal({ showModal: false });
        },
        content: `Control Room cannot be updated because there is an active OWN route on a Summary Row.`,
        title: "Error",
        type: "error",
      });
    } else {
      let fromControlRoomId = currentRoom.roomCode;
      let toControlRoomDetails = getControlRoomDetails(e.value);
      showCommonPopupModal({
        showModal: true,
        onModalClose: () => {
          showCommonPopupModal({ showModal: false });
        },
        onConfirm: () => {
          showCommonPopupModal({ showModal: false });
          doChangeControlRoom(toControlRoomDetails);
        },
        content: `Control room has been changed. Would you also like to update
        all ${fromControlRoomId} CR path signals to ${toControlRoomDetails.roomCode} ... for ${toControlRoomDetails.roomName}`,
        title: "Info Message",
        type: "confirm",
      });
    }
  };

  const CopyCanvasDateInput = forwardRef(({ onClick }, ref) => (
    <>
      <AntdTooltip title="COPY CANVAS FROM DATE">
        <button
          ref={ref}
          className={
            currentCanvas
              ? "invoice-icon-btn   copy-date-btn copy-date-action-btn"
              : "invoice-icon-btn-disabled   copy-date-btn copy-date-action-btn"
          }
          onClick={() => {
            if (currentCanvas) {
              setButtonSelected(true);
              onClick();
            }
          }}
        >
          <img
            className={buttonSelected ? "copy-canvas-btn-selected" : ""}
            src={Invoice_icon}
            alt="Copy"
          />
        </button>
      </AntdTooltip>
    </>
  ));

  const onModifyCanvasTemplate = () => {
    if (currentShow && currentShow != "" && currentShow.showId) {
      setCanvasTemplateSeleted(!canvasTemplateSeleted);
      setDisableDate(!canvasTemplateSeleted);
      if (canvasTemplateSeleted) {
        if (normalCanvasShowDate !== "") {
          onCheckCanvas(token, normalCanvasShowDate, currentShow.showId, true);
          onSaveSelectedShowDate(normalCanvasShowDate);
          setNormalCanvasShowDate("");
        }
      } else {
        setNormalCanvasShowDate(selectedDate.replaceAll("/", "-"));
        fetchCanvasTemplate(token, currentShow.showId, userId);
      }
    }
  };

  const showDownloadMenu = () => {
    if (currentCanvas) {
      setShowDownload(!showDownload);
    }
  };

  const selectedMenuValue = (e) => {
    if (currentCanvas) {
      setShowDownload(!showDownload);
      if (e.target.value === 1) {
        onDownloadToExcel(
          token,
          currentCanvas.showId,
          currentCanvas.showDate,
          "full"
        );
      } else if (e.target.value === 2) {
        onDownloadToExcel(
          token,
          currentCanvas.showId,
          currentCanvas.showDate,
          "collected"
        );
      } else {
        let email = process.env.REACT_APP_EMAIL_TO_ADDRESS;
        window.open(
          `mailto:${email}?subject=${
            currentShow.showName + "%20" + selectedDate + "%20rundown"
          }&body=${
            currentShow.showName +
            "%20" +
            selectedDate +
            "%20rundown%20attached"
          }`
        );
      }
    }
  };

  const getSelectedRoom = () => {
    return currentRoom.roomId
      ? {
          value: currentRoom.roomId,
          key: currentRoom.roomId,
          label: currentRoom.roomName,
        }
      : null;
  };

  const handleRouteAction = (key) => {
    if (liveShotSignalSelected.length > 0) {
      let blocks =
        canvasBlocks &&
        canvasBlocks.filter(
          (block) => block.softDelete !== true && block.liveshots.length > 0
        );

      if (blocks && blocks.length > 0) {
        let liveshots = blocks
          .flatMap((block) => block.liveshots)
          .filter(
            (liveshot) =>
              liveshot.readyForPcr === true || liveshot.readyForAir === true
          );

        if (liveshots.length > 0) {
          if (key === "take") {
            onTakeAllCanvasBlock(
              token,
              userId,
              userFullName,
              liveshots,
              liveShotSignalSelected,
              currentCanvas.roomId,
              currentRoom
            );
          } else {
            onGrabAllCanvasBlock(
              token,
              userId,
              userFullName,
              liveshots,
              liveShotSignalSelected,
              currentCanvas.roomId,
              currentCanvas,
              currentRoom
            );
          }
        }
      }
    }
  };

  const isAddLSDisabled = !createLiveshotPerm || !currentCanvas;

  return (
    <div className="App-header">
      <NavigationHeader
        home="true"
        routerSignalDetailsPerm={readRouterSignalDetailsPermission}
        controlTrackerPerm={readControlTrackerPermission}
        venueAddressperm={true}
        controlRoomId={controlRoomId}
      />
      <div className="top-title-section">
        <div className="top-margin-header"></div>
      </div>

      <Modal
        show={createNewLS}
        modalClosed={() => setCreateNewLS(false)}
        className="createliveshotmodal"
        classOuterBox={createNewLS ? "add-ls-outer-box" : ""}
      >
        <Searchbox
          blockId={selectedBlockId}
          type={lsType}
          openErrorBar={openErrorBar}
          modalClosed={() => setCreateNewLS(false)}
          currentUserRole={currentUserRole}
          clearSearchTerm={!createNewLS}
        />
      </Modal>

      <div className="homepage-row">
        <div className="liveshot-header">
          <div className="form-field-row">
            <div className="select-show-dropdown-container">
              <div className="label-display-flex">
                <label className="text-title-labels">
                  <span className="required-astrix">*</span>Show
                </label>
                {currentShow === "" ? (
                  <label
                    className="show-not-selected row"
                    onClick={onClickNotification}
                  >
                    <div className="select-show-align">
                      SELECT SHOW TO BEGIN
                    </div>
                  </label>
                ) : (
                  <div onClick={onClickNotification}>
                    <div className="selected-canvas-set">
                      <div className="canvasname">{currentShow.showName}</div>
                      <div className="canvastimes">
                        {currentShow.startTime} - {currentShow.endTime}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <Modal
                show={createDropDown}
                modalClosed={onCancelNotification}
                className="showdropdown"
              >
                <ShowDropDown
                  list={showList}
                  networkList={networkList}
                  selectedShow={onSelectedShow}
                  modalClosed={onCancelNotification}
                  clearSearchTerm={!createDropDown}
                />
              </Modal>
            </div>
            <div className={"control-room"}>
              <div
                className={`label-display-flex-controlroom ${
                  !createLiveshotPerm ? "restricted" : ""
                }`}
              >
                <label className="text-title-labels-control">
                  Control Room
                </label>
                <Select
                  ref={inputEl}
                  onChange={onChangeControlRoom}
                  value={getSelectedRoom()}
                  placeholder={"Select Control Room"}
                  disabled={!createLiveshotPerm || currentCanvasId === null}
                  options={controlRoomOptions}
                  data-testid="controlroom-select"
                />
              </div>
              <div className="label-display-flex-date">
                <label className="text-title-labels">Date</label>
                <div
                  className={`date-flex ${
                    isDateFieldClicked ? "datePickerOpen" : "datePickerClose"
                  }`}
                >
                  <span>
                    <CalendarIcon
                      className={`calendar-date-picker${
                        disableDate ||
                        !createLiveshotPerm ||
                        currentCanvasId === null
                          ? " date-not-selected"
                          : " date-selected"
                      }`}
                      alt="calendar-icon"
                    />
                    <Datepicker
                      className={
                        disableDate ||
                        !createLiveshotPerm ||
                        currentCanvasId === null
                          ? "date-disable"
                          : "date-value"
                      }
                      selected={!canvasTemplateSeleted && new Date(startDate)}
                      placeholderText={"Select Date"}
                      onChange={onChangeDate}
                      dateFormat="EEE, MMM d, yyy"
                      disabled={
                        disableDate ||
                        !createLiveshotPerm ||
                        currentCanvasId === null
                      }
                      onCalendarOpen={() => setIsDateFieldClicked(true)}
                      onCalendarClose={() => setIsDateFieldClicked(false)}
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className="header-info">
              <div className="showdate">
                <div className="showname managerdetails removeAutofillStyle">
                  <form className="tech-mgr-form">
                    <div className="label-display-flex-tmname">
                      <label className="text-title-labels">TM Name</label>
                      <div className="manager-notes">
                        <DebounceInput
                          className={
                            currentCanvas && createLiveshotPerm
                              ? "manager-name"
                              : "manager-name-disabled"
                          }
                          type="text"
                          name="techMgrName"
                          debounceTimeout={500}
                          value={techMgr.techMgrName || ""}
                          placeholder="ENTER TM (FIRSTNAME LASTNAME)"
                          onChange={(event) => {
                            const updatedTechMgr = {
                              ...techMgr,
                              [event.target.name]: event.target.value,
                            };
                            setTechMgr(updatedTechMgr);
                            saveTechManagerDetails(updatedTechMgr);
                          }}
                          disabled={
                            currentCanvas && createLiveshotPerm ? false : true
                          }
                          data-testid="techMgrName-input"
                        />
                      </div>
                    </div>
                    <div className="label-display-flex-tmphone">
                      <label className="text-title-labels">
                        TM Phone Number
                      </label>
                      <div className="phone-notes">
                        <Input
                          type="text"
                          className={
                            currentCanvas && createLiveshotPerm
                              ? "phone-number"
                              : "phone-number-disabled"
                          }
                          placeholder="ENTER PHONE NUMBER"
                          name="techMgrPhone"
                          value={techMgr.techMgrPhone || ""}
                          onChange={onChangeTechMgr}
                          onBlur={() => saveTechManagerDetails()}
                          readOnly={
                            currentCanvas && createLiveshotPerm ? false : true
                          }
                          data-testid="techMgrPhone-input"
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="canvas-notes">
          <label className="canvas-notes-label">Notes</label>
          <DebounceInput
            element="textarea"
            className={
              currentCanvas && createLiveshotPerm
                ? canvasNoteError
                  ? "canvas-notes-textarea-limit-error"
                  : "canvas-notes-textarea"
                : "canvas-notes-textarea-disabled"
            }
            debounceTimeout={500}
            rows="7"
            onChange={onChangeCanvasNotes}
            onKeyUp={onKeyUpCanvasNotes}
            name="notes"
            value={canvasNotes.notes || ""}
            placeholder={`CANVAS NOTES (${canvasNotesLength} CHARACTER LIMIT)`}
            disabled={currentCanvas && createLiveshotPerm ? false : true}
            title={canvasNoteError ? "Character limit has been exceeded" : ""}
            data-testid="canvasnotes-input"
          />

          <input
            className="canvas-chars"
            type="text"
            placeholder={
              (notesCount !== undefined ? notesCount.notes : 0) +
              " / " +
              canvasNotesLength
            }
            name="canvasChars"
            disabled={true}
          />

          <div className="page-action-icons">
            <div className="icon-selection">
              <div
                className={
                  isAddLSDisabled ? "addliveshotbtn-disabled" : "addliveshotbtn"
                }
                title={
                  isAddLSDisabled
                    ? "Select a show to add a live shot"
                    : "Click to add a live shot"
                }
              >
                <Button
                  id="createLiveShot"
                  disabled={isAddLSDisabled}
                  onClick={onAddLiveShot}
                >
                  {isAddLSDisabled ? (
                    <PlusIconDisabled alt="add live shot button" />
                  ) : (
                    <PlusIcon
                      className="add-plus-icon"
                      alt="add live shot button"
                    />
                  )}
                  <span>ADD LIVE SHOT</span>
                </Button>
              </div>
              <div>
                {userRole.roleName === "Super Admin" && (
                  <ViewRoleAs currentUserRoleId={currentUserRole.roleId} />
                )}
              </div>
              <div className="canvas-actions-btns">
                {/* print canvas button */}
                <AntdTooltip title="PRINT CANVAS">
                  <div className="print-action-btn">
                    <button
                      className={
                        currentCanvas
                          ? "print-icon-btn print-btn"
                          : "print-icon-btn-disabled print-btn"
                      }
                      onClick={currentCanvas ? onPrintCanvas : null}
                    >
                      <img src={Print_icon} alt="Print" />
                    </button>
                  </div>
                </AntdTooltip>

                {/* download canvas button */}
                <AntdTooltip title="DOWNLOAD CANVAS">
                  <div className="download-action-btn">
                    <button
                      className={
                        currentCanvas
                          ? "download-icon-btn   download-btn " +
                            `${showDownload ? "selected" : ""}`
                          : "download-icon-btn  download-btn-disabled"
                      }
                      onClick={showDownloadMenu}
                      readOnly={currentCanvas}
                    >
                      <img src={Download_icon} alt="Download" />
                    </button>
                  </div>
                </AntdTooltip>

                <DownloadMenu
                  showdownload={showDownload}
                  selectedItem={selectedMenuValue}
                  setShowDownload={setShowDownload}
                />
                {/* copy canvas from date button */}
                <div className="canvas-datepicker-container">
                  <Datepicker
                    className={
                      !currentCanvas ? "copy-date" : "copy-date-disabled"
                    }
                    disabled={!currentCanvas ? true : false}
                    selected={copyDate}
                    onChange={onClickCopyCanvasFromDate}
                    customInput={<CopyCanvasDateInput />}
                    onCalendarClose={() => {
                      setButtonSelected(false);
                    }}
                    popperModifiers={{
                      offset: {
                        enabled: true,
                        offset: "-30px, 0px",
                      },
                    }}
                  />
                </div>
                {/* copy template canvas button */}
                <AntdTooltip title="COPY CANVAS FROM TEMPLATE">
                  <div className="copy-template-action-btn">
                    <button
                      onClick={currentCanvas ? onClickCanvasFromTemplate : null}
                      className={
                        currentCanvas
                          ? "copy-icon-btn   copy-template-btn"
                          : "copy-icon-btn-disabled   copy-template-btn"
                      }
                      readOnly={currentCanvas}
                    >
                      <img src={Copy_icon} alt="refresh" />
                    </button>
                  </div>
                </AntdTooltip>

                {/* modify canvas template button */}
                <AntdTooltip title="MODIFY CANVAS TEMPLATE">
                  <div className="modify-canvas-action-btn">
                    <button
                      className={`modify-icon-btn  edit-template-${
                        canvasTemplateSeleted ? "green" : "btn"
                      }   ${
                        !createLiveshotPerm || !currentCanvas
                          ? "restricted"
                          : "modify-enabled"
                      }`}
                      onClick={onModifyCanvasTemplate}
                      disabled={disableButtons || !createLiveshotPerm}
                    >
                      <img src={Signature_icon} alt="signature" />
                    </button>
                  </div>
                </AntdTooltip>
                {/* LSM-4515 - Hide Keyboard shortcut icon from right toolbar */}
                {/* <div className="add-keybord-action-btn">
                  {shortCut ? (
                    <button
                      className="keyboard-green-active"
                      onClick={onToggleKeyboard}
                    >
                      <KeyboardIcon alt="keyboard" className="actionIcons" />
                    </button>
                  ) : (
                    <button
                      className={
                        !currentCanvas ? "keyboard-disabled" : "keyboard"
                      }
                      onClick={currentCanvas ? onToggleKeyboard : null}
                    >
                      <KeyboardIcon alt="keyboard" className="actionIcons" />
                    </button>
                  )}
                  <Tooltip
                    position={"bottom-canvas-actions"}
                    title="ADD KEYBOARD"
                    className="canvas-action-tooltip"
                    messageTheme="canvas-actions"
                    titleTheme="canvas-actions"
                    baseToolTip={true}
                  />
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        {" "}
        {(canvasTemplateSeleted ||
          (currentCanvas && currentCanvas.isDefault)) && (
          <div className="blatant-warning-master">
            <WarningIcon className="warning-icon-blatant" alt="Warning_icon" />
            <span className="blatant-warning-text">
              ATTENTION: You are currently editing the MASTER TEMPLATE.
            </span>
          </div>
        )}
      </div>
      {routingCapabilityType >= 2 && currentCanvas.isRoomSelected && (
        <div className="canvas-actions-container">
          <div className="search-btn canvas-top-left-actions">
            <div className="canvas-top-left-actions-inner">
              <Button btntype="scrolldown_btn" onClick={scrollDown}>
                <ScrollIcon alt="scroll" className="actionIcons" />
                <label>BOTTOM</label>
              </Button>
              <Button
                btntype="refresh_btn"
                onClick={onRefresh}
                data-testid="canvasrefresh-button"
              >
                <RefreshIcon
                  alt="refresh"
                  className="tvright-space actionIcons"
                />
                <label>REFRESH</label>
              </Button>
              {isFold ? (
                <Button onClick={onFoldLiveShot} btntype="fold-btn folding">
                  <FoldIcon alt="fold" className="actionIcons" />
                  <label>FOLD</label>
                </Button>
              ) : (
                <Button onClick={onUnFoldLiveShot} btntype="fold-btn ">
                  <FoldIcon alt="fold" className="actionIcons" />
                  <label>UNFOLD</label>
                </Button>
              )}
            </div>
          </div>
          <div className="canvas-top-right-actions">
            <div className="arm-routing-container">
              <span className="arm-routing-span">Arm Routing</span>

              <Toggle
                id="routing-toggle"
                className="routing-toggles mousetrap"
                checked={isArmRouting}
                onChange={handleArmRoutingChange}
                icons={{
                  checked: <span className="arm-routing-on-toggle">ON</span>,
                  unchecked: (
                    <span className="arm-routing-off-toggle">OFF</span>
                  ),
                }}
                disabled={!updateCanvasPerm || currentUserRole.roleId === 6}
                data-testid="arm-routing-toggle"
              />
            </div>
            {routingCapabilityType >= 2 && currentCanvas.isRoomSelected && (
              <div className="canvas-block-btns">
                <div className="canvasblock-Take-Grab">
                  <span className="title-canvas">Canvas</span>
                  <span className="take-grab-btns">
                    <Button
                      type="button"
                      btntype="takeall"
                      onClick={() => handleRouteAction("take")}
                      disabled={isArmRouting === false || !takeAllPerm}
                    >
                      USE
                    </Button>
                    <Button
                      type="button"
                      btntype="graball"
                      onClick={() => handleRouteAction("grab")}
                      disabled={isArmRouting === false || !grabAllPerm}
                    >
                      OWN
                    </Button>
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentCanvas: state.canvasInfo.currentCanvas,
    currentCanvasId: state.canvasInfo.currentCanvasId,
    canvasList: state.canvasInfo.canvasList,
    showList: state.showInfo.showList,
    token: state.auth.accessToken,
    networkList: state.showInfo.networkList,
    currentShow: state.showInfo.currentShow,
    showCreateCanvas: state.canvasInfo.showCreateCanvas,
    controlRoomList: state.canvasInfo.controlRoomList,
    currentUser: state.auth.currentUser,
    currentUserRole: state.user.currentUserRole,
    newLiveshot: state.liveShotContainer.newLiveshot,
    selectedBlockId: state.liveShotContainer.selectedBlockId,
    routingCapabilityType: state.canvasInfo.routingCapabilityType,
    shortCut: state.canvasInfo.shortCut,
    formattedRouterSource: state.routerSourceDestination.formattedRouterSource,
    sipState: state.signalsInfo.sipState,
    liveShotSignalSelected: state.signalsInfo.liveShotSignalSelected,

    canvasBlocks: state.liveShotContainer.normalLiveShots,
    inBoundCRPath: state.signalsInfo.inBoundCRPath,
    outBoundCRPath: state.signalsInfo.outBoundCRPath,
    ifbCRPath: state.signalsInfo.ifbCRPath,
    plCRPath: state.signalsInfo.plCRPath,
    phoCRPath: state.signalsInfo.phoCRPath,
    userRole: state.user.userRole,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onResetAddedFromSearch: () => dispatch(actions.resetAddedFromSearch()),
    onResetAddedFromTemplateOrDate: () =>
      dispatch(actions.resetAddedFromTemplateOrDate()),
    onFetchCurrentCanvas: (canvasId, token) =>
      dispatch(actions.fetchCurrentCanvas(canvasId, token)),
    onFetchCanvasList: (token) => dispatch(actions.fetchCanvasList(token)),
    onFetchShowList: (token) => dispatch(actions.fetchShowList(token)),
    onSelectHostStudioLiveShots: (canvasId, token) =>
      dispatch(actions.getHostStudioLiveShots(canvasId, token)),
    onCheckCanvas: (token, date, showId, showLoader) =>
      dispatch(actions.canvasCheck(token, date, showId, undefined, showLoader)),
    fetchCanvasTemplate: (token, showId, userId) =>
      dispatch(actions.fetchCanvasTemplate(token, showId, userId)),
    onCopyCanvas: (token, showId, sourceDate, destinationDate, createdBy) =>
      dispatch(
        actions.processCopyCanvas(
          token,
          showId,
          sourceDate,
          destinationDate,
          createdBy
        )
      ),
    onUpdateCanvas: (payload, token, roomChange = null) =>
      dispatch(actions.updateCanvas(payload, token, roomChange)),
    onCloseCreateCanvas: () => dispatch(actions.closeCreateCanvasModal()),
    onFetchControllRoomList: (token) =>
      dispatch(actions.fetchControlRoomList(token)),
    onFetchUserList: (token) => dispatch(actions.fetchUserList(token)),
    onSaveSelectedShowDate: (date) =>
      dispatch(actions.saveSelectedShowDate(date)),
    onUpdateNormalLiveShotSignals: (
      token,
      normalSignals,
      room,
      currentCanvasId,
      userId
    ) =>
      dispatch(
        actions.updateNormalLiveShotSignalsWithRefresh(
          token,
          normalSignals,
          room,
          currentCanvasId,
          userId
        )
      ),
    onDownloadToExcel: (token, showId, showDate, details) =>
      dispatch(actions.downloadToExcel(token, showId, showDate, details)),
    onCheckCapabilityType: (token, room) =>
      dispatch(actions.checkCapabilityType(token, room)),
    onResetStudioTruckData: () => dispatch(actions.resetStudioTruckData()),
    onResetSuccessMessage: () => dispatch(actions.resetSuccessMsg()),
    onStartLSCreation: (blockId) => dispatch(actions.startLSCreation(blockId)),
    onResetLSCreation: () => dispatch(actions.resetLSCreation()),
    onFetchLocationSuggestion: (token) =>
      dispatch(actions.fetchLoactionSuggestion(token)),
    onLiveShotNameSuggestions: (token) =>
      dispatch(actions.liveShotNameSuggestions(token)),
    onFetchCRPathForInBoundSignal: (token, room) =>
      dispatch(actions.fetchCRPathForInBoundSignal(token, room)),
    onFetchCRPathForOutBoundSignal: (token, room) =>
      dispatch(actions.fetchCRPathForOutBoundSignal(token, room)),
    onFetchIFBCRPathSignal: (token, room) =>
      dispatch(actions.fetchIFBCRPathSignal(token, room)),
    onFetchPLCRPathSignal: (token, room) =>
      dispatch(actions.fetchPLCRPathSignal(token, room)),
    onFetchPHOCRPathSignal: (token, room) =>
      dispatch(actions.fetchPHOCRPathSignal(token, room)),
    onFetchGlobalPathForInBoundSignal: (token, room) =>
      dispatch(actions.fetchGlobalPathForInBoundSignal(token, room)),
    onFetchGlobalPathExtendInBoundSignal: (token, room) =>
      dispatch(actions.fetchGlobalPathExtendInBoundSignal(token, room)),
    onFetchGlobalPathForOutBoundSignal: (token, room) =>
      dispatch(actions.fetchGlobalPathForOutBoundSignal(token, room)),
    onFetchIFBGlobalPathSignal: (token, room) =>
      dispatch(actions.fetchIFBGlobalPathSignal(token, room)),
    onFetchPLGlobalPathSignal: (token, room) =>
      dispatch(actions.fetchPLGlobalPathSignal(token, room)),
    onFetchPHOGlobalPathSignal: (token, room) =>
      dispatch(actions.fetchPHOGlobalPathSignal(token, room)),
    onFetchSharedGlobalInBound: (token, room) =>
      dispatch(actions.fetchSharedGlobalInBoundSignal(token, room)),
    onFetchSharedGlobalOutBound: (token, room) =>
      dispatch(actions.fetchSharedGlobalOutBoundSignal(token, room)),
    onFetchSharedGlobalIFB: (token, room) =>
      dispatch(actions.fetchSharedGlobalIFBSignal(token, room)),
    onFetchSharedGlobalPHO: (token, room) =>
      dispatch(actions.fetchSharedGlobalPHOSignal(token, room)),
    onFetchSharedGlobalPHOTX: (token, room) =>
      dispatch(actions.fetchSharedGlobalPHOTXSignal(token, room)),
    onFetchSharedGlobalPL: (token, room) =>
      dispatch(actions.fetchSharedGlobalPLSignal(token, room)),
    onFetchPHOTxCRPath: (token, room) =>
      dispatch(actions.fetchPHOTxCRPath(token, room)),
    onFetchPHOTxGlobalPath: (token, room) =>
      dispatch(actions.fetchPHOTxGlobalPath(token, room)),
    onSettingArmRouting: (isArmRouting) =>
      dispatch(actions.settingArmRouting(isArmRouting)),
    onToggleKeyboard: () => dispatch(actions.keyShortcut()),
    onFetchFacilityList: (token) => dispatch(actions.fetchFacilityList(token)),
    onFetchNetworkList: (token) => dispatch(actions.fetchNetworkList(token)),
    onRefetchSip: (token, sipList, routerSources) =>
      dispatch(actions.refetchSipsStatus(token, sipList, routerSources)),
    showNotificationBar: (notificationBarDetails) =>
      dispatch(actions.showNotificationBar(notificationBarDetails)),
    onUpdateLiveShotSignals: (
      token,
      signalInfo,
      signalInfoObj,
      room,
      updateCommGlobal = false,
      needUpdate = false
    ) =>
      dispatch(
        actions.updateLiveShotSignals(
          token,
          signalInfo,
          signalInfoObj,
          room,
          updateCommGlobal,
          needUpdate
        )
      ),
    showCommonPopupModal: (modalDetail) =>
      dispatch(actions.showCommonPopupModal(modalDetail)),
    onTakeAllCanvasBlock: (
      token,
      userId,
      userFullName,
      abSignalInfo,
      normalSignalInfo,
      roomId,
      currentRoom
    ) =>
      dispatch(
        actions.takeAllCanvasBlock(
          token,
          userId,
          userFullName,
          abSignalInfo,
          normalSignalInfo,
          roomId,
          currentRoom
        )
      ),
    onGrabAllCanvasBlock: (
      token,
      userId,
      userFullName,
      liveshots,
      liveShotSignalSelected,
      roomId,
      currentCanvas,
      currentRoom
    ) =>
      dispatch(
        actions.grabAllCanvasBlock(
          token,
          userId,
          userFullName,
          liveshots,
          liveShotSignalSelected,
          roomId,
          currentCanvas,
          currentRoom
        )
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CanvasHeader);
