import { format } from "date-fns";
import moment from "moment";

const CurrentDate = () => {
  return format(new Date(), "iii MMM do");
};

export const convertForCurrentTZ = (value) => {
  if (!value) return;

  const stillUtc = moment.utc(value).toDate();
  const currentTime = moment(stillUtc).local().format("YYYY-MM-DD h:mm:ss A");

  return currentTime;
};

export default CurrentDate;
