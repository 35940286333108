import React, { useState } from "react";
import "./Tooltip.css";

const Tooltip = (props) => {
  const [displayTooltip, setDisplayTooltip] = useState(false);

  const hideTooltip = () => {
    setDisplayTooltip(false);
  };

  const showTooltip = () => {
    setDisplayTooltip(true);
  };

  return props.baseToolTip ? (
    <div className={`tooltip-bubble tooltip-${props.position}`}>
      <div className={`tooltip-message-${props.messageTheme}`}>
        <div className={`tooltip-title-${props.titleTheme}`}>{props.title}</div>
        <div className={`tooltip-content-${props.messageStyle}`}>
          {props.message}
        </div>
      </div>
    </div>
  ) : (
    <div className="tooltip-container" onMouseLeave={hideTooltip}>
      {(props.displayTooltip == undefined || props.displayTooltip) &&
        displayTooltip && (
          <div className={`tooltip-bubble tooltip-${props.position}`}>
            <div className={`tooltip-message-${props.messageTheme}`}>
              <div className={`tooltip-title-${props.titleTheme}`}>
                {props.title}
              </div>
              <div className={`tooltip-content-${props.messageStyle}`}>
                {props.message}
              </div>
            </div>
          </div>
        )}
      <div className="tooltip-trigger" onMouseOver={showTooltip}>
        {props.children}
      </div>
    </div>
  );
};

export default Tooltip;
