import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";
import "./CanvasContainer.css";
import Spinner from "../../components/shared/UI/Spinner/Spinner";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import useMousetrap from "react-hook-mousetrap";
import BlockContainer from "../BlockContainer/BlockContainer";
import {
  changeTimeInHours,
  getUserName,
} from "../../components/shared/utility";
import { removeDuplicates } from "../../components/shared/utility";
import { useUser } from "../../hooks/useUser";

const CanvasContainer = (props) => {
  const {
    addBlockPerm: createBlockPerm,
    updateLiveshotPerm,
    updateBlockPerm,
  } = useUser();
  const {
    canvasBlocks,
    loading,
    onCreateBlock,
    onUpdateBlock,
    onLiveshotUpdate,
    onDeleteBlock,
    token,
    currentCanvasId,
    currentUser,
    currentUserRole,
    onSaveLiveshotsInfo,
    onSetActiveLiveshot,
    onSetNextActiveLiveshot,
    onSetPressedKey,
    searchList,
    liveShotSignalSelected,
    currentCanvas,
    controlRoomList,
    shortCut,
    isArmRouting,
    currentShow,
    onStoreArrPosition,
    arrPosition,
    streamDeckOn,
    fetchPinsInfo,
    fetchRouterSources,
    sipList,
    onAddSipList,
    onFetchPinBySipNumber,
    formattedRouterSource,
    sipState,
  } = props;
  const [blockOrder, setBlockOrder] = useState([]);
  const [liveshotOrder, setLiveshotOrder] = useState({});
  const [canvasLiveshotList, setCanvasLiveshotList] = useState([]);
  const [liveshotMap, setLiveshotMap] = useState({});
  const [liveshotPrevSeq, setLiveshotPrevSeq] = useState({});
  const [blockPrevSeq, setBlockPrevSeq] = useState({});
  const [takeGrabBtn, setTakeGrabBtn] = useState(false);

  const [blockMap, setBlockMap] = useState({});
  const [outofOrderLS, setOutofOrderLS] = useState([]);
  const [userFullName, userId] = getUserName(currentUser);
  const [createdBy] = useState(userId);
  const [updatedBy] = useState(userId);

  useEffect(() => {
    let blockLists = [];
    let lsOrder = {};
    let liveshotList = [];
    let liveshotMap = {};
    let seqList = {};
    let blockSeq = {};
    let blockMap = {};
    if (canvasBlocks && canvasBlocks.length > 0) {
      blockLists = canvasBlocks
        .filter((block) => {
          return block.softDelete !== true;
        })
        .map((block) => {
          let blockId = block.blockId;
          blockMap[blockId] = block;
          blockSeq[blockId] = block.sequenceNo;
          const liveshots = block.liveshots;
          const order = [];
          const prevSeq = {};
          liveshots.forEach((liveshot) => {
            order.push(liveshot.liveshotId);
            liveshotList.push(liveshot);
            liveshotMap[liveshot.liveshotId] = liveshot;
            prevSeq[liveshot.liveshotId] = liveshot.sequenceNo;
          });
          lsOrder[blockId] = order;
          seqList[blockId] = prevSeq;
          return blockId;
        });
      setBlockMap(blockMap);
      setBlockOrder(blockLists);
      setLiveshotOrder(lsOrder);
      setCanvasLiveshotList(liveshotList);
      setLiveshotMap(liveshotMap);
      setLiveshotPrevSeq(seqList);
      setBlockPrevSeq(blockSeq);
    }
  }, [canvasBlocks]);

  //start related to hittime changes
  useEffect(() => {
    let currentLiveshot = [];
    let nextLiveshot = [];
    let outofOrderLS = [];
    if (canvasLiveshotList && canvasLiveshotList.length > 1) {
      for (let i = 0; i < canvasLiveshotList.length - 1; i++) {
        currentLiveshot = canvasLiveshotList[i];
        nextLiveshot = canvasLiveshotList[i + 1];
        if (currentLiveshot.hitTime !== null && nextLiveshot.hitTime !== null) {
          if (
            changeTimeInHours(currentLiveshot.hitTime, currentShow.startTime) >
            changeTimeInHours(nextLiveshot.hitTime, currentShow.startTime)
          ) {
            outofOrderLS.push(canvasLiveshotList[i]);
          }
        }
      }
    }
    setOutofOrderLS(outofOrderLS);
  }, [canvasLiveshotList]);

  //Loads LiveShot Selection Every Time Canvas LiveShots are Loaded or Changed
  useEffect(() => {
    onSetActiveLiveshot(canvasLiveshotList[arrPosition - 1]);
    onSetNextActiveLiveshot(canvasLiveshotList[arrPosition]);
  }, [arrPosition, canvasLiveshotList]);

  useEffect(() => {
    fetchPinsInfo(token);
  }, [token, fetchPinsInfo]);

  useEffect(() => {
    fetchRouterSources(token);
  }, [token, fetchRouterSources]);

  useMousetrap(["down", "up"], (e) => {
    e.preventDefault();
    onSetPressedKey(null);
    if (!shortCut) {
      return;
    }

    let newPosition = arrPosition;
    if (e.key === "ArrowDown" && canvasLiveshotList.length > arrPosition) {
      newPosition++;
    } else if (e.key === "ArrowUp" && arrPosition > 1) {
      newPosition--;
    }

    onStoreArrPosition(newPosition);
  });

  useEffect(() => {
    sipList.map((sip) => {
      if (!sipState.hasOwnProperty(sip)) {
        onFetchPinBySipNumber(token, sip, true);
      }
    });
  }, [onFetchPinBySipNumber]);

  useEffect(() => {
    const sipArray = new Set([]);
    const sipComms = removeDuplicates(liveShotSignalSelected).filter(
      (signal) =>
        signal.liveshotSignalTypeId === 5 ||
        signal.liveshotSignalTypeId === 7 ||
        signal.liveshotSignalTypeId === 8 ||
        signal.liveshotSignalTypeId === 9
    );
    sipComms.map((val) => {
      if (val.sip && formattedRouterSource.includes(val.sip)) {
        sipArray.add(val.sip);
      }
    });
    onAddSipList([...sipArray]);
  }, [liveShotSignalSelected, formattedRouterSource]);

  useEffect(() => {
    let lsInfo = {};
    lsInfo.liveshotLs = liveshotMap;
    lsInfo.blockorder = blockOrder;
    onSaveLiveshotsInfo(lsInfo);
  }, [liveshotMap, blockOrder]);

  useEffect(() => {
    if (streamDeckOn) {
      onStoreArrPosition(1);
    } else {
      onStoreArrPosition(0); // Resets Selected LiveShot Index For Keyboard Only
    }
  }, [currentCanvasId, onStoreArrPosition, streamDeckOn]);

  useMousetrap(
    ["u", "o", "U", "O", "e", "E", "f", "F", "enter", "backspace"],
    (e) => {
      e.preventDefault();
      if (
        e.key === "u" ||
        e.key === "U" ||
        e.key === "o" ||
        e.key === "O" ||
        e.key === "e" ||
        e.key === "E" ||
        e.key === "F" ||
        e.key === "f" ||
        e.key === "Enter" ||
        e.key === "Backspace"
      ) {
        onSetPressedKey(e.key);
        setTimeout(() => {
          onSetPressedKey(null);
        }, 300);
      }
    }
  );

  const getLastLabel = () => {
    let lastCharacter = "";
    let lastSequenceNo = 0;

    canvasBlocks.forEach((block) => {
      if (!block.softDelete) {
        let blockName = block.blockName;
        blockName.split(" ");
        if (blockName[0] > lastCharacter) {
          lastCharacter = blockName[0];
        }

        let sequenceNo = block.sequenceNo;
        if (sequenceNo > lastSequenceNo) {
          lastSequenceNo = sequenceNo;
        }
      }
    });

    lastCharacter = String.fromCharCode(lastCharacter.charCodeAt(0) + 1);

    return [lastCharacter, lastSequenceNo + 1];
  };

  const onRemoveBlock = (block_id) => {
    const block = canvasBlocks.find((element) => element.blockId === block_id);
    const canvas_id = canvasBlocks[0].canvasId;
    onDeleteBlock(block_id, canvas_id, updatedBy, token);

    // change blockOrder
    let newBlockOrder = Array.from(blockOrder);
    let index = newBlockOrder.indexOf(block.blockId);
    newBlockOrder.splice(index, 1);
    setBlockOrder(newBlockOrder);

    let newLiveshotOrder = { ...liveshotOrder };
    delete newLiveshotOrder[block.blockId];
    setLiveshotOrder(newLiveshotOrder);

    let newLiveshotPrevSeq = { ...liveshotPrevSeq };
    delete newLiveshotPrevSeq[block.blockId];
    setLiveshotPrevSeq(newLiveshotPrevSeq);

    let newBlockPrevSeq = { ...blockPrevSeq };
    delete newBlockPrevSeq[block.blockId];
    setBlockPrevSeq(newBlockPrevSeq);
  };

  const onAddBlock = () => {
    const [blockName, seqNo] = getLastLabel();
    const canvas_id = canvasBlocks[0].canvasId;
    const liveshot_id = null;
    if (createBlockPerm) {
      onCreateBlock(createdBy, canvas_id, blockName, seqNo, liveshot_id, token);
    }
  };

  const dragEnd = (result) => {
    const { destination, source, draggableId, type } = result;
    if (!destination) {
      return;
    }

    if (destination.droppableId === "bottom-space") {
      const [blockName, seqNo] = getLastLabel();
      const canvas_id = canvasBlocks[0].canvasId;
      const liveshot_id = liveshotMap[parseInt(draggableId)].liveshotId;
      if (createBlockPerm) {
        onCreateBlock(
          createdBy,
          canvas_id,
          blockName,
          seqNo,
          liveshot_id,
          token
        );
      }
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    if (type === "block") {
      // UI
      const oldBlockOrder = Array.from(blockOrder);
      const newBlockOrder = Array.from(blockOrder);
      newBlockOrder.splice(source.index, 1);
      newBlockOrder.splice(destination.index, 0, parseInt(draggableId));
      setBlockOrder(newBlockOrder);

      // API
      let blockSeq = { ...blockPrevSeq };
      if (source.index > destination.index) {
        // move block up
        const start = destination.index;
        const end = source.index;
        let start_seq = blockSeq[oldBlockOrder[start]];
        for (let i = start; i < end; i++) {
          let next_seq = blockSeq[oldBlockOrder[i + 1]];
          let block = canvasBlocks.find(
            (element) => element.blockId === oldBlockOrder[i]
          );
          let blockId = block.blockId;
          let payload = { sequenceNo: next_seq };
          blockSeq[oldBlockOrder[i]] = next_seq;
          if (updateBlockPerm) {
            onUpdateBlock(updatedBy, blockId, payload, currentCanvasId, token);
          }
        }

        let last_block = canvasBlocks.find(
          (element) => element.blockId === oldBlockOrder[end]
        );
        let last_blockId = last_block.blockId;
        let last_payload = { sequenceNo: start_seq };
        blockSeq[oldBlockOrder[end]] = start_seq;
        setBlockPrevSeq(blockSeq);
        if (updateBlockPerm) {
          onUpdateBlock(
            updatedBy,
            last_blockId,
            last_payload,
            currentCanvasId,
            token
          );
        }
      }

      if (source.index < destination.index) {
        // move block down
        const start = source.index;
        const end = destination.index;
        let end_seq = blockSeq[oldBlockOrder[end]];
        for (let i = start + 1; i <= end; i++) {
          let prev_seq = blockSeq[oldBlockOrder[i - 1]];
          let block = canvasBlocks.find(
            (element) => element.blockId === oldBlockOrder[i]
          );
          let blockId = block.blockId;
          let payload = { sequenceNo: prev_seq };
          //blockSeq[oldBlockOrder[i]] = prev_seq;
          if (updateBlockPerm) {
            onUpdateBlock(updatedBy, blockId, payload, currentCanvasId, token);
          }
        }

        let start_block = canvasBlocks.find(
          (element) => element.blockId === oldBlockOrder[start]
        );
        let start_blockId = start_block.blockId;
        let start_payload = { sequenceNo: end_seq };
        blockSeq[oldBlockOrder[start]] = end_seq;
        // setBlockPrevSeq(blockSeq);
        if (updateBlockPerm) {
          onUpdateBlock(
            updatedBy,
            start_blockId,
            start_payload,
            currentCanvasId,
            token
          );
        }
      }
      return;
    }

    if (type === "liveshot" && destination.droppableId === source.droppableId) {
      // liveshot dnd within the same block
      // UI
      const newLiveshotOrder = Array.from(
        liveshotOrder[parseInt(destination.droppableId)]
      );
      newLiveshotOrder.splice(source.index, 1);
      newLiveshotOrder.splice(destination.index, 0, parseInt(draggableId));
      const newColumn = {
        ...liveshotOrder,
        [parseInt(destination.droppableId)]: newLiveshotOrder,
      };

      setLiveshotOrder(newColumn);

      // api
      const block = canvasBlocks.find(
        (element) => element.blockId === parseInt(destination.droppableId)
      );
      let blockSeq = { ...liveshotPrevSeq };
      if (source.index > destination.index) {
        // sift down
        const start = destination.index;
        const end = source.index;
        const liveshotorder = liveshotOrder[parseInt(destination.droppableId)];
        const newSeq = { ...blockSeq[parseInt(destination.droppableId)] };
        let start_seq = newSeq[liveshotorder[start]];
        for (let i = start; i < end; i++) {
          let next_seq = newSeq[liveshotorder[i + 1]];
          let cur_ls = liveshotMap[liveshotorder[i]];
          let liveshotId = cur_ls.liveshotId;
          let payload = { sequenceNo: next_seq };
          newSeq[liveshotorder[i]] = next_seq;
          if (updateLiveshotPerm) {
            onLiveshotUpdate(
              updatedBy,
              currentCanvasId,
              liveshotId,
              payload,
              token
            );
          }
        }

        let last_liveshotId = liveshotMap[liveshotorder[end]].liveshotId;
        let last_payload = { sequenceNo: start_seq };
        newSeq[liveshotorder[end]] = start_seq;
        blockSeq[block.blockId] = newSeq;
        setLiveshotPrevSeq(blockSeq);
        if (updateLiveshotPerm) {
          onLiveshotUpdate(
            updatedBy,
            currentCanvasId,
            last_liveshotId,
            last_payload,
            token
          );
        }
      }

      if (source.index < destination.index) {
        // sift up
        const start = source.index;
        const end = destination.index;
        const liveshotorder = liveshotOrder[parseInt(destination.droppableId)];
        const newSeq = { ...blockSeq[parseInt(destination.droppableId)] };
        let end_seq = newSeq[liveshotorder[end]];
        for (let i = start + 1; i <= end; i++) {
          let prev_seq = newSeq[liveshotorder[i - 1]];
          let cur_ls = liveshotMap[liveshotorder[i]];
          let liveshotId = cur_ls.liveshotId;
          let payload = { sequenceNo: prev_seq };
          newSeq[liveshotorder[i]] = prev_seq;
          if (updateLiveshotPerm) {
            onLiveshotUpdate(
              updatedBy,
              currentCanvasId,
              liveshotId,
              payload,
              token
            );
          }
        }

        let start_liveshotId = liveshotMap[liveshotorder[start]].liveshotId;
        let start_payload = { sequenceNo: end_seq };
        if (updateLiveshotPerm) {
          onLiveshotUpdate(
            updatedBy,
            currentCanvasId,
            start_liveshotId,
            start_payload,
            token
          );
        }
        newSeq[liveshotorder[start]] = end_seq;
        blockSeq[block.blockId] = newSeq;
        setLiveshotPrevSeq(blockSeq);
      }
      return;
    }

    // moving from one block to another
    // UI
    const oldLiveshotOrder = { ...liveshotOrder };
    const srcLiveShotOrder = Array.from(
      liveshotOrder[parseInt(source.droppableId)]
    );
    srcLiveShotOrder.splice(source.index, 1);

    const destLiveShotOrder = Array.from(
      liveshotOrder[parseInt(destination.droppableId)]
    );
    destLiveShotOrder.splice(destination.index, 0, parseInt(draggableId));

    const newLiveshotOrders = {
      ...liveshotOrder,
      [parseInt(source.droppableId)]: srcLiveShotOrder,
      [parseInt(destination.droppableId)]: destLiveShotOrder,
    };

    setLiveshotOrder(newLiveshotOrders);
    // remove block when empty

    // API
    const src_block = canvasBlocks.find(
      (element) => element.blockId === parseInt(source.droppableId)
    );
    const dest_block = canvasBlocks.find(
      (element) => element.blockId === parseInt(destination.droppableId)
    );
    const src_liveshotOrder = oldLiveshotOrder[src_block.blockId];
    const src_prevSeq = liveshotPrevSeq[src_block.blockId];
    const dest_liveshotOrder = oldLiveshotOrder[dest_block.blockId];
    const dest_prevSeq = liveshotPrevSeq[dest_block.blockId];

    const cur_liveshot = liveshotMap[src_liveshotOrder[source.index]];

    const src_sequence = Object.keys(src_prevSeq).filter(
      (seq) => parseInt(seq) !== cur_liveshot.liveshotId
    );

    if (src_sequence.length > 0) {
      src_sequence.map((seq, index) => {
        const liveshot = liveshotMap[seq];
        if (liveshot.sequenceNo !== index + 1 && updateLiveshotPerm) {
          onLiveshotUpdate(
            updatedBy,
            currentCanvasId,
            liveshot.liveshotId,
            { sequenceNo: index + 1 },
            token,
            false
          );
        }
      });
    }

    if (Object.keys(dest_prevSeq).length === 0) {
      dest_prevSeq[cur_liveshot.liveshotId] = 0;
      delete src_prevSeq[cur_liveshot.liveshotId];
      liveshotPrevSeq[src_block.blockId] = src_prevSeq;
      liveshotPrevSeq[dest_block.blockId] = dest_prevSeq;
      setLiveshotPrevSeq(liveshotPrevSeq);

      const payload = {
        blockId: dest_block.blockId,
        sequenceNo: 0,
      };
      if (updateLiveshotPerm) {
        onLiveshotUpdate(
          updatedBy,
          currentCanvasId,
          cur_liveshot.liveshotId,
          payload,
          token
        );
      }
      return;
    }

    if (destination.index !== dest_liveshotOrder.length) {
      // insert to top or middle
      let start_seq = dest_prevSeq[dest_liveshotOrder[destination.index]];
      for (let i = destination.index; i < dest_liveshotOrder.length - 1; i++) {
        let liveshot = liveshotMap[dest_liveshotOrder[i]];
        let next_seq = dest_prevSeq[dest_liveshotOrder[i + 1]];
        let payload = {
          sequenceNo: next_seq,
        };

        dest_prevSeq[dest_liveshotOrder[i]] = next_seq;
        if (updateLiveshotPerm) {
          onLiveshotUpdate(
            updatedBy,
            currentCanvasId,
            liveshot.liveshotId,
            payload,
            token,
            false
          );
        }
      }
      let target_payload = {
        sequenceNo: start_seq,
        blockId: dest_block.blockId,
      };
      dest_prevSeq[cur_liveshot.liveshotId] = start_seq;
      if (updateLiveshotPerm) {
        onLiveshotUpdate(
          updatedBy,
          currentCanvasId,
          cur_liveshot.liveshotId,
          target_payload,
          token
        );
      }

      let last_liveshotName = dest_liveshotOrder[dest_liveshotOrder.length - 1];
      let last_liveshot = liveshotMap[last_liveshotName];
      let last_payload = {
        sequenceNo: dest_prevSeq[last_liveshotName] + 1,
      };
      dest_prevSeq[last_liveshotName] = dest_prevSeq[last_liveshotName] + 1;

      delete src_prevSeq[cur_liveshot.liveshotId];

      liveshotPrevSeq[src_block.blockId] = src_prevSeq;
      liveshotPrevSeq[dest_block.blockId] = dest_prevSeq;
      setLiveshotPrevSeq(liveshotPrevSeq);
      if (updateLiveshotPerm) {
        onLiveshotUpdate(
          updatedBy,
          currentCanvasId,
          last_liveshot.liveshotId,
          last_payload,
          token
        );
      }
    } else {
      // insert into bottom
      let last_liveshotName = dest_liveshotOrder[dest_liveshotOrder.length - 1];
      let last_payload = {
        sequenceNo: dest_prevSeq[last_liveshotName] + 1,
        blockId: dest_block.blockId,
      };

      dest_prevSeq[cur_liveshot.liveshotId] =
        dest_prevSeq[last_liveshotName] + 1;

      delete src_prevSeq[cur_liveshot.liveshotId];

      liveshotPrevSeq[src_block.blockId] = src_prevSeq;
      liveshotPrevSeq[dest_block.blockId] = dest_prevSeq;
      setLiveshotPrevSeq(liveshotPrevSeq);
      if (updateLiveshotPerm) {
        onLiveshotUpdate(
          updatedBy,
          currentCanvasId,
          cur_liveshot.liveshotId,
          last_payload,
          token
        );
      }
    }
    return;
  };

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <DragDropContext onDragEnd={(result) => dragEnd(result)}>
          <div className="Container">
            <div id="normal-blocks-row">
              <Droppable droppableId="all-blocks" type="block">
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {canvasBlocks &&
                      canvasBlocks.length > 0 &&
                      blockOrder
                        .filter((blockId) => blockMap[blockId])
                        .map((blockId, index) => {
                          let block = blockMap[blockId];
                          const blockLiveshotIdList =
                            liveshotOrder[block.blockId];
                          return (
                            <BlockContainer
                              key={block.blockId}
                              block={block}
                              index={index}
                              removeBlock={onRemoveBlock}
                              addBlock={onAddBlock}
                              isLastBlock={index === blockOrder.length - 1}
                              outofOrderLS={outofOrderLS}
                              blockLiveshotIdList={blockLiveshotIdList}
                              canvasLiveshotList={canvasLiveshotList}
                              currentUserRole={currentUserRole}
                              searchList={searchList}
                              isDisable={createBlockPerm}
                            />
                          );
                        })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
            <div id="drag-bottom">
              <Droppable droppableId="bottom-space" type="liveshot">
                {(provided) => (
                  <div
                    className="drag-drop-bottom-space"
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          </div>
        </DragDropContext>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    canvasBlocks: state.liveShotContainer.normalLiveShots,
    loading: state.liveShotContainer.loading,
    token: state.auth.accessToken,
    currentCanvasId: state.canvasInfo.currentCanvasId,
    currentUser: state.auth.currentUser,
    currentUserRole: state.user.currentUserRole,
    shortCut: state.canvasInfo.shortCut,
    streamDeckOn: state.canvasInfo.streamDeckOn,
    showDate: state.canvasInfo.showDate,
    currentShow: state.showInfo.currentShow,
    searchList: state.searchListInfo.searchList,
    liveShotSignalSelected: state.signalsInfo.liveShotSignalSelected,
    currentCanvas: state.canvasInfo.currentCanvas,
    controlRoomList: state.canvasInfo.controlRoomList,
    isArmRouting: state.canvasInfo.armRouting,
    arrPosition: state.liveShotContainer.arrPosition,
    formattedRouterSource: state.routerSourceDestination.formattedRouterSource,
    sipState: state.signalsInfo.sipState,
    sipList: state.signalsInfo.sipList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCreateBlock: (
      createdBy,
      canvas_id,
      block_name,
      sequence_no,
      liveshot_id,
      token
    ) =>
      dispatch(
        actions.createBlock(
          createdBy,
          canvas_id,
          block_name,
          sequence_no,
          liveshot_id,
          token
        )
      ),
    onUpdateBlock: (updatedBy, block_id, payload, currentCanvasId, token) =>
      dispatch(
        actions.updateBlock(
          updatedBy,
          block_id,
          payload,
          currentCanvasId,
          token
        )
      ),
    onLiveshotUpdate: (
      updatedBy,
      currentCanvasId,
      liveshot_id,
      payload,
      token,
      refetchCanvas
    ) =>
      dispatch(
        actions.updateLiveshot(
          updatedBy,
          currentCanvasId,
          liveshot_id,
          payload,
          token,
          refetchCanvas
        )
      ),
    onDeleteBlock: (block_id, canvas_id, userId, token) =>
      dispatch(actions.deleteBlock(block_id, canvas_id, userId, token)),
    onCheckCanvas: (token, date, showId) =>
      dispatch(actions.canvasCheck(token, date, showId)),
    onSaveLiveshotsInfo: (lsInfo) =>
      dispatch(actions.saveLiveshotsInfo(lsInfo)),
    onSetActiveLiveshot: (id) => dispatch(actions.setActiveLiveshot(id)),
    onSetNextActiveLiveshot: (id) =>
      dispatch(actions.setNextActiveLiveshot(id)),
    onSetPressedKey: (key) => dispatch(actions.setPressedKey(key)),
    onStoreArrPosition: (arrPosition) =>
      dispatch(actions.onStoreArrPosition(arrPosition)),
    fetchPinsInfo: (token) => dispatch(actions.getPinTableInfo(token)),
    fetchRouterSources: (token) => dispatch(actions.getRouterSources(token)),
    onAddSipList: (arr) => dispatch(actions.addSipList(arr)),
    onFetchPinBySipNumber: (token, sip, initialLoad) =>
      dispatch(actions.fetchPinBySipNumber(token, sip, initialLoad)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CanvasContainer);
