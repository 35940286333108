import React from "react";
import { convertForCurrentTZ } from "../utils/date/Date";

const whiteSpace = "pre";

export const Venue_PinsLookupTableColumnDefs = [
  {
    headerName: "TALENT OR EVENT NAME",
    field: "liveshotName",
    resizable: true,
    sortable: true,
    cellStyle: {
      border: "none !important",
      textTransform: "uppercase !important",
      whiteSpace,
    },
    suppressHeaderMenuButton: true,
    suppressFloatingFilterButton: true,
  },
  {
    headerName: "IFB 1 PIN",
    field: "studioIfb1pin",
    resizable: true,
    sortable: true,
    cellStyle: { border: "none !important" },
    suppressHeaderMenuButton: true,
    suppressFloatingFilterButton: true,
  },
  {
    headerName: "IFB 2 PIN",
    field: "studioIfb2pin",
    resizable: true,
    sortable: true,
    cellStyle: { border: "none !important" },
    suppressHeaderMenuButton: true,
    suppressFloatingFilterButton: true,
  },
  {
    headerName: "PL PIN",
    field: "studioPlpin",
    resizable: true,
    cellStyle: { border: "none !important" },
    sortable: true,
    suppressHeaderMenuButton: true,
    suppressFloatingFilterButton: true,
  },
  {
    maxWidth: 50,
    headerName: " ",
    cellRenderer: "deletePinIconRenderer",
    sortable: false,
    filter: false,
    resizable: false,
  },
  {
    maxWidth: 50,
    headerName: " ",
    cellRenderer: "editPinIconRenderer",
    sortable: false,
    filter: false,
    resizable: false,
  },
];

export const venueColumnDefs = [
  {
    maxWidth: 35,
    cellRenderer: "deleteIconRenderer",
    sortable: false,
    filter: false,
    resizable: false,
    suppressStickyLabel: true,
  },
  {
    maxWidth: 35,
    cellRenderer: "editIconRenderer",
    sortable: false,
    filter: false,
    resizable: false,
    suppressStickyLabel: true,
  },
  {
    headerName: "VENUE TYPE",
    field: "studioTypeName",
    resizable: true,
    sortable: true,
    cellStyle: { border: "none !important", whiteSpace },
    suppressHeaderMenuButton: true,
    suppressFloatingFilterButton: true,
  },

  {
    headerName: "VENUE NAME",
    field: "studioName",
    resizable: true,
    sortable: true,
    cellStyle: {
      border: "none !important",
      textTransform: "uppercase !important",
      whiteSpace,
    },
    suppressHeaderMenuButton: true,
    suppressFloatingFilterButton: true,
  },
  {
    headerName: "LOCATION",
    field: "",
    valueGetter: function (params) {
      return `${params.data.city} ${params.data.region} ${params.data.country}`;
    },
    resizable: true,
    sortable: true,
    cellStyle: {
      border: "none !important",
      textTransform: "uppercase !important",
      whiteSpace,
    },
    suppressHeaderMenuButton: true,
    suppressFloatingFilterButton: true,
  },
  {
    headerName: "Tx",
    field: "studioTx",
    resizable: true,
    cellStyle: {
      border: "none !important",
      textTransform: "uppercase !important",
      whiteSpace,
    },
    sortable: true,
    suppressHeaderMenuButton: true,
    suppressFloatingFilterButton: true,
  },
  {
    headerName: "IFB 1 PIN",
    field: "studioIfb1pin",
    resizable: true,
    sortable: true,
    cellStyle: { border: "none !important" },
    suppressHeaderMenuButton: true,
    suppressFloatingFilterButton: true,
    minWidth: 200,
  },
  {
    headerName: "IFB 2 PIN",
    field: "studioIfb2pin",
    resizable: true,
    sortable: true,
    cellStyle: { border: "none !important" },
    suppressHeaderMenuButton: true,
    suppressFloatingFilterButton: true,
    minWidth: 200,
  },
  {
    headerName: "PL PIN",
    field: "studioPlpin",
    resizable: true,
    sortable: true,
    cellStyle: { border: "none !important" },
    suppressHeaderMenuButton: true,
    suppressFloatingFilterButton: true,
    minWidth: 200,
  },
  {
    headerName: "SKYPE",
    field: "skype",
    resizable: true,
    sortable: true,
    cellStyle: {
      border: "none !important",
      textTransform: "uppercase !important",
    },
    suppressHeaderMenuButton: true,
    suppressFloatingFilterButton: true,
  },
  {
    headerName: "QUICKLINK",
    field: "quickLink",
    resizable: true,
    sortable: true,
    cellStyle: {
      border: "none !important",
      textTransform: "uppercase !important",
    },
    suppressHeaderMenuButton: true,
    suppressFloatingFilterButton: true,
  },
  {
    headerName: "WEB / OTHER",
    field: "webOther",
    resizable: true,
    sortable: true,
    cellStyle: {
      border: "none !important",
      textTransform: "uppercase !important",
    },
    suppressHeaderMenuButton: true,
    suppressFloatingFilterButton: true,
  },
  {
    headerName: "VENUE CONTACT NAME 1",
    field: "studioContact",
    resizable: true,
    cellStyle: {
      border: "none !important",
      textTransform: "uppercase !important",
      whiteSpace,
    },
    sortable: true,
    suppressHeaderMenuButton: true,
    suppressNavigable: true,
    suppressFloatingFilterButton: true,
  },
  {
    headerName: "VENUE CONTACT PHONE 1",
    field: "studioPhone",
    resizable: true,
    cellStyle: { border: "none !important" },
    sortable: true,
    suppressHeaderMenuButton: true,
    suppressNavigable: true,
    suppressFloatingFilterButton: true,
  },
  {
    headerName: "VENUE CONTACT EMAIL 1",
    field: "studioEmail",
    resizable: true,
    cellStyle: { border: "none !important" },
    sortable: true,
    suppressHeaderMenuButton: true,
    suppressNavigable: true,
    suppressFloatingFilterButton: true,
  },
  {
    headerName: "VENUE CONTACT NAME 2",
    field: "studioContact2",
    resizable: true,
    cellStyle: {
      border: "none !important",
      textTransform: "uppercase !important",
      whiteSpace,
    },
    sortable: true,
    suppressHeaderMenuButton: true,
    suppressNavigable: true,
    suppressFloatingFilterButton: true,
  },
  {
    headerName: "VENUE CONTACT PHONE 2",
    field: "studioPhone2",
    resizable: true,
    cellStyle: { border: "none !important" },
    sortable: true,
    suppressHeaderMenuButton: true,
    suppressNavigable: true,
    suppressFloatingFilterButton: true,
  },
  {
    headerName: "VENUE CONTACT EMAIL 2",
    field: "studioEmail2",
    resizable: true,
    cellStyle: { border: "none !important" },
    sortable: true,
    suppressHeaderMenuButton: true,
    suppressNavigable: true,
    suppressFloatingFilterButton: true,
  },
  {
    headerName: "VENUE CONTACT NAME 3",
    field: "studioContact3",
    resizable: true,
    cellStyle: {
      border: "none !important",
      textTransform: "uppercase !important",
      whiteSpace,
    },
    sortable: true,
    suppressHeaderMenuButton: true,
    suppressNavigable: true,
    suppressFloatingFilterButton: true,
  },
  {
    headerName: "VENUE CONTACT PHONE 3",
    field: "studioPhone3",
    resizable: true,
    cellStyle: { border: "none !important" },
    sortable: true,
    suppressHeaderMenuButton: true,
    suppressNavigable: true,
    suppressFloatingFilterButton: true,
  },
  {
    headerName: "VENUE CONTACT EMAIL 3",
    field: "studioEmail3",
    resizable: true,
    cellStyle: { border: "none !important" },
    sortable: true,
    suppressHeaderMenuButton: true,
    suppressNavigable: true,
    suppressFloatingFilterButton: true,
  },
  {
    headerName: "LIVESHOT NOTES",
    field: "liveshotNote",
    resizable: true,
    cellStyle: { border: "none !important", whiteSpace },
    sortable: true,
    suppressHeaderMenuButton: true,
    suppressNavigable: true,
    suppressFloatingFilterButton: true,
  },
  {
    headerName: "PO NUMBER",
    field: "poNumber",
    resizable: true,
    cellStyle: { border: "none !important" },
    sortable: true,
    suppressHeaderMenuButton: true,
    suppressNavigable: true,
    suppressFloatingFilterButton: true,
  },
];
export const SourceDestination_SourceColumnDefs = [
  {
    headerName: "ID",
    field: "sourceId",
    resizable: true,
    sortable: true,
    suppressHeaderMenuButton: true,
    suppressFloatingFilterButton: true,
  },
  {
    headerName: "ROOM",
    field: "roomCode",
    resizable: true,
    sortable: true,
    suppressHeaderMenuButton: true,
    suppressFloatingFilterButton: true,
  },
  {
    headerName: "ORDER",
    field: "sourceOrder",
    resizable: true,
    sortable: true,
    suppressHeaderMenuButton: true,
    suppressFloatingFilterButton: true,
  },
  {
    headerName: "SET NAME",
    field: "namesetName",
    resizable: true,
    sortable: true,
    suppressHeaderMenuButton: true,
    suppressFloatingFilterButton: true,
  },
  {
    headerName: "CREATED ON",
    field: "createdOn",
    resizable: true,
    sortable: true,
    filter: false,
    valueFormatter: (params) => convertForCurrentTZ(params.data.createdOn),
  },
  {
    headerName: "TYPE",
    field: "liveshotSignalType",
    resizable: true,
    sortable: true,
    suppressHeaderMenuButton: true,
    suppressFloatingFilterButton: true,
  },
];
export const SourceDestination_DestinationsColumnDefs = [
  {
    headerName: "CREATED ON",
    field: "createdOn",
    resizable: true,
    sortable: true,
    filter: false,
    valueFormatter: (params) => convertForCurrentTZ(params.data.createdOn),
  },
  {
    headerName: "ID",
    field: "destinationId",
    resizable: true,
    sortable: true,
    suppressHeaderMenuButton: true,
    suppressFloatingFilterButton: true,
  },
  {
    headerName: "ROOM",
    field: "roomCode",
    resizable: true,
    sortable: true,
    suppressHeaderMenuButton: true,
    suppressFloatingFilterButton: true,
  },
  {
    headerName: "ORDER",
    field: "desOrder",
    resizable: true,
    sortable: true,
    suppressHeaderMenuButton: true,
    suppressFloatingFilterButton: true,
  },
  {
    headerName: "SET NAME",
    field: "namesetName",
    resizable: true,
    sortable: true,
    suppressHeaderMenuButton: true,
    suppressFloatingFilterButton: true,
  },

  {
    headerName: "TYPE",
    field: "liveshotSignalType",
    resizable: true,
    sortable: true,
    suppressHeaderMenuButton: true,
    suppressFloatingFilterButton: true,
  },
];
export const SourceDestination_SignalTypesColumnDefs = [
  {
    headerName: "CREATED ON",
    field: "createdOn",
    resizable: true,
    sortable: true,
    filter: false,
    valueFormatter: (params) => convertForCurrentTZ(params.data.createdOn),
  },
  {
    headerName: "ID",
    field: "liveshotSignalTypeId",
    resizable: true,
    sortable: true,
    suppressHeaderMenuButton: true,
    suppressFloatingFilterButton: true,
  },
  {
    headerName: "SIGNAL TYPE",
    field: "liveshotSignalTypeName",
    resizable: true,
    sortable: true,
    suppressHeaderMenuButton: true,
    suppressFloatingFilterButton: true,
  },
  {
    headerName: "CREATED BY",
    field: "createdBy",
    resizable: true,
    sortable: true,
    filter: false,
  },
  {
    headerName: "UPDATED BY",
    field: "updatedBy",
    resizable: true,
    sortable: true,
    filter: false,
  },
  {
    headerName: "UPDATED ON",
    field: "updatedOn",
    resizable: true,
    sortable: true,
    filter: false,
    valueFormatter: (params) => convertForCurrentTZ(params.data.updatedOn),
  },
];
export const SourceDestination_RoomsColumnDefs = [
  {
    headerName: "UPDATED BY",
    field: "updatedBy",
    sortable: true,
    resizable: true,
    filter: false,
  },
  {
    headerName: "UPDATED ON",
    field: "updatedOn",
    sortable: true,
    resizable: true,
    filter: false,
    valueFormatter: (params) => convertForCurrentTZ(params.data.updatedOn),
  },
  {
    headerName: "ID",
    field: "roomId",
    resizable: true,
    sortable: true,
    suppressHeaderMenuButton: true,
    suppressFloatingFilterButton: true,
  },
  {
    headerName: "CORE ID",
    field: "coreId",
    sortable: true,
    resizable: true,
    suppressHeaderMenuButton: true,
    suppressFloatingFilterButton: true,
  },
  {
    headerName: "ROOM CODE",
    field: "roomCode",
    sortable: true,
    resizable: true,
    suppressHeaderMenuButton: true,
    suppressFloatingFilterButton: true,
  },
  {
    headerName: "ROUTING CAP TYPE",
    field: "routingCapabilityType",
    sortable: true,
    resizable: true,
    suppressHeaderMenuButton: true,
    suppressFloatingFilterButton: true,
  },
  {
    headerName: "ROUTING CONTROL",
    field: "routingControl",
    sortable: true,
    resizable: true,
    suppressHeaderMenuButton: true,
    suppressFloatingFilterButton: true,
  },
  {
    headerName: "ROUTER URL",
    field: "primaryRoutingControlUrl",
    sortable: true,
    resizable: true,
    suppressHeaderMenuButton: true,
    suppressFloatingFilterButton: true,
  },
  {
    headerName: "COMM CAP TYPE",
    field: "commCapabilityType",
    sortable: true,
    resizable: true,
    suppressHeaderMenuButton: true,
    suppressFloatingFilterButton: true,
  },
  {
    headerName: "HIDDEN",
    field: "hidden",
    cellDataType: "text",
    sortable: true,
    resizable: true,
    suppressHeaderMenuButton: true,
    suppressFloatingFilterButton: true,
  },
];
export const SourceDestination_UsersColumnDefs = [
  {
    headerName: "SSO",
    field: "sso",
    resizable: true,
    sortable: true,
    suppressHeaderMenuButton: true,
    suppressFloatingFilterButton: true,
  },
  {
    headerName: "USER EMAIL",
    field: "userEmail",
    sortable: true,
    resizable: true,
    suppressHeaderMenuButton: true,
    suppressFloatingFilterButton: true,
  },
  {
    headerName: "USER NAME",
    field: "userName",
    sortable: true,
    resizable: true,
    suppressHeaderMenuButton: true,
    suppressFloatingFilterButton: true,
  },
  {
    minWidth: 190,
    headerName: "ROLE",
    field: "role.roleName",
    cellRenderer: "roleDropdownRenderer",
    sortable: true,
    resizable: true,
    suppressHeaderMenuButton: true,
    suppressFloatingFilterButton: true,
  },
  {
    headerName: "FIRST LOGIN",
    field: "firstLogin",
    sortable: true,
    resizable: true,
    suppressHeaderMenuButton: true,
    suppressFloatingFilterButton: true,
    valueFormatter: (params) => convertForCurrentTZ(params.data.firstLogin),
  },
  {
    headerName: "LATEST LOGIN",
    field: "latestLogin",
    sortable: true,
    resizable: true,
    suppressHeaderMenuButton: true,
    suppressFloatingFilterButton: true,
    valueFormatter: (params) => convertForCurrentTZ(params.data.latestLogin),
  },
  {
    headerName: "SOFT DELETE",
    field: "softDelete",
    cellDataType: "text",
    sortable: true,
    resizable: true,
    suppressHeaderMenuButton: true,
    suppressFloatingFilterButton: true,
  },
  {
    headerName: "LAST ACTIVITY",
    field: "latestHeartbeat",
    sortable: true,
    resizable: true,
    suppressHeaderMenuButton: true,
    suppressFloatingFilterButton: true,
    valueFormatter: (params) =>
      convertForCurrentTZ(params.data.latestHeartbeat),
  },
  {
    headerName: "",
    cellRenderer: "deleteIconRenderer",
    sortable: false,
    resizable: false,
    filter: false,
  },
];
export const outBoundTypeArray = ["PROMPTER", "PGM RETURN", "GRAPHIC", "OTHER"];
export const commsTypeArray = ["IFB", "PL", "PHO"];
