import React, { useState, memo, forwardRef } from "react";
import { Select } from "antd";
import { ReactComponent as DownOutlined } from "../../../../assets/icons/down-icon.svg";
import { ReactComponent as UpOutlined } from "../../../../assets/icons/up-icon.svg";

const ReactSelect = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const handleClick = (e) => {
    setOpen(!open);
  };

  return (
    <Select
      labelInValue
      ref={ref}
      suffixIcon={open ? <UpOutlined /> : <DownOutlined />}
      onDropdownVisibleChange={handleClick}
      notFoundContent={<span>No Option found</span>}
      virtual={false}
      className={`select-component ${
        open ? "select-active" : props.value ? "item-value" : "placeholder"
      }`}
      popupClassName="select-menu"
      {...props}
    />
  );
});

ReactSelect.defaultProps = {
  onChange: () => {},
  value: "",
  options: [],
  key: "antd-select",
};

export default memo(ReactSelect);
