import React, { useState, memo, forwardRef } from "react";
import { Select } from "antd";
import { ReactComponent as DownOutlined } from "../../../../assets/icons/down-icon.svg";
import { ReactComponent as UpOutlined } from "../../../../assets/icons/up-icon.svg";

const SignalDropdown = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const handleClick = (e) => {
    setOpen(!open);
  };
  const selectedList = props.options.filter(
    (item) => item.label === props.value
  );

  return (
    <Select
      labelInValue
      ref={ref}
      suffixIcon={open ? <UpOutlined /> : <DownOutlined />}
      onDropdownVisibleChange={handleClick}
      notFoundContent={<span>No Option found</span>}
      virtual={false}
      className={`signal-select ${
        open ? "dropdown-open" : props.value ? "item-value" : "placeholder"
      }`}
      popupClassName="signal-menu"
      {...props}
      value={selectedList}
    />
  );
});

SignalDropdown.defaultProps = {
  onChange: () => {},
  value: "",
  options: [],
  key: "signal-dropdown",
};

export default memo(SignalDropdown);
