import React from "react";
import "./GrabNotificationBox.css";
import Button from "../../../shared/UI/Button/Button";
import { connect } from "react-redux";
import * as actions from "../../../../store/actions/index";
import moment from "moment";

const GrabNotificationBox = (props) => {
  const {
    grabNotification,
    removeNotification,
    onRelease,
    token,
    index,
    title,
    currentUser,
  } = props;

  let userId = currentUser.sso;
  if (process.env.REACT_APP_AUTH_ENABLED !== "true") {
    userId = process.env.REACT_APP_AUTH_OVERRIDE_ID;
  }

  const onReleaseControlTracker = () => {
    const controlTrackerId = grabNotification.data.controlTrackerId;
    if (controlTrackerId) {
      onRelease(controlTrackerId, token, userId);
    }
  };

  let dateObj = grabNotification.addedOn;
  let msgTime = moment(dateObj).fromNow();

  return (
    <div className="row grab-container">
      <div className="grab-detail" title={title}>
        <div className="grab-text">
          {msgTime} : {grabNotification.data.requesterFullName} in Control Room{" "}
          {grabNotification.data.controlRoomCode} is requesting to own "
          {grabNotification.data.liveshotSignal.globalPathName}"
        </div>
        <div className="remove-btn">
          <button
            onClick={() => removeNotification(index)}
            type="button"
            className="close"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
      <div className="button-section">
        <div>
          <Button
            onClick={onReleaseControlTracker}
            type="button"
            btntype="button btns release"
          >
            RELEASE
          </Button>
        </div>
        <div>
          <Button
            type="button"
            btntype="button btns ignore"
            onClick={() => removeNotification(index)}
          >
            IGNORE
          </Button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.accessToken,
    currentUser: state.auth.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onRelease: (controlTrackerId, token, userId) =>
      dispatch(actions.releaseControltracker(controlTrackerId, token, userId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GrabNotificationBox);
