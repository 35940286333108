import React from "react";
import { Radio } from "antd";

const RadioInput = ({ onClick, disabled, isDisabled, checked }) => {
  return (
    <Radio
      className={`radiobtn ${
        isDisabled || disabled
          ? `cursorNotAllowed ${checked ? "mint6radio" : ""}`
          : checked
          ? "mint6radio"
          : "hoverRadio"
      }`}
      onClick={onClick}
      disabled={disabled}
      checked={checked}
    />
  );
};

export default RadioInput;
