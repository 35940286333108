import React from "react";
import { Tooltip } from "antd";
import "./Tooltip.css";

export const AntdTooltip = ({ title, children }) => {
  return (
    <Tooltip
      className={"canvas-tooltip"}
      placement="bottom"
      title={title}
      trigger={"hover"}
      arrow={false}
    >
      {children}
    </Tooltip>
  );
};
